import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { apiClient } from '@devd-client/api';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import styled from 'styled-components';
import DragDropFile from './DragDrop';
import Loader from '../appLoader';
import { useToastHook } from '../appToast';

const FileUploadContainer = styled.div`
  min-height: 65vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PreviewLink = styled.a`
  text-decoration: underline;
  color: #0095e6;
  font-size: 14px;
  font-family: 'Manrope' sans-serif;
  margin-left: -270px;
  margin-top: 30px;
`;

interface FileUploadProps {
  setUploadFile: (upload: boolean) => void;
  uploadLink: string;
  queryKey: any;
}

export const FileUpload: FC<FileUploadProps> = ({
  setUploadFile,
  uploadLink,
  queryKey,
}) => {
  const [newToast] = useToastHook();
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [errorFileLink, setErrorFileLink] = useState(null);
  const queryClient = useQueryClient();

  const handleSubmitUpload = async () => {
    setLoading(true);
    const data = new FormData();
    data.append('file', file);
    try {
      setLoading(true);
      apiClient(`${uploadLink}`, {
        method: 'POST',
        headers: {
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: data,
      })
        .then((res: any) => {
          setLoading(false);
          if (res?.error?.code === 'INVALID_FILE_DATA') {
            setErrorFileLink(res?.dto);
            const link = document.createElement('a');
            link.href = res?.dto;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            newToast({
              message:
                'Uploaded file seems to have some missing data/mismatch. Please follow the instructions in red below to correct the errors and re-upload the file.',
              status: 'error',
            });
          } else {
            setErrorFileLink(null);
            newToast({
              message: 'File Uploaded successfully',
              status: 'success',
            });
            Promise.all([
              queryClient.invalidateQueries([queryKey.teams]),
              queryClient.invalidateQueries([queryKey.teamList]),
            ]);

            setTimeout(() => {
              setUploadFile(false);
            }, 200);
          }
        })
        .catch((err) => {
          setLoading(false);

          newToast({
            message: `${err.message}` ?? 'Upload Error',
            status: 'error',
          });
        });
    } catch (err) {
      setLoading(false);
      newToast({
        message: `${err}`,
        status: 'error',
      });
    }
  };

  return (
    <FileUploadContainer>
      <DragDropFile file={file} setFile={setFile} />
      <br />
      <br />
      {queryKey === 'member' && (
        <PreviewLink
          href="https://docs.google.com/spreadsheets/d/1DUWl3bk_oCpyW5qQzy8ZVm1LavFT3ms2/edit#gid=209744760"
          target="_blank"
          rel="noreferrer"
        >
          Preview example upload.
        </PreviewLink>
      )}
      {queryKey?.teams === 'teams' && (
        <>
          <Box mt={6} width="100%" maxW="640px">
            <Accordion allowToggle index={errorFileLink ? [1] : [0]}>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading as="h3" size="md">
                      Instructions for File Upload:
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <List spacing={4} mt={2}>
                    <Text>
                      <b>
                        NOTE: ONLY NEW TEAMS CAN BE CREATED AND THE SYSTEM DOES
                        NOT SUPPORT UPDATING EXISTING TEAMS THROUGH TEAMS
                        UPLOAD.
                      </b>
                    </Text>
                    <Text>
                      Prepare your .xlsx file according to the Instructions
                      sheet in{' '}
                      <Link
                        color={'#0095e6'}
                        href="https://docs.google.com/spreadsheets/d/1kKyWJa2dzVmB8HgR2K5gcXT6_v72q8azbxO_0G2AZ24/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Preview example for Teams Upload.
                      </Link>
                    </Text>
                    {[
                      'Click on Upload file dropbox on top and select the desired .xlsx file.',
                      "Click the 'Upload' button at the bottom to start the upload process.",
                    ].map((item, index) => (
                      <ListItem key={index}>
                        <Text>
                          <b>Step {index + 1}: </b>
                          {item}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>

              {errorFileLink && (
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Heading as="h3" size="md" color="red.500">
                        Looks like the file you uploaded has some missing
                        data/mismatch. Let's correct it by following the below
                        steps:
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <List spacing={3} mt={2}>
                      {[
                        'Find the error file downloaded just now. This file has the same format as the uploaded file and the name of the file is of the format "xxx_xxx_error.xlsx".',
                        // 'Open it in excel and identify the entries marked in red in the file. These highlighted entries seem to have some errors. You are only expected to correct these entries. Please do not make any changes to other entries.',
                        'Open it in excel and identify the entries marked in red in the file.',
                        'If the error says: "A team with this name already exists.", please ignore the entry row from error sheet since system only supports new teams creation through this facility.',
                        'If the error is aboout missing members/managers or missing/invalid repos/projects, please use portal to add/update the missing data or contact support at support@devdynamics.ai',
                        // Changes in [PORTAL-2625] - Re-upload is invalid as teams are not allowed to be updated through imports API.
                        // 'Correct the entries according to the Instructions sheet in the Preview example.',
                        // 'Re-upload the corrected file again in the above upload section.',
                        // 'Hit the Upload button again to upload the corrected file.',
                      ].map((item, index) => (
                        <ListItem key={index}>
                          <Text>
                            <b>Step {index + 1}: </b>
                            {item}
                          </Text>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </Box>
        </>
      )}
      <Box mt={14}>
        <Button
          isDisabled={!file || loading}
          variant="primary"
          size={'md'}
          onClick={handleSubmitUpload}
        >
          Upload
        </Button>

        <Button
          variant="secondary"
          onClick={() => setUploadFile(false)}
          size={'md'}
          ml="4"
        >
          Back
        </Button>
      </Box>

      {loading && <Loader />}
    </FileUploadContainer>
  );
};

export default FileUpload;
