export const CrossIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78202 1.03199C8.83547 0.978611 8.87788 0.915224 8.90683 0.845451C8.93578 0.775679 8.95071 0.700887 8.95075 0.625346C8.9508 0.549806 8.93597 0.474996 8.9071 0.405188C8.87824 0.33538 8.83591 0.271941 8.78252 0.218493C8.72914 0.165045 8.66575 0.122635 8.59598 0.0936837C8.52621 0.0647327 8.45142 0.049808 8.37588 0.0497615C8.30034 0.0497151 8.22553 0.0645479 8.15572 0.0934131C8.08591 0.122278 8.02247 0.16461 7.96902 0.217993L4.50002 3.68699L1.03202 0.217993C0.92408 0.11005 0.777678 0.049408 0.625023 0.049408C0.472369 0.049408 0.325966 0.11005 0.218023 0.217993C0.11008 0.325936 0.0494385 0.472338 0.0494385 0.624993C0.0494385 0.777647 0.11008 0.92405 0.218023 1.03199L3.68702 4.49999L0.218023 7.96799C0.164575 8.02144 0.122178 8.08489 0.0932522 8.15473C0.0643263 8.22456 0.0494385 8.29941 0.0494385 8.37499C0.0494385 8.45058 0.0643263 8.52543 0.0932522 8.59526C0.122178 8.66509 0.164575 8.72854 0.218023 8.78199C0.325966 8.88994 0.472369 8.95058 0.625023 8.95058C0.70061 8.95058 0.775457 8.93569 0.84529 8.90676C0.915123 8.87784 0.978575 8.83544 1.03202 8.78199L4.50002 5.31299L7.96902 8.78199C8.07697 8.8898 8.22332 8.95032 8.37588 8.95022C8.52844 8.95013 8.67471 8.88944 8.78252 8.78149C8.89033 8.67355 8.95085 8.5272 8.95075 8.37464C8.95066 8.22208 8.88997 8.0758 8.78202 7.96799L5.31302 4.49999L8.78202 1.03199Z"
        fill="#B10707"
      />
    </svg>
  );
};

export default CrossIcon;
