import {
  Box,
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { QUERY_KEYS, useQualityConfig, useQualityConfigMutation } from '../api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../TextInput';
import Loader from '../../appLoader';
import { useEffect, useState } from 'react';
import { useToastHook } from '../../appToast';
import { useQueryClient } from '@tanstack/react-query';
import { AiOutlineInfoCircle } from 'react-icons/ai';

type Props = {
  name: string;
};

const ConfigTab = ({ name }: Props) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQualityConfig(name);

  const [initialValues, setInitialValues] = useState({
    branches: '',
  });

  const {
    mutate: handlePostQualityConfig,
    isLoading: handlePostQualityConfigLoading,
  } = useQualityConfigMutation();

  useEffect(() => {
    if (data) {
      setInitialValues({
        branches: data?.dto?.branches,
      });
    }
  }, [data]);

  return (
    <>
      <Tabs isLazy>
        <TabList>
          <Tab
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.primary"
            lineHeight="17px"
          >
            Config
          </Tab>
        </TabList>
        <TabPanels
          p={6}
          border="0.5px solid rgba(0,0,0,0.1)"
          borderRadius="4px"
        >
          <TabPanel>
            <Box w="50%">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  handlePostQualityConfig(
                    {
                      integrationId: name,
                      branches: values.branches,
                    },
                    {
                      onSuccess: () => {
                        newToast({
                          message: 'Config saved successfully!',
                          status: 'success',
                        });
                        queryClient.invalidateQueries([
                          QUERY_KEYS.qualityConfig,
                        ]);
                      },
                      onError: () => {
                        newToast({
                          message: 'Something went wrong.',
                          status: 'error',
                        });
                      },
                    }
                  );
                  setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({})}
              >
                {(props) => {
                  const { isSubmitting, handleSubmit } = props;
                  return (
                    <Stack as="form" spacing={4} onSubmit={handleSubmit as any}>
                      <TextInput
                        name="branches"
                        label="Branches"
                        subLabel={
                          <Flex align="center">
                            <AiOutlineInfoCircle />
                            <Text ml={1} as="span">
                              Enter comma separated branches to show Quality
                              gate date
                            </Text>
                          </Flex>
                        }
                        placeholder="Enter branch to show Quality gate data from that branch"
                      />

                      <Box>
                        <Button
                          isDisabled={isSubmitting}
                          variant="tertiary"
                          fontSize="sm"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </Stack>
                  );
                }}
              </Formik>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {(isLoading || handlePostQualityConfigLoading) && <Loader />}
    </>
  );
};

export default ConfigTab;
