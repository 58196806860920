/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Divider,
  Flex,
  HStack,
  Tooltip as ChakraToolTip,
  Icon,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import {
  MetricSidebar,
  PeriodType,
  PR_CHART_KEYS,
} from '@devd-client/devd/components';
import React, { useState } from 'react';
import { FaCheckCircle, FaCircle, FaEye } from 'react-icons/fa';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { useLocation } from 'react-router';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import moment from 'moment';
import { QUERY_KEYS } from '../../../graphql';

interface prbarChartProps {
  pullRequestStats: any;
  selected: PeriodType;
}

const getPath = (x: any, y: any, width: any, height: any) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  }
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
  Z`;
};

const TriangleBar = (props: any) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const ToolTipContainer = styled.div`
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 8px;
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <ToolTipContainer>
        {payload.map((entry: any) => (
          <Flex align="center">
            <Text
              fontSize="lg"
              fontWeight="medium"
              fontFamily="heading"
              color="text.primary"
            >
              {entry?.value}
            </Text>
            :&nbsp;
            <Text
              fontSize="lg"
              fontWeight="normal"
              fontFamily="heading"
              color="text.primary"
            >
              {label}
            </Text>
          </Flex>
        ))}
      </ToolTipContainer>
    );
  }
  return null;
};

const Colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

export const PrBarChart: React.FC<prbarChartProps> = ({
  pullRequestStats,
  selected,
}) => {
  const barGraphData = pullRequestStats?.filter(
    (item: any) =>
      item?.metric === 'PR_OPEN' ||
      item?.metric === 'PR_MERGE' ||
      item?.metric === 'PR_REVIEW'
  );

  const insightsData = pullRequestStats?.filter(
    (item: any) =>
      item?.metric === 'PR_CHANGES_REQUESTED' ||
      item?.metric === 'PR_CHANGES' ||
      item?.metric === 'COMMENTS_PER_PR' ||
      item?.metric === 'MERGE_WITHOUT_REVIEW'
  );
  const { colors } = useTheme();
  const teammodal = useDisclosure();

  const { pathname } = useLocation();

  const [metricsName, setMetricsName] = useState<string>('');
  const [heading, setHeading] = useState<string>('');
  const [count, setCount] = useState<string>('');

  const transformedData = barGraphData?.map((data: any) => {
    const chartTitle = data?.chartMetadata?.chartTitle;
    const currentData = Number(data?.currentData);
    return { chartTitle, currentData };
  });

  return (
    <Box
      w={'100%'}
      borderWidth={1}
      borderColor="gray.300"
      borderRadius="12px"
      bg="#fff"
      minHeight="248px"
      border="1px solid #E3E6EA"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
      transition="all 0.2s"
      _hover={{
        boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
      }}
    >
      <Box p={5}>
        <Text
          fontWeight="semibold"
          fontFamily="heading"
          color="text.primary"
          lineHeight="24px"
          fontSize={'md'}
          ml={2}
        >
          PR Activity
        </Text>

        <HStack w="100%" justifyContent={'space-between'}>
          <Flex
            direction="column"
            w={'20%'}
            h={150}
            mt="-6"
            justifyContent={'space-around'}
          >
            <Text
              color="gray.600"
              fontWeight="semibold"
              fontSize={'sm'}
              fontFamily="heading"
              ml={2}
            >
              Pull Requests
            </Text>
            {barGraphData?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider
                  ml={2}
                  orientation="horizontal"
                  borderColor="gray.300"
                />

                <Flex
                  ml={2}
                  align="center"
                  w="100%"
                  justifyContent="space-between"
                >
                  <Flex align="center" fontSize="sm">
                    <Icon
                      as={
                        data?.metric === 'PR_OPEN'
                          ? FaCircle
                          : data.metric === 'PR_MERGE'
                          ? FaCheckCircle
                          : FaEye
                      }
                      size={16}
                      mr="4px"
                      color={
                        data?.metric === 'PR_OPEN'
                          ? '#0088FE'
                          : data.metric === 'PR_MERGE'
                          ? '#00C49F'
                          : '#FFBB28'
                      }
                    />

                    <Text
                      fontFamily="inter"
                      fontWeight="semibold"
                      color="gray.500"
                    >
                      {data?.metric === 'PR_OPEN'
                        ? 'Open'
                        : data?.metric === 'PR_MERGE'
                        ? 'Merged'
                        : data?.metric === 'PR_REVIEW'
                        ? 'Reviews'
                        : ''}
                    </Text>
                  </Flex>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label="View details"
                        aria-label="View details"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setHeading(data?.chartMetadata?.chartTitle);
                            setCount(data?.currentData);
                            teammodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                ${
                                  data?.previousData?.improved > 0
                                    ? 'improved'
                                    : 'deteriorated'
                                } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
          {[...PR_CHART_KEYS].includes(metricsName) && teammodal.isOpen && (
            <MetricSidebar
              querykey={QUERY_KEYS.teamStats}
              openDrawer={teammodal.isOpen}
              handleDrawerClose={teammodal.onClose}
              heading={heading}
              subHeading={`${moment(selected.startDate).format(
                'DD MMM'
              )} - ${moment(selected.endDate).format(
                'DD MMM'
              )}, ${heading} (${count})
           `}
              payload={{
                payload: {
                  startDate: selected.startDate,
                  endDate: selected.endDate,
                },
              }}
              currentChartMeta={{ chartKey: metricsName }}
              filterBy={{
                teamId: decodeURIComponent(pathname?.split('/')[2]),
              }}
              selectedTeam={decodeURIComponent(pathname?.split('/')[2])}
            />
          )}

          <ResponsiveContainer width="50%" height={200}>
            <BarChart
              data={transformedData}
              barGap={1}
              margin={{ top: 50, bottom: 5, right: 20, left: 0 }}
            >
              <XAxis
                dataKey="chartTitle"
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: 'your-x-axis-color', // Replace with your preferred color
                  fontFamily: 'inter',
                }}
                tickFormatter={(value) => {
                  switch (value) {
                    case 'PR Open Count':
                      return 'Open';
                    case 'PR Reviews Count':
                      return 'Reviews';
                    case 'PR Merged Count':
                      return 'Merged';
                    default:
                      return value;
                  }
                }}
              />
              <YAxis axisLine={false} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={<CustomTooltip />}
                wrapperStyle={{ outline: 'none' }}
              />

              <Bar
                dataKey="currentData"
                barSize={60}
                fill="rgba(0, 149, 230, 0.2)"
                shape={<TriangleBar />}
              >
                {transformedData?.map((entry: any, index: any) => (
                  <>
                    <Cell key={`cell-${index}`} fill={Colors[index % 20]} />
                    <LabelList dataKey="currentData" position="top" />
                  </>
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>

          <Flex
            direction="column"
            w={'30%'}
            h={120}
            justifyContent={'space-around'}
          >
            {insightsData?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="inter"
                    fontWeight="semibold"
                    color="gray.500"
                  >
                    {data?.metric === 'MERGE_WITHOUT_REVIEW'
                      ? 'Merged w/o Review'
                      : data.metric === 'PR_CHANGES'
                      ? 'Avg PR Size'
                      : data.metric === 'PR_CHANGES_REQUESTED'
                      ? 'PRs Requiring Changes'
                      : "Avg PR Comment's"}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label={`View details`}
                        aria-label={`View details`}
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setHeading(data?.chartMetadata?.chartTitle);
                            setCount(data?.currentData);
                            teammodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                ${
                                  data?.previousData?.improved > 0
                                    ? 'improved'
                                    : 'deteriorated'
                                } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
        </HStack>
      </Box>
    </Box>
  );
};

export default PrBarChart;
