import { LLMResponse } from './home.types';

export function getGreetingByTime() {
  const time = new Date().getHours();

  if (time >= 0 && time < 12) {
    return 'Good morning';
  } else if (time >= 12 && time < 18) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}

export function convertToTitleCase(str: string) {
  return str
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

export function parseLLMResponseData(data: string): LLMResponse {
  const pattern =
    /\{"content": "(.*?)", "error": "(.*?)", "responseCode": (\d+)\}/s;
  const matches = data.match(pattern);
  if (!matches || matches.length < 4) {
    throw new Error('Unable to parse response data');
  }
  return {
    content: matches[1],
    error: matches[2],
    responseCode: parseInt(matches[3]),
  };
}

export const fillColors = [
  '#C2528B',
  '#5F50A9',
  '#0D7EA0',
  '#EC4899',
  '#49CC93',
  '#BBBBBC',
  '#FFC532',
  '#80E1D9',
];

export const fillColorsTaskDist = [
  '#0095E6',
  '#49CC93',
  '#EC4899',
  '#5F50A9',
  '#FFC532',
  '#FF7C65',
  '#C2528B',
  '#FF9D52',
  '#0D7EA0',
  '#80E1D9',
  '#BBBBBC',
];

export const ticketTypeFilterOptions = [
  { value: 'task', label: 'Work Item' },
  { value: 'bug', label: 'Bug Item' },
];
