import React, { FC, Fragment } from 'react';
import { Box, Flex, Skeleton, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { AiOutlineLink } from 'react-icons/ai';
import { WorkLogDetailsType } from '../../../../helpers/ActivityLog.types';
import { BsPersonCircle } from 'react-icons/bs';
import { BiDetail } from 'react-icons/bi';
import { SlideBar } from '@devd-client/devd/components';
import {
  AnchorLink,
  Divider,
  NoDataFoundText,
  Paragraph,
  ParaLeft,
  ParaRight,
  SidebarCard,
} from './SlideDrawer.styled';
import moment from 'moment';

interface SlideDrawerProps {
  openDrawer: boolean;
  workLogDetails?: any;
  handleDrawerClose: () => void;
  workLogDetailsFetching: boolean;
  heading: string;
  tag: string;
  subHeading: string;
  date: string;
}

const SlideDrawer: FC<SlideDrawerProps> = ({
  openDrawer,
  workLogDetails,
  handleDrawerClose,
  workLogDetailsFetching,
  heading,
  tag,
  subHeading,
  date,
}) => {
  const { colors } = useTheme();
  return (
    <SlideBar
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      sideBarSubHeading={
        <Text color="text.secondary" fontWeight="400" fontSize="13px">
          {tag === 'contributors'
            ? heading === 'PR Merged'
              ? 'of'
              : 'by'
            : 'on'}{' '}
          {tag === 'contributors' ? 'user' : tag === 'git' ? 'repo' : 'project'}{' '}
          <Text as="u">{subHeading}</Text> on{' '}
          {moment(date).format('dddd, MMMM Do YYYY').split(' ')[2]}{' '}
          {moment(date).format('dddd, MMMM Do YYYY').split(' ')[1]}
        </Text>
      }
      content={
        <>
          {!workLogDetails ||
            (workLogDetails.length === 0 && !workLogDetailsFetching && (
              <NoDataFoundText>No Data Found.</NoDataFoundText>
            ))}

          {(workLogDetailsFetching
            ? Array.from(new Array(7))
            : workLogDetails
          )?.map((detail: WorkLogDetailsType, idx: number) => (
            <Fragment key={idx}>
              {detail ? (
                <SidebarCard>
                  <Flex gap={1}>
                    {detail?.activityDate && (
                      <Tooltip label={'Activity time'} hasArrow>
                        <Box
                          fontSize={'10px'}
                          fontFamily="heading"
                          bgColor={'#f6f7f9'}
                          w="fit-content"
                          p={1}
                        >
                          <Box>
                            {
                              moment
                                .utc(detail?.activityDate)
                                // .local()
                                .format('lll')
                                .split(' ')[3]
                            }{' '}
                            {
                              moment
                                .utc(detail?.activityDate)
                                // .local()
                                .format('lll')
                                .split(' ')[4]
                            }
                          </Box>
                        </Box>
                      </Tooltip>
                    )}
                    {detail?.project && (
                      <Tooltip label={'Project'} hasArrow>
                        <Box
                          fontSize={'10px'}
                          fontFamily="heading"
                          bgColor={'#f6f7f9'}
                          w="fit-content"
                          p={1}
                        >
                          {detail?.project}
                        </Box>
                      </Tooltip>
                    )}
                    {detail?.branch && (
                      <Tooltip label={'Branch'} hasArrow>
                        <Box
                          fontSize={'10px'}
                          fontFamily="heading"
                          bgColor={'#f6f7f9'}
                          w="fit-content"
                          p={1}
                        >
                          {detail?.branch}
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>

                  {detail?.assignee && (
                    <Paragraph
                      color={colors.text.gray[200]}
                      fWeight="500"
                      fSize="12px"
                    >
                      <ParaRight>
                        <BsPersonCircle
                          fontSize="14"
                          color={colors.text.secondary}
                        />
                      </ParaRight>
                      <ParaLeft>{detail.assignee}</ParaLeft>
                    </Paragraph>
                  )}

                  {detail?.description && (
                    <Tooltip label={'Description'} hasArrow>
                      <Paragraph
                        color={colors.text.secondary}
                        fWeight="400"
                        fSize="11px"
                      >
                        <ParaRight>
                          <BiDetail
                            fontSize="14"
                            color={colors.text.secondary}
                          />
                        </ParaRight>
                        <ParaLeft>{detail.description}</ParaLeft>
                      </Paragraph>
                    </Tooltip>
                  )}

                  {detail?.link && (
                    <Paragraph>
                      <ParaRight>
                        <AiOutlineLink
                          fontSize="14"
                          color={colors.text.secondary}
                        />
                      </ParaRight>
                      <ParaLeft>
                        <AnchorLink href={detail.link} target="_blank">
                          {detail.link}
                        </AnchorLink>
                      </ParaLeft>
                    </Paragraph>
                  )}
                </SidebarCard>
              ) : (
                <Skeleton height="80px" />
              )}

              <Divider />
            </Fragment>
          ))}
        </>
      }
    />
  );
};

export default React.memo(SlideDrawer);
