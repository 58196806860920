import styled from 'styled-components';

type ParagraphProps = {
  fWeight?: string;
  color?: string;
  fSize?: string;
};

export const SidebarCard = styled.div`
  min-height: 30px;
  border-radius: 5px;
  background: #fff;
  padding: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Paragraph = styled.p<ParagraphProps>`
  display: flex;
  align-items: flex-start;
  color: ${({ color }) => color};
  font-size: ${({ fSize }) => fSize || '10.5px'};
  font-family: 'Manrope';
  font-weight: ${({ fWeight }) => fWeight || 400};
  margin: 8px 0;
`;

export const ParaRight = styled.span`
  font-weight: 700;
  font-family: 'Inter';
`;

export const ParaLeft = styled.span`
  margin-left: 5px;
  width: 90%;
`;

export const AnchorLink = styled.a`
  text-decoration: underline;
  color: #0095e6;
`;

export const Divider = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const NoDataFoundText = styled.p`
  color: '#718096';
  font-size: 12px;
  font-family: 'Manrope';
  font-weight: 600;
`;
