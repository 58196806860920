import styled from 'styled-components';

export type LabelProps = {
  color: string;
  isCondensed?: boolean;
};

export type StyledLeftNavProps = {
  width?: string;
};

export const Label = styled.div<LabelProps>`
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter';
  align-self: center;
  margin-left: ${({ isCondensed }) => (isCondensed ? '8px' : '0')};
`;

export const IconLabelContainer = styled.div`
  padding: 9px 16px;
`;

export const StyledLeftNav = styled.div<StyledLeftNavProps>`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: ${({ width }) => width || '60px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  transition: all 0.2s;
`;

export const StyledLeftNavCondensed = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
