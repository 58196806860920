export const AiStarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_4089_690)">
        <path
          d="M3.00016 14.6667V11.3334M3.00016 4.66671V1.33337M1.3335 3.00004H4.66683M1.3335 13H4.66683M8.66683 2.00004L7.51071 5.00595C7.3227 5.49477 7.2287 5.73918 7.08252 5.94476C6.95296 6.12697 6.79376 6.28617 6.61155 6.41573C6.40597 6.56191 6.16156 6.65591 5.67274 6.84392L2.66683 8.00004L5.67274 9.15616C6.16156 9.34417 6.40597 9.43817 6.61155 9.58435C6.79376 9.71391 6.95296 9.87311 7.08252 10.0553C7.2287 10.2609 7.3227 10.5053 7.51071 10.9941L8.66683 14L9.82295 10.9941C10.011 10.5053 10.105 10.2609 10.2511 10.0553C10.3807 9.87311 10.5399 9.71391 10.7221 9.58435C10.9277 9.43817 11.1721 9.34417 11.6609 9.15616L14.6668 8.00004L11.6609 6.84392C11.1721 6.65591 10.9277 6.56191 10.7221 6.41573C10.5399 6.28617 10.3807 6.12697 10.2511 5.94476C10.105 5.73918 10.011 5.49477 9.82295 5.00595L8.66683 2.00004Z"
          stroke={props.color || '#4D4DFF'}
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4089_690">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiStarIcon;
