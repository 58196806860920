import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getReportQuestionsGraphql = async (
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetReportQuestionDetails(
        $orgId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        ReportQuestionDetails(
          orgId: $orgId
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            question
            icon
            questionType
            description
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      pageNumber,
      pageSize,
    }
  );

export const getReportQuestionDetailsGraphql = async (
  reportId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetReportDetails(
        $reportId: String!
        $startDate: String!
        $endDate: String!
        $orgId: String!
        $sprintId: String!
      ) {
        ReportDetails(
          reportId: $reportId
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          metadata {
            zeroStates {
              type
              url
            }
          }
          reportId
          reports {
            headline
            reportMetrics {
              metric
              suggestedActions {
                text
                cta
              }
              status
              zeroState
            }
            suggestedActions {
              text
              cta
            }
          }
        }
      }
    `,
    {
      reportId,
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
    }
  );

export const sprintGraphql = async (
  metric: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintMetricsByType(
        $metric: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $orgId: String!
      ) {
        SprintMetricsByType(
          metric: $metric
          granularity: ""
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          chartDataState
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      metric,
      teamId,
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
    }
  );

export const scopeChangeMetricGraphql = async (
  metric: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintMetricsByType(
        $metric: String!
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
      ) {
        SprintMetricsByType(
          metric: $metric
          granularity: ""
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
          }
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
          }
          data
          keys {
            key
            name
            color
          }
          average
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          highlight
          chartDataState
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      metric,
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
      sprintId,
    }
  );

export const issueTimelineDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  type: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCustomReportMetricByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $type: String!
        $metric: String!
      ) {
        CustomReportMetricByType(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            type: $type
          }
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      type: type,
      metric: "ISSUE_DISTRIBUTION",
    }
  );
