import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

interface DonutChartProps {
  planningAccuracy: number;
}

const DonutChart = ({ planningAccuracy }: DonutChartProps) => {
  const total = planningAccuracy < 100 ? 100 : planningAccuracy;
  const valueToShow = planningAccuracy || 0;
  const remainingValue = total - valueToShow;

  const data = [
    { name: 'Value', value: valueToShow, color: '#0095E6' },
    { name: 'Remaining', value: remainingValue, color: '#CBD5E0' },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={16}
        >
          {valueToShow}%
        </text>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={35}
          outerRadius={50}
          style={{ outline: 'none' }}
          fill="#8884d8"
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
