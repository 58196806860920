import React, { FC } from 'react';
import {
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';

type TriggerType = 'hover' | 'click';

type ConditionalProps =
  | {
      label: string;
      content?: never;
    }
  | {
      label?: never;
      content: React.ReactNode;
    };

type AppTooltipProps = {
  children: React.ReactNode;
  trigger: TriggerType;
  headingText?: string;
  hasCloseBtn?: boolean;
  placement?: PlacementWithLogical;
};

type TooltipProps = AppTooltipProps & ConditionalProps;

/**
 * @prop {string} label (label or content required)
 * @prop {React.ReactNode} content (content or label required)
 * @prop {TriggerType} trigger (required)
 * @prop {string} headingText (optional)
 * @prop {boolean} hasCloseBtn (optional)
 * @prop {string} placement (optional)
 * @see [codesandbox]{@link https://codesandbox.io/s/ecstatic-curran-ylumex}
 */

export const AppTooltip: FC<TooltipProps> = ({
  children,
  trigger,
  headingText,
  hasCloseBtn,
  label,
  content,
  placement,
}) => (
  <Popover isLazy trigger={trigger} placement={placement}>
    <PopoverTrigger>
      <button>{children}</button>
    </PopoverTrigger>
    <Portal>
      <PopoverContent boxShadow={'xl'} w="fit-content" maxW="360px">
        <PopoverArrow />

        {headingText && <PopoverHeader>{headingText}</PopoverHeader>}
        {hasCloseBtn && <PopoverCloseButton />}

        <PopoverBody>
          {label && (
            <Text fontSize="sm" fontFamily="heading">
              {label}
            </Text>
          )}

          {content && content}
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
);

export default AppTooltip;
