import { Box, Image, SkeletonText, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { InfoSlideBar } from './InfoSlideBar';
import { useGetAIGeneratedDataForInfoTooltip, useGetMetricInfo } from './apis';
import AIStartIcon from '../shared/assets/ai-star.svg';
import { splitTime } from '../shared/utils';

interface SlideInfoDrawerProps {
  openDrawer: boolean;
  handelDrawerClose: () => void;
  title?: string;
  chartKey: string;
  item?: any;
  goalData?: any;
}

export const SlideInfoDrawer: React.FC<SlideInfoDrawerProps> = ({
  openDrawer,
  handelDrawerClose,
  title = '',
  chartKey,
  item,
  goalData,
}) => {
  const { data: metricData, isLoading } = useGetMetricInfo(chartKey);
  const [typedContent, setTypedContent] = useState('');
  const [typedContent2, setTypedContent2] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);

  let metricCount = '';
  let metricName = '';
  let metricTrend = '';
  let metricValueDesc = '';
  let metricGoal = '';

  if (item?.threshold) {
    metricCount = `${item?.threshold?.value} ${item?.threshold?.title}`;
    metricName = chartKey;
    metricTrend = 'do not consider';
    metricValueDesc = item?.threshold?.desc;
    metricGoal = goalData?.dto?.fields[0]?.low
      ? `>=${goalData?.dto?.fields[0]?.low} ${goalData?.dto?.fields[0]?.unit} - <${goalData?.dto?.fields[2]?.high} ${goalData?.dto?.fields[2]?.unit}`
      : `<${goalData?.dto?.fields[0]?.high} ${goalData?.dto?.fields[0]?.unit} - >=${goalData?.dto?.fields[2]?.low} ${goalData?.dto?.fields[2]?.unit}`;
  } else {
    metricCount =
      item?.chartMetadata?.ylabel === 'Minutes'
        ? `${splitTime(item?.average / 60)} ${item?.averageLabel}`
        : `${item?.average} ${item?.averageLabel}`;
    metricName = chartKey;
    metricTrend = `${item?.previousPeriodStat?.percentChange}% (${item?.previousPeriodStat?.improved})`;
    metricValueDesc = 'do not consider';
    metricGoal = 'do not consider';
  }

  const { data: aiGeneratedData, isFetching: isFetchingAiGeneratedData } =
    useGetAIGeneratedDataForInfoTooltip(
      metricCount,
      metricName,
      metricTrend,
      metricValueDesc,
      metricGoal
    );

  const [firstParagraph, setFirstParagraph] = useState('');
  const [remainingContent, setRemainingContent] = useState('');

  useEffect(() => {
    if (metricData?.data) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(metricData.data, 'text/html');

      const firstParagraphElement = doc.querySelector('p');
      const firstParagraphContent = firstParagraphElement?.outerHTML || '';
      setFirstParagraph(firstParagraphContent);

      let remainingContent = '';

      if (firstParagraphElement) {
        let currentElement: Element | null =
          firstParagraphElement.nextElementSibling;

        while (currentElement) {
          remainingContent += currentElement.outerHTML;
          currentElement = currentElement.nextElementSibling;
        }
      }
      setRemainingContent(remainingContent);
    }
  }, [metricData?.data]);

  useEffect(() => {
    if (firstParagraph && currentIndex < firstParagraph.length) {
      const typeCharacter = () => {
        const nextIndex = currentIndex + 1;
        const newContent = firstParagraph.slice(currentIndex, nextIndex);

        if (newContent) {
          setTypedContent((prevContent) => prevContent + newContent);
          setCurrentIndex(nextIndex);
        }
      };

      const timeout = setTimeout(typeCharacter, 0.5);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [currentIndex, firstParagraph]);

  useEffect(() => {
    if (currentIndex < firstParagraph.length) return;
    if (remainingContent && currentIndex2 < remainingContent.length) {
      const typeCharacter = () => {
        const nextIndex = currentIndex2 + 1;
        const newContent = remainingContent.slice(currentIndex2, nextIndex);

        if (newContent) {
          setTypedContent2((prevContent) => prevContent + newContent);
          setCurrentIndex2(nextIndex);
        }
      };

      const timeout = setTimeout(typeCharacter, 0.5);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [currentIndex2, remainingContent, currentIndex, firstParagraph.length]);

  return (
    <InfoSlideBar
      width={'45vw'}
      openDrawer={openDrawer}
      handleDrawerClose={handelDrawerClose}
      sideBarHeading={title}
      sideBarSubHeading=""
      content={
        <Box
          border={'1px solid #4D4DFF'}
          borderRadius={'8px'}
          marginBottom={10}
          minHeight={'85vh'}
        >
          {isLoading || metricData?.data === undefined ? (
            <Box textAlign="center" padding={4}>
              <SkeletonText noOfLines={24} spacing="3" />
            </Box>
          ) : (
            <>
              <Box
                padding={4}
                fontSize={'14px'}
                dangerouslySetInnerHTML={{ __html: typedContent }}
                fontFamily={'inter'}
                sx={{
                  h1: {
                    marginTop: '4px',
                    marginBottom: '2px',
                    fontWeight: 'bold',
                  },
                  ul: {
                    marginLeft: '20px',
                    listStyleType: 'disc',
                    padding: '5px 0 5px 0',
                  },
                  li: {
                    padding: '3px 0 3px 0',
                  },
                }}
              />
              {firstParagraph && currentIndex >= firstParagraph.length && (
                <Box
                  position="relative"
                  mx={4}
                  p={3}
                  borderRadius="6px"
                  bg="white"
                  backgroundClip="padding-box"
                >
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    margin="-2px"
                    borderRadius="6px"
                    bgGradient="linear(to-r, #4D4DFF 0%, #C850C0 50%, #FFCC70 100%)"
                    zIndex="-1"
                  />
                  <Box fontFamily="Inter">
                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                      <Text fontWeight="600" fontSize="16px">
                        Interpretation by
                      </Text>
                      <Text
                        bgGradient="linear(to-r, #4D4DFF 0%, #C850C0 40%, #FFCC70 100%)"
                        bgClip="text"
                        fontWeight="600"
                        fontSize="16px"
                      >
                        DevDynamics AI
                      </Text>
                      <Image src={AIStartIcon} alt="AI Icon" boxSize="16px" />
                    </Box>
                    <Box fontSize={'14px'} fontFamily={'inter'}>
                      {isFetchingAiGeneratedData ? (
                        <Box textAlign="center">
                          <SkeletonText mt="3" noOfLines={6} spacing="3" />
                        </Box>
                      ) : (
                        aiGeneratedData?.content
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                paddingX={4}
                fontSize={'14px'}
                dangerouslySetInnerHTML={{ __html: typedContent2 }}
                fontFamily={'inter'}
                sx={{
                  h1: {
                    marginTop: '4px',
                    marginBottom: '2px',
                    fontWeight: 'bold',
                  },
                  ul: {
                    marginLeft: '20px',
                    listStyleType: 'disc',
                    padding: '5px 0 5px 0',
                  },
                  li: {
                    padding: '3px 0 3px 0',
                  },
                }}
              />
            </>
          )}
        </Box>
      }
    />
  );
};
