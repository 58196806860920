import { Text, Tooltip } from '@chakra-ui/react';

export const RenderCell = (props: any) => (
  <>
    {props.tooltip ? (
      <Tooltip hasArrow label={props.tooltip}>
        <Text
          {...props}
          as="div"
          fontSize={props.fontSize || 'sm'}
          fontWeight={props.fontWeight || 'semibold'}
          lineHeight={props.lineHeight || '14px'}
          fontFamily={props.fontFamily || 'body'}
          maxW={props.maxW || '120px'}
          color={props.color || 'text.primary'}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow={'ellipsis'}
        >
          {props.value}
        </Text>
      </Tooltip>
    ) : props.component ? (
      <Text
        {...props}
        as="div"
        fontSize={props.fontSize || 'sm'}
        fontWeight={props.fontWeight || 'semibold'}
        lineHeight={props.lineHeight || '14px'}
        fontFamily={props.fontFamily || 'body'}
        maxW={props.maxW || '120px'}
        color={props.color || 'text.primary'}
      >
        {props.component}
      </Text>
    ) : (
      <Text
        {...props}
        as="div"
        fontSize={props.fontSize || 'sm'}
        fontWeight={props.fontWeight || 'semibold'}
        lineHeight={props.lineHeight || '14px'}
        fontFamily={props.fontFamily || 'body'}
        maxW={props.maxW || '120px'}
        color={props.color || 'text.primary'}
      >
        {props.value}
      </Text>
    )}
  </>
);
