import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext, RequireAuth, useAuth } from '@devd-client/devd/auth';
import Settings from './screens/Settings/settings';
import MetricsPage from './screens/Metrics/metrics';
import { AsyncGitInstallation } from './screens/gitInstallationPage/asyncGitInstallation';
import { AsyncGmeetInstallation } from './screens/gMeet/asyncGMeet';
import { AsyncInvitation } from './screens/invitation/asynsInvitation';
import { AsyncRegister } from './screens/register/asyncRegister';
import AsyncLogin from './screens/Login/login';
import AsyncSignup from './screens/Signup';
import Dashboard from './screens/Dashboard/dashboard';
import { AnnouncementIcon, AppLayout } from '@devd-client/devd/components';
import { useContext, useEffect, useRef, useState } from 'react';
import { AsyncMsTeamsInstallation } from './screens/msTeamsInstallationPage/asyncMsTeamsInstallation';
import Fallback from './screens/Status/Fallback';
import NotFound from './screens/Status/404NotFound';
import AsyncResetPassword from './screens/resetPassword';
import Maintenance from './screens/Status/Maintenance';
import { AsyncGitLabInstallation } from './screens/gitLabInstallationPage/asyncGitLabInstallation';
import { AsyncClickupInstallation } from './screens/clickupInstallationPage/asyncClickupInstallation';
import { AsyncAsyncIntercomInstallation } from './screens/intercomInstallation/asyncIntercomInstallation';
import {
  Box,
  Flex,
  Stack,
  Text,
  useTheme,
  useToast,
  CloseButton,
  Image,
} from '@chakra-ui/react';
import AsyncOnboarding from './screens/Onboarding';

const AppRoutes = () => {
  const [, , handleLogout] = useContext(AuthContext);
  const [appState] = useAuth();
  const toast = useToast();
  const { colors } = useTheme();

  // Turn this flag to true to show maintenance page
  const isUnderMaintenance = false;

  const toastRefs = useRef<any>([]);

  const email = appState?.user?.email;

  useEffect(() => {
    if (appState?.user?.dto?.announcement) {
      localStorage.setItem(
        'announcement',
        JSON.stringify(appState?.user?.dto?.announcement)
      );
    }
  }, [appState?.user?.dto?.announcement]);

  useEffect(() => {
    if (!localStorage.getItem('announcement')) return;
    const announcement = JSON.parse(
      localStorage.getItem('announcement') as any
    );
    toast({
      position: 'bottom-left',
      duration: 10000,
      render: ({ onClose }) => {
        toastRefs.current.push(onClose);
        return (
          <Box
            p={4}
            borderRadius="4px"
            border="1px solid"
            borderColor="gray.300"
            width="380px"
            bg="white"
            zIndex="1000"
            boxShadow="sm"
            height="fit-content"
          >
            <Flex w="full" align="flex-start">
              <AnnouncementIcon
                color={colors.text.primary}
                height="20px"
                width="20px"
              />
              <Stack w="full" ml={3} spacing={1}>
                <Flex w="100%" align="center" justify="space-between">
                  <Flex align="center">
                    <Text
                      mr={8}
                      fontFamily="heading"
                      fontSize="md"
                      fontWeight="semibold"
                      color="text.primary"
                    >
                      {announcement?.title}
                    </Text>
                    {announcement?.icon && (
                      <Image boxSize={8} src={announcement?.icon} />
                    )}
                  </Flex>
                  <CloseButton
                    size="sm"
                    onClick={() => {
                      toastRefs.current.forEach((cb: any) => cb());
                      toastRefs.current = [];
                    }}
                  />
                </Flex>
                <Text
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="normal"
                  color="text.secondary"
                >
                  {announcement?.description}
                </Text>
              </Stack>
            </Flex>
          </Box>
        );
      },
    });
  }, []);

  return (
    <Routes>
      {isUnderMaintenance ? (
        <Route path="/*" element={<Maintenance />} />
      ) : (
        <>
          <Route
            path="/"
            element={
              localStorage.getItem('role') === 'EMPLOYEE' ? (
                <Navigate to={`/contributors/${email}`} />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <AppLayout
                  appState={appState}
                  handleLogout={handleLogout}
                  splitSidebar="none"
                >
                  <Dashboard />
                </AppLayout>
              </RequireAuth>
            }
          >
            <Route path=":appId" />
          </Route>
          <Route
            path="/metrics"
            element={
              <RequireAuth>
                <AppLayout
                  appState={appState}
                  handleLogout={handleLogout}
                  splitSidebar="metrics"
                >
                  <MetricsPage />
                </AppLayout>
              </RequireAuth>
            }
          >
            <Route path=":metricsId" />
          </Route>
          <Route
            path="/settings/*"
            element={
              <RequireAuth>
                <AppLayout
                  appState={appState}
                  handleLogout={handleLogout}
                  splitSidebar="settings"
                >
                  <Settings />
                </AppLayout>
              </RequireAuth>
            }
          >
            <Route path=":settingsId" />
          </Route>

          <Route path="account">
            <Route path="login" element={<AsyncLogin />} />
            <Route path="signup" element={<AsyncSignup />} />
            <Route path="reset-password" element={<AsyncResetPassword />} />
            <Route index element={<Navigate to={'/account/login'} />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/onboarding" element={<AsyncOnboarding />} />

          <Route
            path="/github/installation"
            element={<AsyncGitInstallation />}
          />
          <Route
            path="/gitlab/installation"
            element={<AsyncGitLabInstallation />}
          />
          <Route
            path="/clickup/installation"
            element={<AsyncClickupInstallation />}
          />
          <Route
            path="/gmeet/installation"
            element={<AsyncGmeetInstallation />}
          />
          <Route
            path="/intercom/installation"
            element={<AsyncAsyncIntercomInstallation />}
          />
          <Route
            path="/msteams/installation"
            element={<AsyncMsTeamsInstallation />}
          />
          <Route
            path="/webex/installation"
            element={<AsyncMsTeamsInstallation />}
          />
          <Route
            path="/outlook/installation"
            element={<AsyncMsTeamsInstallation />}
          />
          <Route path="/invitation" element={<AsyncInvitation />} />
          <Route path="/register" element={<AsyncRegister />} />
          <Route path="/error" element={<Fallback />} />

          <Route path="*" element={<NotFound />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
