import {
  Box,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import {
  AppTooltip,
  CFR_CHART_KEYS,
  COMMIT_CHART_KEYS,
  CUSTOM_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  DangerIcon,
  DataGrid,
  DownloadIcon,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  ISelect,
  IconButtonContainer,
  Loader,
  MEETING_CHARTS,
  MetricSidebar,
  PR_CHART_KEYS,
  Paginate,
  PeriodType,
  splitTime,
  useToastHook,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { QUERY_KEYS, useFetchContributorsGql } from '../../../graphql';
import { useCallback, useMemo, useState } from 'react';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CycleTimeItem from '../../ListView/CycleTimeItem';
import { convertToTimeFormat } from '../../../helpers/team-details.utils';
import { apiClient } from '@devd-client/api';

interface ContributorsProps {
  selected: PeriodType;
  selectedTeam: ISelect;
}

const Contributors = ({ selected, selectedTeam }: ContributorsProps) => {
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const [pending, setPending] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const emailmodal = useDisclosure();

  const { pathname } = useLocation();
  const { colors } = useTheme();

  const [heading, setHeading] = useState<string>('');
  const [count, setCount] = useState<string>('');
  const [metricsName, setMetricsName] = useState<string>('');
  const [emailId, setEmailId] = useState<string>('');

  const {
    data: contributorsData,
    isLoading,
    isFetching,
  } = useFetchContributorsGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname.split('/')[2]),
    currentPage,
    30,
    '',
    ''
  );

  const itemMeta = contributorsData?.itemMeta;

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v1/team/org/${localStorage?.getItem(
        'orgId'
      )}/contributor-report?startDate=${selected.startDate}&endDate=${
        selected.endDate
      }&team=${selectedTeam.value}`,
      {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage?.getItem('token')}`,
        },
      }
    )
      .then((res: any) => {
        setPending(false);
        newToast({
          message: res?.dto,
          status: 'success',
        });
      })
      .catch((err) => {
        setPending(false);
        newToast({
          message:
            err.message ||
            'There is some error in downloading data. Please try again or contact support at support@devdynamics.ai',
          status: 'error',
        });
      });
  };

  const rows: any[] = useMemo(
    () => contributorsData?.data || [],
    [contributorsData?.data]
  );

  const isMetricType = itemMeta?.map((item: any) => item.metric);

  const columnHelper = createColumnHelper<any>();

  const columns: any = useMemo(
    () =>
      contributorsData?.data && contributorsData?.data[0]
        ? Object.keys(contributorsData?.data[0])
            .filter((el: any) => el !== 'prCycleTimeBreakUp')
            .map((key: any, idx: any) => {
              return key === 'contributorAccount'
                ? columnHelper.accessor('contributorAccount', {
                    header: () => (
                      <Box border={2} borderColor="black">
                        <Text>
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.label
                          }
                        </Text>
                        <Text fontSize="xxs" fontWeight="normal">
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.measurement
                          }
                        </Text>
                      </Box>
                    ),

                    cell: (info) => {
                      return (
                        <Link to={`/contributors/${info.getValue()}`}>
                          <Text
                            textDecoration="underline"
                            fontSize="sm"
                            fontWeight="medium"
                            color="primary"
                            _hover={{
                              color: 'blue.600',
                            }}
                          >
                            {info.getValue()}
                          </Text>
                        </Link>
                      );
                    },
                  })
                : key === 'gitActivity'
                ? columnHelper.accessor('gitActivity', {
                    header: () => (
                      <Box>
                        <Text>Git Activity</Text>
                        <Text fontSize="xxs" fontWeight="normal">
                          Count
                        </Text>
                      </Box>
                    ),
                    enableSorting: false,

                    cell: (info) => (
                      <Stack spacing={1}>
                        {info.row.original?.gitActivity?.map(
                          (row: any, index: number) => (
                            <Flex key={index} align="center">
                              <Flex align="center" width="48px">
                                <Box
                                  height="16px"
                                  width="2px"
                                  borderRadius="2px"
                                  bg={row.fillColor}
                                />
                                <Tooltip label="View Details">
                                  <Text
                                    ml={1.5}
                                    fontSize="xs"
                                    color="text.primary"
                                    textDecoration={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'underline'
                                        : 'none'
                                    }
                                    cursor={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'pointer'
                                        : 'default'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setEmailId(
                                        info.row.original.contributorAccount
                                      );
                                      setMetricsName(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      );
                                      setHeading(row.label);
                                      setCount(row.count);
                                      emailmodal.onOpen();
                                    }}
                                  >
                                    {row.count}
                                  </Text>
                                </Tooltip>
                              </Flex>
                              <Text
                                fontSize="xs"
                                fontFamily="heading"
                                fontWeight={'normal'}
                                color="text.secondary"
                                whiteSpace={'nowrap'}
                              >
                                {row?.label}
                              </Text>
                            </Flex>
                          )
                        )}
                      </Stack>
                    ),
                  })
                : key === 'issueActivity'
                ? columnHelper.accessor('issueActivity', {
                    header: () => (
                      <Box>
                        <Text>Issue Activity</Text>
                        <Text fontSize="xxs" fontWeight="normal">
                          Count
                        </Text>
                      </Box>
                    ),
                    enableSorting: false,

                    cell: (info) => (
                      <Stack spacing={1}>
                        {info.row.original?.issueActivity?.map(
                          (row: any, index: number) => (
                            <Flex key={index} align="center">
                              <Flex align="center" width="48px">
                                <Box
                                  height="16px"
                                  width="2px"
                                  borderRadius="2px"
                                  bg={row.fillColor}
                                />
                                <Tooltip label="View Details">
                                  <Text
                                    ml={1.5}
                                    fontSize="xs"
                                    color="text.primary"
                                    textDecoration={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'underline'
                                        : 'none'
                                    }
                                    cursor={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'pointer'
                                        : 'default'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setEmailId(
                                        info.row.original.contributorAccount
                                      );
                                      setMetricsName(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      );
                                      setHeading(row.label);
                                      setCount(row.count);
                                      emailmodal.onOpen();
                                    }}
                                  >
                                    {row.count}
                                  </Text>
                                </Tooltip>
                              </Flex>
                              <Text
                                fontSize="xs"
                                fontFamily="heading"
                                fontWeight={'normal'}
                                color="text.secondary"
                                whiteSpace={'nowrap'}
                              >
                                {row?.label}
                              </Text>
                            </Flex>
                          )
                        )}
                      </Stack>
                    ),
                  })
                : key === 'meetingActivity'
                ? columnHelper.accessor('meetingActivity', {
                    header: () => (
                      <Box>
                        <Text>Meeting Activity</Text>
                      </Box>
                    ),
                    enableSorting: false,

                    cell: (info) => (
                      <Stack spacing={1}>
                        {info.row.original?.meetingActivity?.map(
                          (row: any, index: number) => (
                            <Flex key={index} align="center">
                              <Flex align="center" width="88px">
                                <Box
                                  height="16px"
                                  width="2px"
                                  borderRadius="2px"
                                  bg={row.fillColor}
                                />
                                <Tooltip label="View Details">
                                  <Text
                                    ml={1.5}
                                    fontSize="xs"
                                    color="text.primary"
                                    textDecoration={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'underline'
                                        : 'none'
                                    }
                                    cursor={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'pointer'
                                        : 'default'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setEmailId(
                                        info.row.original.contributorAccount
                                      );
                                      setMetricsName(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      );
                                      setHeading(row.label);
                                      setCount(
                                        row.label === 'Total Meeting time'
                                          ? splitTime(Number(row.count) / 60)
                                          : row.count
                                      );
                                      emailmodal.onOpen();
                                    }}
                                  >
                                    {row.label === 'Total Meeting time'
                                      ? splitTime(Number(row.count) / 60)
                                      : row.count}
                                  </Text>
                                </Tooltip>
                              </Flex>
                              <Text
                                fontSize="xs"
                                fontFamily="heading"
                                fontWeight={'normal'}
                                color="text.secondary"
                                whiteSpace={'nowrap'}
                              >
                                {row?.label}
                              </Text>
                            </Flex>
                          )
                        )}
                      </Stack>
                    ),
                  })
                : key === 'prCycleTime'
                ? columnHelper.accessor('prCycleTime', {
                    header: () => (
                      <Box border={2} borderColor="black">
                        <Text>
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.label
                          }
                        </Text>
                        <Text fontSize="xxs" fontWeight="normal">
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.measurement
                          }
                        </Text>
                      </Box>
                    ),
                    enableSorting: false,

                    cell: (info) => (
                      <AppTooltip
                        content={
                          <Box textAlign={'start'}>
                            <Text
                              fontFamily="heading"
                              fontSize="medium"
                              color="text.primary"
                            >
                              PR cycle time breakdown
                            </Text>

                            <Text
                              fontFamily="heading"
                              fontSize="sm"
                              color="text.secondary"
                            >
                              {splitTime(Number(info.getValue()) / 60)}
                            </Text>
                            <HStack mt={3} justify={'center'} spacing={4}>
                              <CycleTimeItem
                                title="PICKUP"
                                time={convertToTimeFormat(
                                  info.row.original?.prCycleTimeBreakUp
                                    ?.pickupCycleTime / 60
                                )}
                              />
                              <CycleTimeItem
                                title="MERGE"
                                time={convertToTimeFormat(
                                  info.row.original?.prCycleTimeBreakUp
                                    ?.mergeCycleTime / 60
                                )}
                              />
                            </HStack>
                          </Box>
                        }
                        trigger="hover"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEmailId(info.row.original?.contributorAccount);
                            setMetricsName(
                              itemMeta.filter(
                                (item: any) => item.key === key
                              )[0]?.metric
                            );
                            setHeading(
                              itemMeta.filter(
                                (item: any) => item.key === key
                              )[0]?.label
                            );
                            setCount(splitTime(Number(info.getValue()) / 60));
                            emailmodal.onOpen();
                          }}
                          fontSize="sm"
                          textDecoration="none"
                          fontWeight="medium"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {info.getValue() &&
                            splitTime(Number(info.getValue()) / 60)}
                        </Text>
                      </AppTooltip>
                    ),
                  })
                : isMetricType.includes(
                    itemMeta.filter((item: any) => item.key === key)[0]?.metric
                  )
                ? columnHelper.accessor(key, {
                    header: () => (
                      <Box border={2} borderColor="black">
                        <Text>
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.label
                          }
                        </Text>
                        <Text fontSize="xxs" fontWeight="normal">
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.measurement
                          }
                        </Text>
                      </Box>
                    ),

                    cell: (info) => <>{info.getValue()}</>,
                  })
                : columnHelper.accessor(key, {
                    header: () => <></>,

                    cell: () => null,
                  });
            })
        : [],
    [contributorsData?.data]
  );

  return (
    <Box>
      <Flex gap={4} mb={2} w="full" justify={'flex-end'}>
        <Flex gap={3} justify={'center'} align={'center'}>
          <Box w={5}>
            <Icon as={DangerIcon} />
          </Box>
          <Link to={'/settings/contributors'}>
            <Text
              fontFamily={'heading'}
              fontSize={'xs'}
              fontWeight={'semibold'}
              textDecoration={'underline'}
            >
              Dont't see any data? <br /> Merge all the contributor accounts.
            </Text>
          </Link>
        </Flex>
        <Tooltip
          label="Download Contributor report data"
          hasArrow
          placement="bottom-end"
        >
          <IconButtonContainer onClick={downloadCSV}>
            <DownloadIcon
              height="16px"
              width="16px"
              color={colors.text.secondary}
            />
          </IconButtonContainer>
        </Tooltip>
      </Flex>

      <DataGrid
        showLoader={isLoading}
        columns={columns}
        data={rows}
        maxH="24rem"
        sticky="firstCol"
      />
      <Box marginLeft="-20px">
        <Paginate
          currentPage={contributorsData?.currentPage + 1}
          totalPageCount={contributorsData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>

      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...GOAL_METRIC_GIT_KEYS,
        ...GOAL_METRIC_JIRA_KEYS,
        ...COMMIT_CHART_KEYS,
        ...CUSTOM_CHART_KEYS,
        ...MEETING_CHARTS,
      ].includes(metricsName) &&
        emailmodal.isOpen && (
          <MetricSidebar
            querykey={QUERY_KEYS.contributorsList}
            openDrawer={emailmodal.isOpen}
            handleDrawerClose={emailmodal.onClose}
            heading={heading}
            subHeading={`${moment(selected.startDate).format(
              'DD MMM'
            )} - ${moment(selected.endDate).format(
              'DD MMM'
            )}, ${heading} (${count})
           `}
            payload={{
              payload: {
                startDate: selected.startDate,
                endDate: selected.endDate,
              },
            }}
            currentChartMeta={{
              chartKey: metricsName === 'CUSTOM' ? heading : metricsName,
            }}
            filterBy={{
              author: emailId,
              teamId: decodeURIComponent(pathname.split('/')[2]),
              sprintId: selected.sprintId,
            }}
            selectedTeam={decodeURIComponent(pathname.split('/')[2])}
          />
        )}

      {isFetching && <Loader />}
    </Box>
  );
};

export default Contributors;
