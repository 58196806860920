import {
  Box,
  Flex,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Text,
  Tooltip,
  Button,
  useDisclosure,
  Divider,
  Grid,
  Center,
  Image,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { ConfirmButton, Loader } from '@devd-client/devd/components';
import { FC, useEffect, useState, useMemo } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import NoDashboard from '../../../../../components/src/shared/assets/no-dashboard.png';
import { FaArrowDown, FaArrowUp, FaArrowRight } from 'react-icons/fa';

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  createColumnHelper,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';

import {
  useDeleteCustomDashboard,
  useGetAllCustomDashboards,
} from '../../../api';
import { QUERY_KEYS } from '../../../api';
import UpdateDashboardModel from '../UpdateDashboardModal';
import CreateDashboardModal from '../CreateDashboardModal';
import { IoChevronDownCircleOutline } from 'react-icons/io5';

interface CustomMetricsListProps {
  setSelectedItem: (selectedItem: any) => void;
  searchTerm?: string;
}

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  cursor: pointer;
  color: #000;
  color: ${(props: any) => props.theme.colors.primary};
  text-decoration: underline;
`;

const CustomMetricsList: FC<CustomMetricsListProps> = ({
  setSelectedItem,
  searchTerm,
}) => {
  const { data, isFetching: allCustomDashboardLoading } =
    useGetAllCustomDashboards();
  const createDashboardModal = useDisclosure();
  const { mutate: deleteCustomDashboard } = useDeleteCustomDashboard();
  const queryClient = useQueryClient();
  const updateDashboardModal = useDisclosure();
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const columnHelper = createColumnHelper<any>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const filteredData = useMemo(() => {
    if (!searchTerm) return data?.dto || [];
    return data?.dto.filter((dashboard: any) =>
      dashboard.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  const handleSort = (direction: 'asc' | 'desc') => {
    setSorting([{ id: 'updatedAt', desc: direction === 'desc' }]);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <StyledLink
            to={{
              pathname: '',
              search: `?id=${info.getValue()}`,
            }}
            onClick={() => setSelectedItem(info.getValue())}
          >
            {info.getValue()}
          </StyledLink>
        ),
        header: 'Dashboard name',
        size: 700,
        enableSorting: false,
      }),
      columnHelper.accessor('updatedAt', {
        cell: (info) => {
          const date = new Date(info.getValue());
          return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
        header: () => (
          <Flex align="center">
            <Text>Last Updated at</Text>
            <Menu>
              {({ onClose }) => (
                <>
                  <MenuButton
                    as={IconButton}
                    icon={<IoChevronDownCircleOutline />}
                    size="sm"
                    variant="ghost"
                    ml={2}
                    aria-label="Sort Options"
                  />
                  <MenuList minW={'auto'}>
                    <Text
                      _hover={{ bg: 'gray.100' }}
                      padding={'8px'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={1}
                      cursor={'pointer'}
                      fontWeight={'400'}
                      onClick={() => {
                        handleSort('asc');
                        onClose();
                      }}
                    >
                      <FaArrowUp size={10} />
                      Sort oldest <FaArrowRight size={10} /> newest
                    </Text>
                    <Text
                      _hover={{ bg: 'gray.100' }}
                      padding={'8px'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={1}
                      cursor={'pointer'}
                      fontWeight={'400'}
                      onClick={() => {
                        handleSort('desc');
                        onClose();
                      }}
                    >
                      <FaArrowDown size={10} />
                      Sort newest <FaArrowRight size={10} /> oldest
                    </Text>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        ),
        maxSize: 300,
        enableSorting: true,
      }),
      columnHelper.accessor('actions', {
        cell: (info) => (
          <Flex>
            <Tooltip label="Rename" hasArrow>
              <IconButton
                aria-label="edit"
                bg="transparent"
                size="sm"
                color="text.secondary"
                icon={<MdOutlineEdit size={16} />}
                position={'static'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateDashboardModal.onOpen();
                  setSelectedDashboard(info.row.original.name);
                }}
              />
            </Tooltip>

            <ConfirmButton
              buttonText="Delete"
              showIcon
              bodyText={`Do you really want to delete this Dashboard - ${info.row.original.name}?`}
              headerText="Are you sure?"
              onSuccessAction={() => {
                deleteCustomDashboard(
                  { dashboard: info.row.original.name },
                  {
                    onSuccess() {
                      queryClient.invalidateQueries([
                        QUERY_KEYS.customDashboards,
                      ]);
                    },
                    onError(err) {
                      console.log(err);
                    },
                  }
                );
              }}
            />
          </Flex>
        ),
        header: 'Actions',
        maxSize: 50,
        enableSorting: false,
      }),
    ],
    [filteredData]
  );

  const tableRows: any[] = useMemo(() => filteredData || [], [filteredData]);
  const table = useReactTable({
    columns,
    data: tableRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
  });

  return (
    <>
      <Box>
        {filteredData?.length === 0 && !allCustomDashboardLoading ? (
          <Box
            h={'75vh'}
            w={'full'}
            display={'flex'}
            gap={10}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Image
              src={NoDashboard}
              alt="no-dashboard"
              height={'264px'}
              width={'264px'}
            />
            <Box
              fontFamily={'inter'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={4}
            >
              <Text color={'#2A2A2F'} fontWeight={600} fontSize={'28px'}>
                No dashboard found.
              </Text>
              <Text color={'#626266'} fontWeight={400} fontSize={'16px'}>
                Start creating dashboards to identify bottlenecks and optimize
                workflows.
              </Text>
              <Button
                rounded={'4px'}
                bg={'#4D4DFF'}
                paddingY={'8px'}
                paddingX={'12px'}
                border={'none'}
                color={'white'}
                width={'fit-content'}
                fontSize={'16px'}
                _hover={{ bg: '#4D4DFF' }}
                onClick={createDashboardModal.onOpen}
              >
                Create Dashboard
              </Button>
            </Box>
          </Box>
        ) : (
          <Table>
            <Thead bg="#fff" boxShadow="0 1px 2px rgba(0,0,0,0.05)">
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      textTransform="none"
                      boxShadow="inset 0 0 0 0.2px rgba(0,0,0,0.1)"
                      fontSize="xs"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      fontFamily="heading"
                      color="text.primary"
                      fontSize="sm"
                      fontWeight="medium"
                      boxShadow="inset 0 0 0 0.2px rgba(0,0,0,0.1)"
                      w={cell.column.getSize()}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      {updateDashboardModal.isOpen && (
        <UpdateDashboardModel
          isOpen={updateDashboardModal.isOpen}
          onClose={updateDashboardModal.onClose}
          selectedDashboard={selectedDashboard}
        />
      )}

      {createDashboardModal.isOpen && (
        <CreateDashboardModal
          isOpen={createDashboardModal.isOpen}
          onClose={createDashboardModal.onClose}
        />
      )}

      {allCustomDashboardLoading && <Loader />}
    </>
  );
};

export default CustomMetricsList;
