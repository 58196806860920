import { Box, Divider, Flex, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import MetricColumn from './MetricColumn';
import {
  CustomTeamFilter,
  Loader,
  Team,
  useTeamComparisonStore,
} from '@devd-client/devd/components';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useAuth } from '@devd-client/devd/auth';
import SelectMetricColumn from './SelectMetricColumn';
import { defaultMetrics } from '../../helpers/team-details.utils';

type Props = {
  isChecked: boolean;
  metricsList: any;
  isLoading: boolean;
};

const TeamCompHeatMap = ({ isChecked, metricsList, isLoading }: Props) => {
  const [appState] = useAuth();
  const { colors } = useTheme();
  const {
    selectedTeams,
    setSelectedTeams,
    selectedMetrics,
    setSelectedMetrics,
  } = useTeamComparisonStore();

  useEffect(() => {
    if (
      Array.isArray(selectedMetrics) &&
      selectedMetrics.length === 0 &&
      metricsList &&
      metricsList?.dto
    ) {
      setSelectedMetrics(
        metricsList?.dto?.filter((el: any) => defaultMetrics.includes(el.name))
      );
    } else {
      setSelectedMetrics(selectedMetrics);
    }
  }, [metricsList, selectedMetrics]);

  useEffect(() => {
    const allTeam = { value: 'all', label: 'All Teams' };
    if (isChecked) {
      if (!selectedTeams.some((team: any) => team.value === 'all')) {
        setSelectedTeams([allTeam, ...selectedTeams]);
      }
    } else {
      setSelectedTeams(
        selectedTeams.filter((team: any) => team.value !== 'all')
      );
    }
  }, [isChecked]);

  return (
    <Flex
      overflowX={'scroll'}
      overflowY={'auto'}
      border="1px solid #E0E0E0"
      height="480px"
      width="100%"
      borderRadius="8px"
      position="relative"
    >
      <Flex
        direction="column"
        position="sticky"
        left={0}
        bg="white"
        h="100%"
        minW="240px"
        zIndex={100}
      >
        <Flex
          bg="#FBFBFB"
          position="sticky"
          top={0}
          align="center"
          minH="52px"
          px={4}
          zIndex={50}
        >
          <CustomTeamFilter
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            appState={appState}
            noDefault={false}
            isChecked={isChecked}
          >
            <Flex
              w="full"
              cursor="pointer"
              justify={'space-between'}
              align="center"
            >
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="medium"
                color="text.secondary2"
                w="190px"
              >
                Teams
              </Text>
              <MdOutlineKeyboardArrowDown
                size={18}
                color={colors.text.secondary2}
              />
            </Flex>
          </CustomTeamFilter>
        </Flex>

        <Divider />

        {selectedTeams?.map((row: any) => (
          <Fragment key={row.label}>
            <Flex
              direction="column"
              align="flex-start"
              justify={'center'}
              minH="52px"
              pl="16px"
              bg="white"
            >
              <Text
                fontFamily={'heading'}
                fontSize="sm"
                fontWeight="normal"
                color="text.gray.300"
              >
                {row.label}
              </Text>
              {row.label !== 'All Teams' && (
                <Flex gap={1} align="center">
                  <Tooltip hasArrow label="Members">
                    <Box>
                      <Team
                        height="12"
                        width="12"
                        color={colors.text.secondary3}
                      />
                    </Box>
                  </Tooltip>
                  <Text fontSize="xs" fontFamily="heading" color="text.primary">
                    {row.memberCount || 0}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Divider />
          </Fragment>
        ))}
      </Flex>

      {selectedMetrics?.map((metricData: any, idx: number) => (
        <MetricColumn
          data={metricData}
          key={idx}
          selectedTeams={selectedTeams}
          setSelectedMetrics={setSelectedMetrics}
          selectedMetrics={selectedMetrics}
          options={metricsList?.dto?.filter(
            (el: any) =>
              !selectedMetrics?.some((item: any) =>
                item.name === 'CUSTOM'
                  ? item.title === el.title
                  : item.name === el.name
              )
          )}
        />
      ))}
      <SelectMetricColumn
        data={selectedTeams || []}
        selectedMetrics={selectedMetrics}
        setSelectedMetrics={setSelectedMetrics}
        options={metricsList?.dto?.filter(
          (el: any) =>
            !selectedMetrics?.some((item: any) =>
              item.name === 'CUSTOM'
                ? item.title === el.title
                : item.name === el.name
            )
        )}
      />
      {isLoading && <Loader />}
    </Flex>
  );
};

export default TeamCompHeatMap;
