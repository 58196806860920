import { FC, useState } from 'react';
import { apiClient } from '@devd-client/api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook, DragDropFile } from '@devd-client/devd/components';
import { Loader } from '@devd-client/devd/components';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  ListItem,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';

interface AddMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  roleValue?: string;
  setRoleValue?: (role: string) => void;
  selectedMember: string[];
  setSelectedMember: (selectedMember: string[]) => void;
  handleFormSubmit: () => void;
  addMemberLoading: boolean;
  team: string;
}

const BulkUpload: FC<AddMemberModalProps> = ({ isOpen, onClose, team }) => {
  const [newToast] = useToastHook();
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [errorFileLink, setErrorFileLink] = useState(false);
  const queryClient = useQueryClient();

  const handleDownload = async () => {
    const url =
      'https://docs.google.com/spreadsheets/d/1yugv1RbKjOx4WSfdqVSad-VRAPgUgbHX6VPIbGg3jcA/export?format=csv';
    const a = document.createElement('a');
    a.href = url;
    a.download = 'spreadsheet.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleSubmitUpload = async () => {
    setLoading(true);
    setErrorFileLink(false);
    const data = new FormData();
    data.append('file', file);
    try {
      setLoading(true);
      apiClient(
        `/v1/team/members/org/${localStorage.getItem(
          'orgId'
        )}/teamName/${team}/import`,
        {
          method: 'POST',
          headers: {
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
          body: data,
        }
      )
        .then((res: any) => {
          setLoading(false);
          if (res?.error?.code === 'INVALID_FILE_DATA') {
            setErrorFileLink(true);
            const link = document.createElement('a');
            link.href = res?.dto;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            newToast({
              message:
                'Uploaded file seems to have some missing data/mismatch. Please follow the instructions in red below to correct the errors and re-upload the file.',
              status: 'error',
            });
            Promise.all([
              queryClient.invalidateQueries(['teamMembers']),
              queryClient.invalidateQueries(['teamList']),
            ]);
          } else {
            setErrorFileLink(false);
            newToast({
              message: 'File Uploaded successfully',
              status: 'success',
            });
            Promise.all([
              queryClient.invalidateQueries(['teamMembers']),
              queryClient.invalidateQueries(['teamList']),
            ]);
            setTimeout(() => {
              onClose();
            }, 500);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorFileLink(false);
          newToast({
            message: err.message ? `${err.message}` : 'Upload Error',
            status: 'error',
          });
        });
    } catch (err) {
      setLoading(false);
      newToast({
        message: `${err}`,
        status: 'error',
      });
    }
  };

  return (
    <Modal
      size="2xl"
      scrollBehavior={'inside'}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} minH={'700px'}>
        <ModalHeader ml={2} mt={6} fontFamily={'heading'}>
          Add Members in bulk
        </ModalHeader>
        <ModalCloseButton mr={5} mt={6} />
        <ModalBody ml={2}>
          <OrderedList fontWeight={'medium'} fontFamily={'heading'}>
            <ListItem fontSize={16} color={'text.secondary'}>
              <Box>
                <Text>Download CSV template</Text>
                <Button
                  borderRadius={4}
                  mt={4}
                  variant={'filled'}
                  colorScheme="twitter"
                  fontSize={16}
                  onClick={handleDownload}
                >
                  Download
                </Button>
              </Box>
            </ListItem>
            <ListItem fontSize={16} mt={8} color={'text.secondary'}>
              Edit your CSV file
            </ListItem>
            <ListItem fontSize={16} mt={8} mb={10} color={'text.secondary'}>
              <Text>Upload your CSV file</Text>
              <Box mt={4} width="560px" ml={'auto'} mr={9}>
                <DragDropFile file={file} setFile={setFile} width={'100%'} />
              </Box>
              <Flex>
                <Button
                  isDisabled={file ? false : true}
                  borderRadius={4}
                  mt={4}
                  variant={'filled'}
                  colorScheme="twitter"
                  fontSize={16}
                  ml={'auto'}
                  mr={9}
                  onClick={handleSubmitUpload}
                >
                  Upload
                </Button>
              </Flex>
            </ListItem>
          </OrderedList>
          {errorFileLink && (
            <Box>
              <Text
                fontFamily="heading"
                fontWeight={'semibold'}
                color={'red'}
                maxW={'500'}
                lineHeight={'7'}
                textAlign={'center'}
                fontSize={'2xl'}
                mx={'auto'}
                mb={10}
              >
                Looks like the file you uploaded has some missing data/mismatch.
                Let's correct it by following the below steps:
              </Text>
              <OrderedList fontWeight={'medium'} fontFamily="heading">
                <ListItem fontSize={16} color={'text.secondary'}>
                  <Text>
                    Find the error file downloaded just now. This file has the
                    same format as the uploaded file.
                  </Text>
                </ListItem>
                <ListItem mt={3} fontSize={16} color={'text.secondary'}>
                  Open it in excel and identify the entries as shown in the
                  error column in the file. These highlighted entries seem to
                  have some errors. You are only expected to correct these
                  entries. Please do not make any changes to other entries.
                </ListItem>
                <ListItem mt={3} fontSize={16} color={'text.secondary'}>
                  <Text>
                    Correct the entries according to the Instructions sheet in
                    the Preview example. Re-upload the corrected file again in
                    the above upload section.
                  </Text>
                </ListItem>
                <ListItem
                  mt={3}
                  mb={10}
                  fontSize={16}
                  textAlign={'left'}
                  color={'text.secondary'}
                >
                  <Text>
                    Hit the Upload button again to upload the corrected file.
                  </Text>
                </ListItem>
              </OrderedList>
            </Box>
          )}
          {loading && <Loader />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BulkUpload;
