import { FC, useContext, useEffect, useState } from 'react';
import { Tag } from '@chakra-ui/react';
import {
  eliminateHtmlTags,
  LeftElements,
  RightElements,
  SearchBox,
  SearchIconContainer,
  SearchInput,
  StyledSearchIcon,
  ToolbarContainer,
} from '@devd-client/devd/components';

import { InitiativeContext } from '../../../contexts/initiativesContext';

import { useSearchParams } from 'react-router-dom';
import { statusOptions } from '../../../helpers/Initiatives.utils';

interface ToolbarProps {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  handleRealStatus: (status: { value: string; label: string }) => void;
  realStatus: { value: string; label: string };
}

const Toolbar: FC<ToolbarProps> = ({
  setSearchInput,
  handleRealStatus,
  realStatus,
}) => {
  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState('');
  const searchParam = searchParams.get('search');

  const {
    state: { initiativeData },
  } = useContext(InitiativeContext);

  useEffect(() => {
    searchParam && setSearch(searchParam);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSearchInput(search);
  };

  return (
    <ToolbarContainer>
      <RightElements>
        {statusOptions?.map((item: any, idx: number) => (
          <Tag
            key={idx}
            mx={1}
            variant={realStatus.value === item.value ? 'solid' : 'outline'}
            colorScheme={realStatus.value === item.value ? 'blue' : 'gray'}
            userSelect="none"
            cursor="pointer"
            onClick={() =>
              handleRealStatus({
                value: eliminateHtmlTags(item.value),
                label:
                  item.value === '' ? 'All' : eliminateHtmlTags(item.value),
              })
            }
          >
            {item.label}
          </Tag>
        ))}
      </RightElements>

      <LeftElements>
        <SearchBox onSubmit={handleSubmit}>
          <SearchInput
            placeholder="Search"
            value={search}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              if (e.currentTarget.value === '')
                setSearchInput(eliminateHtmlTags(e.currentTarget.value));
              setSearch(eliminateHtmlTags(e.currentTarget.value));
            }}
          />
          <SearchIconContainer type="submit">
            <StyledSearchIcon />
          </SearchIconContainer>
        </SearchBox>
      </LeftElements>
    </ToolbarContainer>
  );
};

export default Toolbar;
