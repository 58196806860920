import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  TeamFilter,
  AuthorIdFilter,
  PR_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_HEALTH_INDEX_CHARTS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  ISSUE_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  REQUIREMENT_STABILITY_CHARTS,
} from '../shared/utils';

// This zustand store consists and persists all data related to PR table

interface FilterByTypes extends TeamFilter {
  prIds?: null | string[];
  showFilters?: boolean;
}

interface MetricTableTypes extends TeamFilter {
  tableType: string;
  metricKey: string;
  chartKey: string;
  metric: string;
  componentOrigin: string;
  showFilters: boolean;
  searchQuery: null | string;

  // PR RELATED
  status: string;
  prSize: string;
  reviewer: string;
  filterAge: string;
  age: string;
  feedbackResponse: string;
  reviewResponse: string;
  orderBy?: string;
  orderColumn?: string;
  previousFilter: string;
  prIds?: null | string[];

  // ISSUE RELATED
  issueType: string | null;
  issueStatusType: string | null;
  issueStatus: string | null;
  filterStatus: string | null;
  priority: string | null;
  dueDate: string | null;
  isDueDatePresent: null | boolean;
  isPriority: null | boolean;
  isLongCycleTime: null | boolean;
  inactiveIssues: string | null;
  backtrackIssues: null | boolean;
  reopenedIssues: null | boolean;
  requirements: null | boolean;
  cycleTimeSpread: string | null;
  issueAge: string | null;
  stage: string | null;
  currentAssignee: null | string;
  assignees: null | string[];
  issueIds: null | string[];
}

interface Actions {
  reset: () => void;
  resetFilters: () => void;
  setMetricFilter: (
    filterBy: FilterByTypes,
    chartKey: string,
    metricKey: string,
    componentOrigin: string
  ) => void;
  setSorting: (orderBy: string, orderColumn: string) => void;
  setStatus: (value: string) => void;
  setSearchQuery: (value: string) => void;

  // PR RELATED
  setDefault: (value: string) => void;
  setTeam: (value: string) => void;
  setPreviousFilter: (value: string) => void;
  setRepos: (value: string) => void;
  setAuthors: (value: string) => void;
  setReviewers: (value: string) => void;
  setPrSize: (value: string) => void;
  setPrIds: (value: string) => void;
  setPrAge: (value: string) => void;
  setReviewResponse: (value: string) => void;
  setReviewFeedback: (value: string) => void;
  setFeedbackResponse: (value: string) => void;

  // ISSUE RELATED
  setIssueDefault: (value: string) => void;
  setIssueType: (value: string) => void;
  setIssueIds: (value: string) => void;
  setIssueStatusType: (value: string) => void;
  setIssueStatus: (value: string) => void;
  setFilterStatus: (value: string) => void;
  setPriority: (value: string) => void;
  setDueDate: (value: string) => void;
  setNoDueDate: (value: boolean) => void;
  setNoPriority: (value: boolean) => void;
  setLongCycleTime: (value: boolean) => void;
  setInactiveIssue: (value: string) => void;
  setReOpenIssues: (value: boolean, type: string) => void;
  setCycleTimeSpread: (value: string) => void;
  setOpenIssueAge: (value: string) => void;
  setIssueStage: (value: string) => void;
  setAssignees: (value: string) => void;
  setCurrentAssignee: (value: string) => void;
  setbacktrackIssues: (value: boolean) => void;
  setRequirements: (value: boolean) => void;
}

const initialState: MetricTableTypes = {
  tableType: '',
  metricKey: '',
  chartKey: '',
  metric: '',
  componentOrigin: '',
  showFilters: true,
  searchQuery: null,

  // PR RELATED
  age: '',
  filterAge: '',
  prSize: '',
  reviewer: '',
  status: '',
  teamId: '',
  author: '',
  name: '',
  projectsFilter: [],
  type: '',
  sprintId: '',
  orderBy: '',
  orderColumn: '',
  lowerBoundIncl: '',
  upperBound: null,
  reviewResponse: 'LESS_THAN_24HRS',
  feedbackResponse: 'LESS_THAN_24HRS',
  previousFilter: '',
  prIds: null,

  // ISSUE RELATED
  issueType: null,
  issueStatusType: null,
  issueStatus: null,
  filterStatus: null,
  priority: null,
  isLongCycleTime: null,
  isPriority: null,
  dueDate: null,
  isDueDatePresent: null,
  inactiveIssues: null,
  backtrackIssues: null,
  reopenedIssues: null,
  requirements: null,
  cycleTimeSpread: null,
  issueAge: null,
  stage: null,
  currentAssignee: null,
  assignees: null,
  issueIds: null,
};

export const useMetricsTableStore = create<MetricTableTypes & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,

      // this runs only the first time to component is mounted to set the initial values.
      setMetricFilter: (
        filterBy: FilterByTypes,
        //    metricHeading: string,
        chartKey: string,
        metricKey: string,
        componentOrigin: string
      ) =>
        set(() => {
          return {
            ...filterBy,
            prIds: filterBy?.prIds ?? null,
            age: filterBy?.age ?? '',
            filterAge: filterBy?.age
              ? filterBy?.age === 'More than 1 Day'
                ? 'MORE_THAN_1D'
                : filterBy?.age === 'More than 3 Days'
                ? 'MORE_THAN_3D'
                : filterBy?.age === 'More than 7 Days'
                ? 'MORE_THAN_7D'
                : filterBy?.age === 'More than 14 Days'
                ? 'MORE_THAN_14D'
                : filterBy?.age === 'More than 1 Month'
                ? 'MORE_THAN_1M'
                : ''
              : '',
            prSize:
              filterBy?.lowerBoundIncl === 0
                ? '0-200'
                : filterBy?.lowerBoundIncl === 200
                ? '200-400'
                : filterBy?.lowerBoundIncl === 400
                ? '400-600'
                : filterBy?.lowerBoundIncl === 600
                ? '600-800'
                : filterBy?.lowerBoundIncl === 800
                ? '800+'
                : '',
            reviewer: [
              'TOP_REVIEWER_DISTRIBUTION',
              'PR_REVIEW',
              'PR_REVIEWED',
            ].includes(chartKey)
              ? filterBy?.author ?? ''
              : '',
            status: chartKey === 'PR_WITHOUT_ISSUE' ? 'merged' : '',
            teamId: filterBy?.teamId ?? '',
            author: ![
              'TOP_REVIEWER_DISTRIBUTION',
              'PR_REVIEW',
              'PR_REVIEWED',
            ].includes(chartKey)
              ? filterBy?.author ?? ''
              : '',
            name: filterBy?.name
              ? ['Feedback Responsiveness', 'Review Responsiveness'].includes(
                  filterBy?.name
                )
                ? `${filterBy?.name} Index`
                : filterBy?.name ?? ''
              : '',
            projectsFilter: filterBy?.projectsFilter ?? [],
            type:
              metricKey === 'merged_without_review' ||
              chartKey === 'MERGE_WITHOUT_REVIEW'
                ? 'merged_wo_review'
                : metricKey === 'merged_with_review'
                ? 'merged_with_review'
                : chartKey === 'LOC_VS_PR_CYCLE_TIME'
                ? 'pr_size'
                : filterBy?.name === 'Review Completion'
                ? 'review_with_comment'
                : chartKey === 'PR_WITHOUT_ISSUE'
                ? 'prs_unlinked'
                : ['bug', 'task', 'all'].includes(filterBy?.type ?? '') &&
                  [
                    ...ISSUE_CHART_KEYS,
                    ...ISSUE_AGE_CHART_KEYS,
                    ...ISSUE_LIFECYCLE_DIST_CHARTS,
                    ...REQUIREMENT_STABILITY_CHARTS,
                  ].includes(chartKey)
                ? filterBy?.type ?? ''
                : '',
            sprintId: filterBy?.sprintId ?? '',
            lowerBoundIncl: filterBy?.lowerBoundIncl ?? '',
            upperBound: filterBy?.upperBound ? filterBy?.upperBound : null,
            reviewResponse: 'LESS_THAN_24HRS',
            feedbackResponse: 'LESS_THAN_24HRS',

            // ISSUE RELALTED
            issueType: null,
            issueStatusType: null,
            issueStatus: null,
            filterStatus: [
              'ISSUE_THROUGHPUT',
              'ISSUE_PROGRESSION_RATE',
            ].includes(chartKey)
              ? filterBy?.status ?? null
              : null,
            priority: null,
            dueDate: null,
            isDueDatePresent: null,
            isLongCycleTime: null,
            isPriority: null,
            inactiveIssues: null,
            backtrackIssues: null,
            reopenedIssues: null,
            requirements: null,
            cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(chartKey)
              ? filterBy?.age ?? null
              : null,
            issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(chartKey)
              ? filterBy?.age ?? null
              : null,
            stage: ['ISSUE_LIFECYCLE_DIST'].includes(chartKey)
              ? filterBy?.status ?? null
              : null,
            currentAssignee:
              componentOrigin === 'contributor-profile-issue'
                ? filterBy?.author
                : null,
            assignees: null,
            issueIds: null,

            metricKey,
            chartKey,
            componentOrigin,
            searchQuery: null,
            showFilters: filterBy?.showFilters ?? true,
            tableType: [
              ...PR_CHART_KEYS,
              ...PR_AGEING_CHARTS,
              ...PR_HEALTH_INDEX_CHARTS,
              ...LOC_VS_PR_CYCLE_TIME_CHARTS,
              ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
            ].includes(chartKey)
              ? 'PR'
              : [
                  ...ISSUE_CHART_KEYS,
                  ...ISSUE_AGE_CHART_KEYS,
                  ...ISSUE_LIFECYCLE_DIST_CHARTS,
                  ...REQUIREMENT_STABILITY_CHARTS,
                ].includes(chartKey)
              ? 'ISSUE'
              : 'PR',
          };
        }),
      setDefault: (value: string) =>
        set((state) => ({
          type: value,
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          projectsFilter: [],
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),

      // PR RELATED
      setPrIds: (value: string) =>
        set((state) => ({
          prIds: value === '' ? null : [value],
        })),

      setRepos: (value: string) =>
        set((state) => ({
          projectsFilter: [value],
          type: '',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setAuthors: (value: string) =>
        set((state) => ({
          author: value,
          reviewer: [
            'TOP_REVIEWER_DISTRIBUTION',
            'PR_REVIEW',
            'PR_REVIEWED',
          ].includes(state.chartKey)
            ? state.reviewer
            : '',
          type: '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          projectsFilter: [],
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setReviewers: (value: string) =>
        set((state) => ({
          reviewer: value,
          author: '',
          type: '',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          projectsFilter: [],
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setPrSize: (value: string) =>
        set((state) => ({
          prSize: value,
          type: 'pr_size',
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          projectsFilter: [],
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          lowerBoundIncl:
            value === '0-200'
              ? 0
              : value === '200-400'
              ? 200
              : value === '400-600'
              ? 400
              : value === '600-800'
              ? 600
              : value === '800+'
              ? 800
              : '',
          upperBound:
            value === '0-200'
              ? 200
              : value === '200-400'
              ? 400
              : value === '400-600'
              ? 600
              : value === '600-800'
              ? 800
              : value === '800+'
              ? null
              : null,
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setPrAge: (value: string) =>
        set((state) => ({
          filterAge: value,

          age:
            value === 'MORE_THAN_1D'
              ? 'More than 1 Day'
              : value === 'MORE_THAN_3D'
              ? 'More than 3 Days'
              : value === 'MORE_THAN_7D'
              ? 'More than 7 Days'
              : value === 'MORE_THAN_14D'
              ? 'More than 14 Days'
              : 'More than 1 Month',
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          type:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          name:
            state.chartKey === 'REVIEW_RESPONSIVENESS_INDEX' ? state.name : '',
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',

          projectsFilter: [],
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setReviewResponse: (value: string) =>
        set((state) => ({
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          type: '',
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          projectsFilter: [],
          reviewResponse: value,
          name: 'Review Responsiveness Index',
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setReviewFeedback: (value: string) =>
        set((state) => ({
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          type: value,
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          projectsFilter: [],
          name: 'Review Completion',
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),
      setFeedbackResponse: (value: string) =>
        set((state) => ({
          prSize: state.chartKey === 'LOC_VS_PR_CYCLE_TIME' ? state.prSize : '',
          filterAge:
            state.chartKey === 'PR_AGEING_DISTRIBUTION' ? state.filterAge : '',
          type: '',
          author:
            state.componentOrigin === 'contributor-profile-pr'
              ? state.author
              : '',
          projectsFilter: [],
          feedbackResponse: value,
          name: 'Feedback Responsiveness Index',
          reviewer:
            ['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
              state.chartKey
            ) || state.componentOrigin === 'contributor-profile-pr'
              ? state.reviewer
              : '',
          tableType: state.tableType,
          prIds: null,
          searchQuery: null,
        })),

      // ISSUE RELATED
      setIssueIds: (value: string) =>
        set((state) => ({
          issueIds: value === '' ? null : [value],
        })),
      setIssueDefault: (value: string) =>
        set((state) => ({
          ...initialState,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),

      setIssueType: (value: string) =>
        set((state) => ({
          ...initialState,
          issueType: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setIssueStatusType: (value: string) =>
        set((state) => ({
          issueStatusType: value,
          // issueIds: null,
        })),
      setFilterStatus: (value: string) =>
        set((state) => ({
          ...initialState,
          filterStatus: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setIssueStatus: (value: string) =>
        set((state) => ({
          ...initialState,
          issueStatus: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setPriority: (value: string) =>
        set((state) => ({
          ...initialState,
          priority: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setDueDate: (value: string) =>
        set((state) => ({
          ...initialState,
          dueDate: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setNoDueDate: (value: boolean) =>
        set((state) => ({
          ...initialState,
          isDueDatePresent: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setNoPriority: (value: boolean) =>
        set((state) => ({
          ...initialState,
          isPriority: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setLongCycleTime: (value: boolean) =>
        set((state) => ({
          ...initialState,
          isLongCycleTime: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setInactiveIssue: (value: string) =>
        set((state) => ({
          ...initialState,
          inactiveIssues: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setReOpenIssues: (value: boolean, type: string) =>
        set((state) => ({
          ...initialState,
          type,
          reopenedIssues: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setCycleTimeSpread: (value: string) =>
        set((state) => ({
          ...initialState,
          cycleTimeSpread: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,

          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setOpenIssueAge: (value: string) =>
        set((state) => ({
          ...initialState,
          issueAge: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setRequirements: (value: boolean) =>
        set((state) => ({
          ...initialState,
          requirements: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setbacktrackIssues: (value: boolean) =>
        set((state) => ({
          ...initialState,
          backtrackIssues: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setIssueStage: (value: string) =>
        set((state) => ({
          ...initialState,
          stage: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          currentAssignee:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.currentAssignee
              : null,
          assignees:
            state.componentOrigin === 'contributor-profile-issue'
              ? state.assignees
              : null,
          issueIds: null,
        })),
      setAssignees: (value: string) =>
        set((state) => ({
          ...initialState,
          assignees: [value],
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),
      setCurrentAssignee: (value: string) =>
        set((state) => ({
          ...initialState,
          currentAssignee: value,
          metricKey: state.metricKey,
          chartKey: state.chartKey,
          componentOrigin: state.componentOrigin,
          tableType: state.tableType,
          type: state.type,
          filterStatus: ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
            state.chartKey
          )
            ? state.filterStatus
            : null,
          cycleTimeSpread: ['ISSUE_CYCLE_TIME_DIST'].includes(state.chartKey)
            ? state.cycleTimeSpread
            : null,
          issueAge: ['CHANGE_AGING_DISTRIBUTION'].includes(state.chartKey)
            ? state.issueAge
            : null,
          stage: ['ISSUE_LIFECYCLE_DIST'].includes(state.chartKey)
            ? state.stage
            : null,
          teamId: state.teamId,
          previousFilter: state.previousFilter,
          issueStatusType: state.issueStatusType,
          issueIds: null,
        })),

      setStatus: (value: string) => {
        return set(() => ({
          status: value,
          // issueIds: null,
          // prIds: null,
          // searchQuery: null,
        }));
      },
      setTeam: (value: string) =>
        set(() => ({
          teamId: value,
          issueIds: null,
          prIds: null,
          searchQuery: null,
        })),
      setPreviousFilter: (value: string) =>
        set(() => ({
          previousFilter: value,
        })),
      setSorting: (orderBy: string, orderColumn: string) =>
        set(() => ({
          orderBy,
          orderColumn,
        })),
      setSearchQuery: (value: string) =>
        set((state) => ({ searchQuery: value === '' ? null : value })),
      reset: () => set(() => initialState),
      resetFilters: () =>
        set((state) => ({
          ...initialState,
          tableType: state.tableType,
          type: state.tableType === 'ISSUE' ? 'all' : '',
          componentOrigin: state.componentOrigin,
          metricKey: ['contributor-profile-pr'].includes(state.componentOrigin)
            ? 'PR_UPDATED'
            : 'ISSUE_UPDATED',
          chartKey: ['contributor-profile-pr'].includes(state.componentOrigin)
            ? 'PR_UPDATED'
            : 'ISSUE_UPDATED',
        })),
    }),
    {
      name: 'MetricFilters', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useMetricsTableStore;
