import { Box, Flex } from '@chakra-ui/react';
import {
  DataGrid,
  Loader,
  Paginate,
  PeriodType,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchInProgressGql } from '../../../graphql';
import { useCallback, useMemo, useState } from 'react';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { BiLinkExternal } from 'react-icons/bi';

type AnchorLinkType = {
  mr?: string;
  ml?: string;
};

const AnchorLink = styled.a<AnchorLinkType>`
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: #0095e6;
  margin-right: ${({ mr }) => mr && mr};
  margin-left: ${({ ml }) => ml && ml};
`;

interface InProgressProps {
  selected: PeriodType;
}

const InProgress = ({ selected }: InProgressProps) => {
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    data: inProgressData,
    isLoading,
    isFetching,
  } = useFetchInProgressGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname.split('/')[2]),
    '',
    '',
    '',
    '',
    '',
    '',
    currentPage,
    30
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const rows: any[] = useMemo(
    () => inProgressData?.data || [],
    [inProgressData?.data]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <Flex align="center">
            <AnchorLink
              href={info?.row?.original?.link}
              target="_blank"
              rel="noopener"
            >
              {info.getValue()}
              <BiLinkExternal style={{ marginLeft: '5px' }} />
            </AnchorLink>
          </Flex>
        ),
        header: 'ID',
      }),
      columnHelper.accessor('assignee', {
        cell: (info) => info.getValue(),
        header: 'Assignee',
        size: 80,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('title', {
        cell: (info) => info.getValue(),
        header: 'Title',
        size: 80,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('type', {
        cell: (info) => info.getValue(),
        header: 'Type',
        size: 100,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('openDays', {
        cell: (info) => info.getValue(),
        header: 'Open Days',
        size: 100,
        meta: { isNumeric: true },
      }),
    ],
    [inProgressData]
  );

  return (
    <Box>
      <DataGrid
        showLoader={isLoading}
        columns={columns}
        data={rows}
        maxH="24rem"
        sticky="firstCol"
      />
      {/* <Box marginLeft="-20px">
        <Paginate
          currentPage={inProgressData?.currentPage + 1}
          totalPageCount={inProgressData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box> */}
      {isFetching && <Loader />}
    </Box>
  );
};

export default InProgress;
