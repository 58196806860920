import { useQuery } from '@tanstack/react-query';
import {
  getReportQuestionDetailsGraphql,
  getReportQuestionsGraphql,
  issueTimelineDistributionGraphql,
  scopeChangeMetricGraphql,
  sprintGraphql,
} from './query';

const QUERY_KEYS = {
  reportQuestions: 'reportQuestions',
  reportDetails: 'reportDetails',
  sprintMetricByType: 'sprintMetricByType',
  scopeChange: 'scopeChange',
  issueBugDist: 'issueBugDist',
};

export const useReportQuestionsGql = (pageNumber: number, pageSize: number) => {
  return useQuery(
    [QUERY_KEYS.reportQuestions, pageNumber, pageSize],
    () => getReportQuestionsGraphql(pageNumber, pageNumber),
    {
      select: (data) => data.ReportQuestionDetails.data,
    }
  );
};

export const useReportQuestionDetailsGql = (
  reportId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.reportQuestions, reportId, startDate, endDate, sprintId],
    () =>
      getReportQuestionDetailsGraphql(reportId, startDate, endDate, sprintId),
    {
      select: (data) => data.ReportDetails,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useSprintMetricByTypeGql = (
  metric: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [
      QUERY_KEYS.sprintMetricByType,
      metric,
      teamId,
      startDate,
      endDate,
      sprintId,
    ],
    () => sprintGraphql(metric, teamId, startDate, endDate, sprintId),
    {
      select: (data) => {
        return {
          ...data.SprintMetricsByType,
          data: JSON.parse(data.SprintMetricsByType.data),
        };
      },
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useScopeChangeMetricGql = (
  metric: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.scopeChange, metric, teamId, startDate, endDate, sprintId],
    () =>
      scopeChangeMetricGraphql(metric, teamId, startDate, endDate, sprintId),
    {
      select: (data) => {
        return {
          ...data?.SprintMetricsByType,
          data: JSON.parse(data?.SprintMetricsByType?.data),
        };
      },
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useIssueBugDistGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
) => {
  return useQuery(
    [QUERY_KEYS.issueBugDist, startDate, endDate, teamId, sprintId],
    () =>
      issueTimelineDistributionGraphql(
        startDate,
        endDate,
        teamId,
        sprintId,
        'all'
      ),
    {
      select: (data) => {
        return {
          ...data.CustomReportMetricByType,
          data: JSON.parse(data.CustomReportMetricByType.data),
        };
      },
      enabled: !!startDate && !!endDate,
    }
  );
};
