import { Flex, VStack, Box, Text, IconButton } from '@chakra-ui/react';
import { FC, useState } from 'react';
import AppBody from './AppBody';
import AppHeader from './AppHeader';
import { RxCross1 } from 'react-icons/rx';

export type SplitSidebarType = 'settings' | 'metrics' | 'none';

interface AppLayoutProps {
  children: React.ReactNode;
  splitSidebar: SplitSidebarType;
  appState: any;
  handleLogout: () => void;
}

export const AppLayout: FC<AppLayoutProps> = ({
  children,
  splitSidebar,
  appState,
  handleLogout,
}) => {
  const [hideCross, setHideCross] = useState<boolean>(true);

  return (
    <VStack w="100%" spacing={0} minW="1280px" overflow="hidden">
      {!hideCross && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          w="full"
          bg="primary"
          height="40px"
        >
          <Text
            textAlign="center"
            fontSize="md"
            fontWeight="medium"
            color="white"
          >
            Scheduled maintenance downtime is planned from 10:30 AM GMT to 12:30
            PM GMT on 14th May 2023. For any queries, please email us at
            support@devdynamics.ai.
          </Text>
          <Box position="absolute" right={5} translateX={'-50%'}>
            <IconButton
              size="sm"
              isRound
              bg="transperant"
              onClick={() => setHideCross(true)}
              _hover={{ bg: 'gray.400' }}
              aria-label="cross"
              icon={<RxCross1 color="#fff" />}
            />
          </Box>
        </Box>
      )}

      <AppHeader appState={appState} handleLogout={handleLogout} />

      <Flex w="100%">
        <AppBody
          appState={appState}
          children={children}
          splitSidebar={splitSidebar}
        />
      </Flex>
    </VStack>
  );
};

export default AppLayout;
