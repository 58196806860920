import { Box, Tooltip, useDisclosure } from '@chakra-ui/react';
import { DataGrid, PeriodType } from '@devd-client/devd/components';
import { Paginate } from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchSprintVelocityGql } from '../../../graphql';
import { useCallback, useMemo, useState } from 'react';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import ShowSprintVelocitySideDrawer from './ShowSprintVelocitySideDrawer';
import moment from 'moment';

const StyledLink = styled.div`
  text-decoration: underline;
  color: #0095e6;
  cursor: pointer;
`;

interface SprintVelocityProps {
  selected: PeriodType;
}

const SprintVelocity = ({ selected }: SprintVelocityProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const team = decodeURIComponent(pathname.split('/')[2]);
  const [heading, setHeading] = useState<string>('');
  const [subHeading, setSubHeading] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [sprintId, setSprintId] = useState<string>('');
  const [type, setType] = useState<string>('');
  const sprintVelocitySideDrawer = useDisclosure();

  const { data: sprintVelocityData, isLoading } = useFetchSprintVelocityGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname.split('/')[2]),
    currentPage,
    20
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const rows: any[] = useMemo(
    () => sprintVelocityData?.data || [],
    [sprintVelocityData?.data]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('sprintName', {
        cell: (info) => (
          <StyledLink
            onClick={() =>
              navigate(
                `/teams/sprint/${info?.row?.original?.sprintId}${
                  team && `?team=${team}`
                }`
              )
            }
          >
            {info?.getValue()}
          </StyledLink>
        ),
        header: 'Sprint Name',
      }),
      columnHelper.accessor('sprintId', {
        cell: (info) => info.getValue(),
        header: 'Sprint Id',
        size: 80,
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => info.getValue(),
        header: 'Start Date',
        size: 100,
      }),
      columnHelper.accessor('endDate', {
        cell: (info) => info.getValue(),
        header: 'End Date',
        size: 100,
      }),
      columnHelper.accessor('commitedTasks', {
        cell: (info) => (
          <Box
            onClick={() => {
              setHeading(info?.row?.original?.sprintName);
              setSubHeading(`Planned Issues (${info?.getValue()})`);
              setStartDate(info?.row?.original?.startDate);
              setEndDate(info?.row?.original?.endDate);
              setSprintId(info?.row?.original?.sprintId);
              setType('committed');
              sprintVelocitySideDrawer.onOpen();
            }}
          >
            <Tooltip label="View details">
              <Box
                textDecoration="underline"
                cursor="pointer"
                width={'fit-content'}
                ml={'auto'}
              >
                {info.getValue()}
              </Box>
            </Tooltip>
          </Box>
        ),
        header: 'Planned Issues',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('completedTasks', {
        cell: (info) => (
          <Box
            onClick={() => {
              setHeading(info?.row?.original?.sprintName);
              setSubHeading(`Completed Issues (${info?.getValue()})`);
              setStartDate(info?.row?.original?.startDate);
              setEndDate(info?.row?.original?.endDate);
              setSprintId(info?.row?.original?.sprintId);
              setType('completed');
              sprintVelocitySideDrawer.onOpen();
            }}
          >
            <Tooltip label="View details">
              <Box
                textDecoration="underline"
                cursor="pointer"
                width={'fit-content'}
                ml={'auto'}
              >
                {info.getValue()}
              </Box>
            </Tooltip>
          </Box>
        ),
        header: 'Completed Issues',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('unplannedTasks', {
        cell: (info) => (
          <Box
            onClick={() => {
              setHeading(info?.row?.original?.sprintName);
              setSubHeading(`Unplanned Issues (${info?.getValue()})`);
              setStartDate(info?.row?.original?.startDate);
              setEndDate(info?.row?.original?.endDate);
              setSprintId(info?.row?.original?.sprintId);
              setType('uncommitted');
              sprintVelocitySideDrawer.onOpen();
            }}
          >
            <Tooltip label="View details">
              <Box
                textDecoration="underline"
                cursor="pointer"
                width={'fit-content'}
                ml={'auto'}
              >
                {info.getValue()}
              </Box>
            </Tooltip>
          </Box>
        ),
        header: 'Unplanned Issues',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('commitedSP', {
        cell: (info) => info.getValue(),
        header: 'Planned SP',
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('completedSP', {
        cell: (info) => info.getValue(),
        header: 'Completed SP',
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
    ],
    [sprintVelocityData]
  );

  return (
    <Box>
      <DataGrid
        showLoader={isLoading}
        columns={columns}
        data={rows}
        maxH="24rem"
        sticky="firstCol"
      />
      <Box marginLeft="-20px">
        <Paginate
          currentPage={sprintVelocityData?.currentPage + 1}
          totalPageCount={sprintVelocityData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
      {sprintVelocitySideDrawer.isOpen && (
        <ShowSprintVelocitySideDrawer
          openDrawer={sprintVelocitySideDrawer.isOpen}
          handleDrawerClose={sprintVelocitySideDrawer.onClose}
          heading={heading}
          subHeading={`${moment(startDate).format('DD MMM')} - ${moment(
            endDate
          ).format('DD MMM')}, ${subHeading}`}
          startDate={startDate}
          endDate={endDate}
          sprintId={sprintId}
          type={type}
          teamId={team}
        />
      )}
    </Box>
  );
};

export default SprintVelocity;
