import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import ShowInvCharts from './ShowInvCharts/index';
import ShowMetrics from './ShowMetrics';
import { ReportMetrics } from '../../../../helpers/report.types';
import { ISelect, PeriodType } from '@devd-client/devd/components';
import SprintMetric from './SprintMetric';
import IssueDistTable from './IssueDistTable';

interface ChartContainerProps {
  showMetric: ReportMetrics[];
  selected: PeriodType;
  selectedTeam: ISelect;
}

const ChartContainer: FC<ChartContainerProps> = ({
  showMetric,
  selected,
  selectedTeam,
}) => {
  const sprintMetrics: any = [
    'COMMITTED_VS_COMPLETED_SP',
    'COMMITTED_VS_COMPLETED_TASKS',
    'SPRINT_COMPLETION_PERCENT',
  ];

  return (
    <Flex w="full">
      {showMetric.some(
        (item: ReportMetrics) => item.metric === 'INVESTMENT_DIST'
      ) ? (
        <ShowInvCharts
          selected={selected}
          selectedTeam={selectedTeam.value}
          showMetric={showMetric.find(
            (item: any) => item.metric === 'INVESTMENT_DIST'
          )}
        />
      ) : showMetric.some(
          (item: ReportMetrics) => item.metric === 'ISSUE_BUG_DISTRIBUTION'
        ) ? (
        <IssueDistTable
          selected={selected}
          selectedTeam={selectedTeam.value}
          showMetric={showMetric.find(
            (item: any) => item.metric === 'ISSUE_BUG_DISTRIBUTION'
          )}
        />
      ) : showMetric.some((item: ReportMetrics) =>
          sprintMetrics.includes(item.metric)
        ) ? (
        <Flex direction="column" w="100%">
          {showMetric.map((item: any, idx: number) => (
            <SprintMetric
              item={item}
              key={idx}
              selected={selected}
              team={selectedTeam.value}
            />
          ))}
        </Flex>
      ) : (
        <ShowMetrics
          showMetric={showMetric}
          selected={selected}
          selectedTeam={selectedTeam.value}
        />
      )}
    </Flex>
  );
};

export default ChartContainer;
