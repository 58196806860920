import { Box, Button, Icon, Stack, Tooltip } from '@chakra-ui/react';
import {
  MetaTags,
  PageHeader,
  TeamMenu,
  useQueryState,
  useToastHook,
  FileUpload,
} from '@devd-client/devd/components';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import CardTable from '../components/CardTable';
import { useTeamHomeDetails, QUERY_KEYS } from '../apis';
import { useEffect, useState } from 'react';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import { BiUpload } from 'react-icons/bi';
import { TEAM_DETAILS_QUERY_KEYS } from '@devd-client/devd/team-details';
import { useDashboardStore } from '@devd-client/devd/components';

export function DevdTeams() {
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const [AppState, dispatch] = useAuth();
  const plan = AppState?.user?.dto?.plan;
  const [team, setTeam] = useQueryState('team');
  const [uploadTeamsFile, setUploadTeamsFile] = useState<any>(false);

  const [teamLength, setTeamLength] = useState(0);

  const { data: teamHomedata } = useTeamHomeDetails();
  const helpDocLink = teamHomedata?.dto?.helpDoc;
  const { selectedTeam, setSelectedTeam } = useDashboardStore();

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );
  }, []);

  useEffect(() => {
    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }
  }, [selectedTeam, setTeam]);

  return (
    <>
      <MetaTags title="Teams | Settings | Devdynamics" />
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        minHeight={'90vh'}
        marginBottom={'50px'}
      >
        {uploadTeamsFile ? (
          <FileUpload
            setUploadFile={setUploadTeamsFile}
            uploadLink={`/v1/team/org/${localStorage.getItem('orgId')}/import`}
            queryKey={{
              teams: QUERY_KEYS.teams,
              teamList: TEAM_DETAILS_QUERY_KEYS.teamList,
            }}
          />
        ) : (
          <Stack spacing={6}>
            <PageHeader
              headingText="Teams"
              helpDocLink={helpDocLink}
              component={
                <Box>
                  <Button
                    variant="primary"
                    size={'sm'}
                    mr={'.5rem'}
                    onClick={() => {
                      setUploadTeamsFile(true);
                    }}
                  >
                    <Icon as={BiUpload} mr={2} />
                    Upload Teams
                  </Button>

                  <Button
                    variant="primary"
                    size={'sm'}
                    onClick={() => {
                      if (
                        AppState?.user?.dto?.teamAllowed &&
                        (plan === 'Free' || plan === 'Trial') &&
                        teamLength >= AppState?.user?.dto?.teamAllowed
                      ) {
                        newToast({
                          message:
                            'Upgrade your plan to add more teams, or contact Devdynamics support',
                          status: 'error',
                        });
                      } else {
                        window.analytics.track('team_created', {
                          status: 'init',
                        });
                        navigate('/settings/teams/add-team', {
                          state: { newTeam: true },
                        });
                      }
                    }}
                  >
                    <Icon as={FaPlus} mr={2} />
                    New Team
                  </Button>
                </Box>
              }
            />

            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              AuthActionTypes={AuthActionTypes}
              getUser={getUser}
              appState={AppState}
              dispatch={dispatch}
              hideShowDetails
              mb={2}
            />

            <CardTable
              team={selectedTeam.value}
              teamLength={teamLength}
              plan={plan}
              appState={AppState}
              setTeamLength={setTeamLength}
            />
          </Stack>
        )}
      </Box>
    </>
  );
}

export default DevdTeams;
