import {
  Box,
  Flex,
  Tag,
  Text,
  Tooltip,
  useTheme,
  TagLabel,
} from '@chakra-ui/react';
import {
  IconLabelContainer,
  Label,
  StyledLeftNav,
  StyledLeftNavCondensed,
} from './left-nav.styled';
import { useLocation, useNavigate } from 'react-router';
import { IconType } from 'react-icons/lib';
import { useEffect, useState } from 'react';
import { Home } from '../../../icons/home';
import {
  ActivityLog,
  Goals,
  Initiatives,
  Investment,
  Metrics,
  Setting,
} from '../../../icons';
import { MdFeedback, MdExplore } from 'react-icons/md';
import { RiSurveyLine } from 'react-icons/ri';
import { Team, cursorClick } from '../../../icons';
import { useSidebarStore } from '../store/sidebarStore';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface LeftNavProps {
  isCondensed?: boolean | false;
  appState: any;
}

interface LeftNavItemProps {
  id: string;
  isCondensed?: LeftNavProps['isCondensed'] | false;
  label: string;
  Icon?: IconType;
  isSelected?: boolean;
  bottom?: boolean;
  onClick: (id: string) => void;
  path?: string;
  subMenuItems?: any[];
  currentSubMenu?: any;
  appState?: any;
}

function LeftNavItem(props: LeftNavItemProps) {
  const {
    id,
    Icon,
    label,
    isSelected,
    onClick,
    bottom = false,
    isCondensed,
    path,
    subMenuItems,
    currentSubMenu,
    appState,
  } = props;

  const theme = useTheme();
  const { open } = useSidebarStore();

  return (
    <>
      <Link to={'/' + path} onClick={() => onClick(id)}>
        <Box
          bgColor={isSelected ? theme['colors'].primaryBg : '#FFFFFF'}
          cursor={'pointer'}
          userSelect={'none'}
          mb={
            subMenuItems && subMenuItems?.length > 0 ? 0 : isCondensed ? 4 : 2
          }
        >
          <Flex position={label === 'Automations' ? 'relative' : 'static'}>
            <Box
              width={1}
              height={'auto'}
              bgColor={isSelected ? 'primary' : '#FFFFFF'}
            />
            <IconLabelContainer>
              <Flex
                direction={isCondensed ? 'row' : 'column'}
                alignItems="center"
              >
                {Icon && (
                  <Box>
                    {!open ? (
                      <Tooltip
                        placement="right"
                        hasArrow
                        aria-label="icon-label"
                        label={label}
                      >
                        <Box>
                          <Icon
                            height="16px"
                            width="16px"
                            color={
                              isSelected
                                ? theme['colors'].primary
                                : theme['colors'].text.secondary
                            }
                          />
                        </Box>
                      </Tooltip>
                    ) : open && !isCondensed ? (
                      <Tooltip
                        placement="right"
                        hasArrow
                        aria-label="icon-label"
                        label={label}
                      >
                        <Box>
                          <Icon
                            height="16px"
                            width="16px"
                            color={
                              isSelected
                                ? theme['colors'].primary
                                : theme['colors'].text.secondary
                            }
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Icon
                        height="16px"
                        width="16px"
                        color={
                          isSelected
                            ? theme['colors'].primary
                            : theme['colors'].text.secondary
                        }
                      />
                    )}
                  </Box>
                )}

                {open && isCondensed && (
                  <Label
                    isCondensed={isCondensed}
                    color={
                      isSelected && currentSubMenu
                        ? theme['colors'].text.secondary
                        : isSelected
                        ? theme['colors'].primary
                        : theme['colors'].text.secondary
                    }
                    style={
                      isCondensed
                        ? {}
                        : {
                            textAlign: 'center',
                            fontWeight: 'initial',
                          }
                    }
                  >
                    {label}
                    {label === 'Automations' && (
                      <Tag
                        position={'absolute'}
                        left={115}
                        top={-1}
                        colorScheme="twitter"
                        size={'sm'}
                      >
                        <TagLabel>Beta</TagLabel>
                      </Tag>
                    )}
                  </Label>
                )}
              </Flex>
            </IconLabelContainer>
          </Flex>
        </Box>
      </Link>
      {open && isCondensed && (
        <>
          {subMenuItems?.map((item: any, index: number) => {
            if (
              item.id === 'Custom Dashboard' &&
              appState?.user?.dto?.modules?.customDashboard === false
            ) {
              return null;
            }

            if (
              item.id === 'Quality' &&
              appState?.user?.dto?.modules?.qualityDashboard === false
            ) {
              return null;
            }

            if (
              item.id === 'Sprint' &&
              appState?.user?.dto?.modules?.sprintDashboard === false
            ) {
              return null;
            }

            if (
              item.id === 'Dora' &&
              appState?.user?.dto?.modules?.doraDashboard === false
            ) {
              return null;
            }

            if (
              item.id === 'Ticket' &&
              appState?.user?.dto?.modules?.ticketDashboard === false
            ) {
              return null;
            }

            if (
              item.id === 'Git' &&
              appState?.user?.dto?.modules?.gitDashboard === false
            ) {
              return null;
            }

            return (
              <Link
                to={'/' + item.path}
                key={index}
                onClick={() => onClick(item.id)}
              >
                <Box key={index} pl={12}>
                  <Flex
                    height="28px"
                    align="center"
                    bg={currentSubMenu === item.id ? 'primaryBg' : 'white'}
                  >
                    <Box
                      height={'full'}
                      width="2px"
                      bg={currentSubMenu === item.id ? 'primary' : 'white'}
                      mr={2}
                    />
                    <Text
                      fontFamily="heading"
                      fontSize="xs"
                      fontWeight={'medium'}
                      color={
                        currentSubMenu === item.id
                          ? 'primary'
                          : 'text.secondary'
                      }
                    >
                      {item.label}
                    </Text>
                  </Flex>
                </Box>
              </Link>
            );
          })}
        </>
      )}
    </>
  );
}

export function LeftNav(props: LeftNavProps) {
  const location = useLocation();
  const { open, setOpen } = useSidebarStore();

  const email = props.appState?.user?.email;
  const path =
    localStorage.getItem('role') === 'EMPLOYEE'
      ? `contributors/${email}`
      : 'dashboard';

  const NavItems = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      path: path,
      Icon: Home,
      subMenuItems: [
        { id: 'Git', label: 'Git', path: 'git' },
        { id: 'Ticket', label: 'Ticket', path: 'ticket' },
        { id: 'Dora', label: 'DORA', path: 'dora' },
        { id: 'Sprint', label: 'Sprint', path: 'sprint' },
        { id: 'Quality', label: 'Code Quality', path: 'quality' },
        {
          id: 'Custom Dashboard',
          label: 'Custom Dashboard',
          path: 'custom-dashboard',
        },
      ],
    },
    {
      id: 'Teams',
      label: 'Teams',
      path: 'teams',
      Icon: Team,
    },
    {
      id: 'Reports',
      label: 'Reports',
      path: 'reports',
      Icon: MdExplore,
    },
    {
      id: 'Metrics',
      label: 'Metrics',
      path: 'metrics',
      Icon: Metrics,
    },
    {
      id: 'ActivityLog',
      label: 'Activity log',
      path: 'activity-log',
      Icon: ActivityLog,
    },
    {
      id: 'Investments',
      label: 'Investments',
      path: 'investments',
      Icon: Investment,
    },

    {
      id: 'Initiatives',
      label: 'Initiatives',
      path: 'initiatives',
      Icon: Initiatives,
    },
    {
      id: 'Feedback',
      label: 'Feedback',
      path: 'feedback',
      Icon: MdFeedback,
    },

    {
      id: 'Goals',
      label: 'Working Agreements',
      path: 'goals',
      Icon: Goals,
    },
    {
      id: 'WorkflowAutomation',
      label: 'Automations',
      path: 'workflow-automation',
      Icon: cursorClick,
    },
    {
      id: 'Survey',
      label: 'Survey',
      path: 'survey',
      Icon: RiSurveyLine,
    },
    {
      id: 'Settings',
      label: 'Settings',
      path: 'settings',
      Icon: Setting,
    },
  ];

  const showFeedback = props?.appState?.user?.dto?.modules?.cycleFeedback;

  const currentNav = location.pathname.split('/')[1];

  const currentNavId = currentNav
    ? NavItems.find((item) => item.path === currentNav)?.id
    : null;

  const [selectedNavItem, setSelectedNavItem] = useState(
    currentNavId ? currentNavId : NavItems[0].id
  );

  useEffect(() => {
    setSelectedNavItem(currentNavId ? currentNavId : NavItems[0].id);
  }, [currentNavId]);

  const onNavItemSelect = (id: string): void => {
    const item = NavItems.filter((item) => item.id === id)[0];
    setSelectedNavItem(id);
  };

  const renderNavItems = () => {
    return NavItems.slice(0, -1).map((item) => {
      if (item.id === 'Feedback' && !showFeedback) {
        return null;
      }

      if (
        item.id === 'Teams' &&
        props?.appState?.user?.dto?.modules?.teams === false
      ) {
        return null;
      }

      if (
        item.id === 'Goals' &&
        props?.appState?.user?.dto?.modules?.agreements === false
      ) {
        return null;
      }

      if (
        item.id === 'Investments' &&
        props?.appState?.user?.dto?.modules?.investment === false
      ) {
        return null;
      }

      if (
        item.id === 'ActivityLog' &&
        props?.appState?.user?.dto?.modules?.activityLog === false
      ) {
        return null;
      }

      if (
        localStorage.getItem('role') === 'EMPLOYEE' &&
        (item.id === 'Initiatives' ||
          item.id === 'Feedback' ||
          item.id === 'Reports' ||
          item.id === 'Survey' ||
          item.id === 'Investments' ||
          item.id === 'ActivityLog')
      ) {
        return null;
      }

      const currentNavSubMenu = item?.subMenuItems?.find(
        (subItem) => currentNav === subItem.path
      )?.id;

      return (
        <LeftNavItem
          isCondensed={props.isCondensed}
          key={item.id}
          isSelected={selectedNavItem === item.id || !!currentNavSubMenu}
          currentSubMenu={currentNavSubMenu}
          onClick={onNavItemSelect}
          appState={props.appState}
          {...item}
        />
      );
    });
  };

  const renderBottomItems = () => {
    return localStorage.getItem('role') !== 'EMPLOYEE' ? (
      NavItems.slice(-1).map((item) => (
        <LeftNavItem
          isCondensed={props.isCondensed}
          key={item.id}
          isSelected={selectedNavItem === item.id}
          onClick={onNavItemSelect}
          appState={props.appState}
          {...item}
        />
      ))
    ) : (
      <></>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.isCondensed ? (
        <StyledLeftNav width={open ? '200px' : '60px'}>
          {open ? (
            <Tooltip
              placement="right"
              hasArrow
              aria-label="collapse"
              label="Collapse"
            >
              <Box
                position="absolute"
                top={5}
                right={-3}
                width="24px"
                height="24px"
                borderRadius={'50%'}
                border="0.5px solid #CBD5E0"
                display="grid"
                placeItems={'center'}
                bg="white"
                color="text.secondary"
                boxShadow={'lg'}
                cursor="pointer"
                zIndex={10}
                onClick={() => setOpen(!open)}
                _hover={{ bg: 'primary', color: '#fff' }}
              >
                <BsArrowLeftShort size={20} />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip
              placement="right"
              hasArrow
              aria-label="expand"
              label="Expand"
            >
              <Box
                position="absolute"
                top={5}
                right={-3}
                width="24px"
                height="24px"
                borderRadius={'50%'}
                border="0.5px solid #CBD5E0"
                display="grid"
                placeItems={'center'}
                bg="white"
                color="text.secondary"
                boxShadow={'lg'}
                cursor="pointer"
                zIndex={10}
                onClick={() => setOpen(!open)}
                _hover={{ bg: 'primary', color: '#fff' }}
              >
                <BsArrowRightShort size={20} />
              </Box>
            </Tooltip>
          )}

          <Box>{renderNavItems()}</Box>
          <Box>{renderBottomItems()}</Box>
        </StyledLeftNav>
      ) : (
        <StyledLeftNavCondensed>
          <Box
            width={'100%'}
            display="flex"
            flexDirection="column"
            alignItems={'center'}
          >
            {renderNavItems()}
          </Box>
          <Box
            width={'100%'}
            display="flex"
            flexDirection="column"
            alignItems={'center'}
          >
            {renderBottomItems()}
          </Box>
        </StyledLeftNavCondensed>
      )}
    </>
  );
}

export default LeftNav;
