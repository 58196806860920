import { SimpleGrid } from '@chakra-ui/react';
import { apiClient } from '@devd-client/api';
import { IntegrationCard } from '@devd-client/devd/components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router';

const IntegrationCardsContainer = styled.div`
  margin-top: 8px;
  margin-right: 12px;
`;

const ViewAllLink = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  line-height: 15px;
  margin-top: 13px;
`;

export type IntegrationData = {
  name: string;
  type: string;
  active: boolean;
  projects: any;
  fieldMap: string;
  fieldMapVals: string;
  properties: any;
  status: string;
  message: null;
  displayValue: string;
};

const IntegrationCards = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [activeIntegrationData, setActiveIntegrationData] = useState<
    IntegrationData[]
  >([]);
  const [inactiveIntegrationData, setInactiveIntegrationData] = useState<
    IntegrationData[]
  >([]);

  useEffect(() => {
    try {
      setLoading(true);
      apiClient(`/v1/integration/org/${localStorage.getItem('orgId')}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
      }).then((res: any) => {
        setLoading(false);

        setActiveIntegrationData(
          res.dto.filter((item: any) => item.active === true)
        );
        setInactiveIntegrationData(
          res.dto.filter((item: any) => item.active === false)
        );
      });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const filterIntegrations = ['GitHub', 'Jira', 'Slack'];

  return (
    <IntegrationCardsContainer>
      {inactiveIntegrationData.length > 0 && !loading ? (
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
          {inactiveIntegrationData
            .filter((integration: IntegrationData) =>
              filterIntegrations.some(
                (item: string) => item === integration.displayValue
              )
            )
            .sort((a: IntegrationData, b: IntegrationData) => {
              if (a.displayValue < b.displayValue) {
                return -1;
              }
              if (a.displayValue > b.displayValue) {
                return 1;
              }
              return 0;
            })
            .map((integration: IntegrationData, index: number) => {
              return integration.name !== '' ? (
                <IntegrationCard
                  key={`integration-${index}`}
                  {...integration}
                  setClickSubmit={() => console.log('submit')}
                />
              ) : (
                <></>
              );
            })}
        </SimpleGrid>
      ) : (
        <></>
      )}

      <ViewAllLink onClick={() => navigate('/settings/integrations')}>
        {'View all integrations'} <BsArrowRight style={{ marginLeft: '2px' }} />
      </ViewAllLink>
    </IntegrationCardsContainer>
  );
};

export default IntegrationCards;
