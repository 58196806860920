import { Box, Flex } from '@chakra-ui/react';
import {
  DataGrid,
  Paginate,
  PieChartShared,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #0095e6;
`;

interface ListViewProps {
  data: any;
  loading: boolean;
}

const ListView = ({ data, loading }: ListViewProps) => {
  const [currentPage, setCurrentPage] = useState(0);

  const rows: any[] = useMemo(() => data || [], [data]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('parentId', {
        cell: (info) => (
          <StyledLink
            to={info.row.original?.parentUrl}
            target="_blank"
            rel="noopener"
          >
            {info.getValue()}
          </StyledLink>
        ),
        header: 'ID',
      }),

      columnHelper.accessor('summary', {
        cell: (info) => info.getValue(),
        header: 'Summary',
        size: 200,
      }),
      columnHelper.accessor('assignee', {
        cell: (info) => info.getValue(),
        header: 'Assignee',
        size: 200,
      }),

      columnHelper.accessor('bugDistribution', {
        cell: (info) => (
          <>
            <Flex
              direction="row"
              width="full"
              justify={'center'}
              align="center"
              overflowY="auto"
            >
              <Flex
                height={
                  info.row.original?.bugDistribution?.length > 16
                    ? '465px'
                    : info.row.original?.bugDistribution?.length > 10
                    ? '345px'
                    : info.row.original?.bugDistribution?.length > 8
                    ? '285px'
                    : info.row.original?.bugDistribution?.length >= 6
                    ? '225px'
                    : '140px'
                }
                width="240px"
                direction="column"
              >
                <PieChartShared
                  pieSize={35}
                  data={info.row.original?.bugDistribution?.map(
                    (item: any, idx: number) => ({
                      ...item,
                      value:
                        item.value === 'null'
                          ? 0
                          : item.value === null
                          ? 0
                          : parseFloat(item.value),
                      fill: item.color,
                    })
                  )}
                />
              </Flex>
            </Flex>
          </>
        ),
        header: 'Bug Distribution',
        size: 200,
      }),
      columnHelper.accessor('status', {
        cell: (info) => info.getValue(),
        header: 'Status',
        size: 200,
      }),
      columnHelper.accessor('updatedAt', {
        cell: (info) => info.getValue() && moment(info.getValue()).format('ll'),
        header: 'Updated At',
        size: 200,
      }),
    ],
    [data]
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <Box>
      <DataGrid
        data={rows}
        columns={columns}
        showLoader={loading}
        maxH="460px"
        sticky="firstCol"
      />

      {/* <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={data?.currentPage + 1}
          totalPageCount={data?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box> */}
      {/* {(customFieldLoading || deleteCustomMetricLoading) && <Loader />} */}
    </Box>
  );
};

export default ListView;
