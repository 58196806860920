import { useState } from 'react';
import {
  Box,
  Tooltip,
  IconButton,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  AiOutlineFileText,
  AiOutlineMail,
  AiOutlineMessage,
  AiOutlinePhone,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';

declare global {
  interface Window {
    Intercom: any;
    analytics: any;
  }
}

interface HelpIconButtonProps {
  salesButtonAction: () => void;
}

const HelpIconButton: React.FC<HelpIconButtonProps> = ({
  salesButtonAction,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const openChat = () => {
    if (window?.Intercom) {
      window?.Intercom('show');
    }
  };

  const redirectToDocs = () => {
    window.open('https://docs.devdynamics.ai/', '_blank');
  };

  const openEmail = () => {
    window.open('mailto:support@devdynamics.ai');
  };

  return (
    <Box position="relative" alignSelf={'center'}>
      <Menu>
        <Tooltip aria-label="Help" label="Help" hasArrow placement="bottom">
          <MenuButton _hover={{ color: 'primary', bg: 'gray.100' }}>
            <IconButton
              aria-label="Help"
              variant="ghost"
              color="text.primary"
              onClick={() => setShowOptions(!showOptions)}
              icon={<AiOutlineQuestionCircle size="24" cursor={'pointer'} />}
            />
          </MenuButton>
        </Tooltip>
        <MenuList mr={'20px'}>
          <MenuItem onClick={openChat} icon={<AiOutlineMessage size={20} />}>
            Chat with us
          </MenuItem>
          <MenuItem
            onClick={redirectToDocs}
            icon={<AiOutlineFileText size={20} />}
          >
            Help docs
          </MenuItem>
          <MenuItem
            onClick={salesButtonAction}
            icon={<AiOutlinePhone size={20} />}
          >
            Talk to us
          </MenuItem>
          <MenuItem onClick={openEmail} icon={<AiOutlineMail size={20} />}>
            Email us
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default HelpIconButton;
