import React, { FC, useContext, useMemo } from 'react';
import { InvestmentsContext } from '../../../../context/investmentContext';
import { DataGrid, Paginate } from '@devd-client/devd/components';
import styled from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { Box } from '@chakra-ui/react';

interface DataTableProps {
  handlePageClick: (event: any) => void;
  isFetching: boolean;
}

const AnchorLink = styled.a`
  text-decoration: underline;
  color: #0095e6;
`;

const DataTable: FC<DataTableProps> = ({ handlePageClick, isFetching }) => {
  const {
    state: { ticketData },
  } = useContext(InvestmentsContext);

  const rows: any[] = useMemo(() => ticketData?.data || [], [ticketData?.data]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('key', {
        cell: (info) => (
          <AnchorLink
            href={info.row.original.ticketUrl}
            target="_blank"
            rel="noreferrer"
          >
            {info.getValue()}
          </AnchorLink>
        ),
        header: 'Key',
      }),
      columnHelper.accessor('summary', {
        cell: (info) => info.getValue(),
        header: 'Summary',
        size: 360,
      }),
      columnHelper.accessor('updatedDate', {
        cell: (info) => info.getValue(),
        header: 'Date',
      }),
      columnHelper.accessor('assignee', {
        cell: (info) => info.getValue(),
        header: 'Assignee',
      }),

      columnHelper.accessor('ticketType', {
        cell: (info) => info.getValue(),
        header: 'Type',
      }),
    ],
    [ticketData?.data]
  );

  return (
    <Box>
      <DataGrid
        showLoader={isFetching}
        maxH="20rem"
        columns={columns}
        data={rows}
        sticky="firstCol"
      />

      <Paginate
        currentPage={ticketData?.currentPage}
        totalPageCount={ticketData?.totalPages}
        pageSize={20}
        onPageChange={handlePageClick}
      />
    </Box>
  );
};

export default React.memo(DataTable);
