export function Home(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.39169 16.152H5.39169V10.152H11.3917V16.152H14.3917V7.15201L8.39169 2.65201L2.39169 7.15201V16.152ZM0.391693 18.152V6.15201L8.39169 0.152008L16.3917 6.15201V18.152H9.39169V12.152H7.39169V18.152H0.391693Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
}
