export const viewSwitchObj = [
  {
    tab: 'Day',
    tooltipText: 'Day',
    TabIcon: '',
  },
  {
    tab: 'Week',
    tooltipText: 'Week',
    TabIcon: '',
  },
  {
    tab: 'Month',
    tooltipText: 'Month',
    TabIcon: '',
  },
];

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.substring(1);

export const filterOptions = [
  { value: '', label: '-' },
  { value: 'PROJECT', label: 'Project' },
  { value: 'PRIORITY', label: 'Priority' },
];
