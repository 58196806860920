import { useCallback, useState } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import './chart.css';
import { useNavigate } from 'react-router';
import {
  CFR_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  CUSTOM_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  getKeyByValue,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  PR_CHART_KEYS,
  QUALITY_CHART_KEYS,
  splitTime,
} from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';
import { WELLBEING_CHART_KEYS } from '../shared/utils/index';

interface ComposedChartRechartsProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  granularSlant?: boolean;
  forDetails?: boolean;
  ticketType?: string;
  queryKey?: string;
  repos?: string[];
  selected?: any;
}

export const ComposedChartRecharts = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  granularSlant,
  forDetails,
  ticketType,
  queryKey,
  repos,
  selected,
}: ComposedChartRechartsProps) => {
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={'#0095E6'}
          fontSize={7.5}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          ref={chartref}
          data={data}
          margin={{
            top: 10,
            right: 35,
            left: showLabel ? 0 : slant ? 108 : -5,
            bottom: forDetails ? 20 : -45,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={chartMetadata.xAxis}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: chartMetadata.xAxis === 'label' ? 9.5 : 7.5,
              fontWeight: 500,
              cursor: slant ? 'pointer' : undefined,
            }}
            angle={slant || granularSlant ? -60 : undefined}
            textAnchor={granularSlant || slant ? 'end' : 'middle'}
            interval={granularSlant || slant ? 0 : undefined}
            onClick={slant ? handleClick : undefined}
            tick={slant ? renderCustomTick : undefined}
          />
          {chartMetadata.zAxis && (
            <YAxis
              orientation="right"
              dataKey={chartMetadata.zAxis}
              tickFormatter={
                chartMetadata.ylabel === 'Hour'
                  ? splitTime
                  : chartMetadata.zlabel === 'Days'
                  ? (e) => splitTime(e / 60)
                  : (e) => {
                      return e;
                    }
              }
              label={
                !showLabel
                  ? {}
                  : {
                      value: chartMetadata.zlabel,
                      angle: -270,
                      position: 'outsideRight',
                      fontSize: 11,
                      fontWeight: 400,
                      dx: 20,
                    }
              }
              axisLine={false}
              tickLine={false}
              style={{ fontSize: 11, fontWeight: 400 }}
            />
          )}
          <YAxis
            yAxisId="left"
            dataKey={chartMetadata.yAxis}
            orientation="left"
            style={{ fontSize: 11, fontWeight: 400 }}
            tickLine={false}
            axisLine={false}
            label={
              !showLabel
                ? {}
                : {
                    value: chartMetadata.ylabel,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 11,
                    fontWeight: 400,
                    dx: 10,
                  }
            }
          />

          <Tooltip
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.zlabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                hideBtn={hideBtn}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />

          {showLabel && (
            <Legend
              verticalAlign="middle"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: '20%',
                transform: 'translateY(-50%)',
              }}
            />
          )}

          {keys?.map((el: any) =>
            el.key === 'yRollingAvg' ? (
              <Line
                key={el.key}
                type="monotone"
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                dot={false}
                activeDot={false}
                strokeWidth={2}
                strokeDasharray="3 6"
              />
            ) : el.chartType === 'line_chart' ? (
              <Line
                key={el.key}
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                strokeWidth={2}
                dot={{
                  fill: el.color,
                  stroke: el.color,
                  strokeWidth: 3,
                  r: 2,
                }}
              />
            ) : el.chartType === 'bar_chart' ? (
              <Bar
                yAxisId="left"
                key={el.key}
                dataKey={el.key}
                name={el.name}
                barSize={20}
                fill={el.color}
                style={{ cursor: 'pointer' }}
                onClick={(event: any, payload: any) => {
                  handleModalOpen();
                  setCurrentChartMeta(chartMetadata);
                  setClickedData({
                    payload: {
                      startDate: event.startDate,
                      endDate: event.endDate,
                      label: event?.payload?.label,
                    },
                    name: event.name,
                  });
                }}
              />
            ) : (
              <></>
            )
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...CONTRIBUTOR_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...GOAL_METRIC_GIT_KEYS,
        ...GOAL_METRIC_JIRA_KEYS,
        ...WELLBEING_CHART_KEYS,
        ...QUALITY_CHART_KEYS,
        ...CUSTOM_CHART_KEYS,
        ...ISSUE_LIFECYCLE_DIST_CHARTS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={queryKey}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={
              chartMetadata.chartKey === 'ISSUE_LIFECYCLE_DIST'
                ? `${moment(selected?.startDate).format('DD MMM')} - ${moment(
                    selected?.endDate
                  ).format('DD MMM')}${
                    data.find(
                      (d: any) => d?.label === clickedData?.payload?.label
                    )?.value !== undefined
                      ? `, ${
                          clickedData?.payload?.label
                            ? `${clickedData?.payload?.label} `
                            : ''
                        }Issue Count (${
                          data.find(
                            (d: any) => d?.label === clickedData?.payload?.label
                          )?.value
                        })`
                      : ''
                  } `
                : `${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.startDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.startDate).format('DD MMM')
                  } - ${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.endDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.endDate).format('DD MMM')
                  }${
                    clickedData[0]
                      ? `, ${clickedData[0]?.name} (${clickedData[0]?.payload?.y})`
                      : ''
                  }`
            }
            payload={
              chartMetadata.chartKey === 'ISSUE_LIFECYCLE_DIST'
                ? {
                    ...clickedData,
                    payload: {
                      startDate: selected?.startDate,
                      endDate: selected?.endDate,
                    },
                  }
                : clickedData
            }
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              type: ticketType,
              projectsFilter: repos,
              sprintId: selected.sprintId,
              status:
                chartMetadata.chartKey === 'ISSUE_LIFECYCLE_DIST'
                  ? (getKeyByValue(clickedData?.payload?.label) as string) ||
                    (getKeyByValue(clickedData[0]?.payload?.label) as string)
                  : '',
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
