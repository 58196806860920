import {
  AuthAction,
  AuthActionTypes,
  AuthState,
  authReducer,
} from '../reducer/authReducer';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
  graphQLClient,
  updateClientAPIWithRegion,
  updateClientGraphQLWithRegion,
} from '@devd-client/api';

import {
  Loader,
  useToastHook,
  useDashboardStore,
  useTeamComparisonStore,
  useMetricsTableStore,
  useDetailsPageStore,
} from '@devd-client/devd/components';
import { auth } from '../helpers/auth';
import {
  absoluteClearInterval,
  absoluteSetInterval,
} from '../helpers/authUtils';
import { getUser } from '../api/endpoints';
import { useFetchOrdinals } from '../api';
export interface AuthProps {
  children?: React.ReactNode;
}

const initState = {
  user: null,
  authIsReady: false,
};

const nan = () => {
  return;
};

export const AuthContext = React.createContext<
  [AuthState, React.Dispatch<AuthAction>, () => void]
>([initState, nan, nan]);

export const AuthProvider = ({ children }: AuthProps) => {
  const [, setToken] = useState(null);
  const [newToast] = useToastHook();
  const [pending, setPending] = useState(true);
  const [state, dispatch] = React.useReducer(authReducer, initState);
  const metricTableFilters = useMetricsTableStore();

  const handleLogout = async () => {
    // eslint-disable-next-line no-debugger
    dispatch({ type: AuthActionTypes.LOGOUT });
    localStorage.removeItem('token');
    localStorage.removeItem('authId');
    useDashboardStore.setState({
      selectedTeam: {
        value: '',
        label: 'Org',
      },
      selected: {
        id: 0,
        startDate: '',
        endDate: '',
        duration: '',
        sprintId: '',
      },
      filterTitle: '',
      active: '',
    });
    useDetailsPageStore.setState({
      selected: {
        id: 0,
        startDate: '',
        endDate: '',
        duration: '',
        sprintId: '',
      },
      filterTitle: '',
      active: '',
    });
    useTeamComparisonStore.setState({
      selectedTeams: [],
      selectedMetrics: [],
      isChecked: true,
    });
    localStorage.removeItem('team');
    metricTableFilters.reset();
    localStorage.setItem('isLoggedIn', '' + false);
    localStorage.removeItem('region');
    updateClientGraphQLWithRegion(localStorage.getItem('region'));
    updateClientAPIWithRegion(localStorage.getItem('region'));
    localStorage.clear();
    await auth.signOut();
  };

  useEffect(() => {
    let isAvailable = true;
    dispatch({ type: AuthActionTypes.LOGOUT });

    const handleAuthStateChanged = async (user: any) => {
      if (!isAvailable) return;
      if (!user) {
        setToken(null);
        localStorage.setItem('token', '');
        setPending(false);
        return;
      }

      try {
        const idTokenResult = await user.getIdTokenResult(true);

        if (
          !idTokenResult.claims.orgId &&
          window.location.pathname.includes('/account/login')
        ) {
          newToast({
            message:
              'This user is not registered with any organization. Please signup at https://devdynamics.ai/signup',
            status: 'error',
          });
          handleLogout();
          return;
        }

        const {
          token,
          claims: { orgId, role, auth_time },
        } = idTokenResult;

        if (
          token &&
          orgId &&
          [
            'SUPPORT',
            'EMPLOYER_ADMIN',
            'EMPLOYER_READ',
            'EMPLOYER_WRITE',
            'EMPLOYEE',
            'MANAGER',
          ].includes(role)
        ) {
          localStorage.setItem('authId', user.uid);
          localStorage.setItem('token', token);
          localStorage.setItem('role', role);

          graphQLClient.setHeader('authorization', `bearer ${token}`);
          setToken(token);

          try {
            const userResponse = await getUser();
            const {
              dto: { org_id, org_name, id, team, region },
            } = userResponse;

            localStorage.setItem('orgId', org_id);
            localStorage.setItem('orgName', org_name);
            localStorage.setItem('userId', id);
            if (team) localStorage.setItem('team', team);
            if (region) localStorage.setItem('region', region);

            updateClientGraphQLWithRegion(region);
            updateClientAPIWithRegion(region);

            dispatch({
              type: AuthActionTypes.AUTH_READY,
              payload: { ...user, ...userResponse },
            });
          } catch (error: any) {
            newToast({
              message: error?.message || 'Error in fetching user data',
              status: 'error',
            });
            handleLogout();
          } finally {
            setPending(false);
          }

          const authTime = auth_time * 1000;
          const sessionDurationInMilliseconds = 60 * 60 * 1000; // 60 minutes
          const expirationInMilliseconds =
            sessionDurationInMilliseconds - (Date.now() - authTime);
        } else {
          handleUnauthorizedUser();
        }
      } catch (error: any) {
        console.log('Error found in fetching token:', error);
        newToast({
          message: error?.message || 'Error in fetching token',
          status: 'error',
        });
      }
    };

    const handleUnauthorizedUser = () => {
      setPending(false);
      setToken(null);
      handleLogout();
      newToast({
        message: 'User not authorised.',
        status: 'error',
      });
    };

    auth.onIdTokenChanged(handleAuthStateChanged);

    return () => {
      isAvailable = false;
    };
  }, []);

  useEffect(() => {
    const handle = absoluteSetInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken(true);
          localStorage.removeItem('token');
          localStorage.setItem('token', token);
          graphQLClient.setHeader('authorization', `bearer ${token}`);
        } catch (error) {
          handleLogout();
        }
      }
    }, 55 * 60 * 1000);

    return () => absoluteClearInterval(handle);
  }, []);

  if (pending) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={[state, dispatch, handleLogout]}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

declare global {
  interface Window {
    Intercom: any;
    analytics: any;
  }
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const [appState, , handleLogout] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useFetchOrdinals();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    window.analytics.identify(appState?.user?.dto?.id, {
      firstName: appState?.user?.dto?.firstName,
      lastName: appState?.user?.dto?.lastName,
      email: appState?.user?.email,
      orgId: appState?.user?.dto?.org_id,
    });
  }, [location]);

  useEffect(() => {
    if (
      data?.dto?.setupSteps.some(
        (item: any) =>
          item.accountIntegrationStatus === 'FAILED' ||
          item.accountIntegrationStatus === 'INIT'
      )
    ) {
      navigate('/onboarding');
    }
  }, [data]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    setTimeout(() => {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'diiw5xyw',
        name: appState?.user?.displayName,
        email: appState?.user?.email,
        created_at: appState?.user?.metadata?.createdAt,
      });
    }, 5000);

    return () => window.Intercom('shutdown');
  }, []);

  if (!appState.user) {
    return <Navigate to="/account/login" state={{ from: location }} replace />;
  }

  return children;
}
