/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import DoraProgressItem from './DoraProgressItem';
import { useInsightMetricGql } from '../../../graphql';
import { convertToTitleCase } from '../../../helpers/home.utils';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { BsArrowRight } from 'react-icons/bs';
import { Link as NavLink } from 'react-router-dom';
import { beforeStyles, hoverBeforeStyles } from '../InsightCards.utils';
import { AppTooltip, PeriodType } from '@devd-client/devd/components';

interface DoraProgressCardProps {
  team: string;
  selected: PeriodType;
}

const DoraProgressCard: React.FC<DoraProgressCardProps> = ({
  team,
  selected,
}) => {
  const { data, isFetching } = useInsightMetricGql(
    'DORA_PERFORMANCE',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const progressData =
    data &&
    data?.data &&
    Object.entries(data?.data)?.map(([key, value]) => ({
      key,
      value,
    }));

  return (
    <>
      {isFetching ? (
        <Box position={'relative'} minWidth="100%" height="248px">
          <Skeleton h="248px" w="100%" />
          <Spinner position={'absolute'} top={'40%'} left={'43%'} />
        </Box>
      ) : data?.chartDataState === 'NO_INTEGRATION' ? (
        <Box
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          position="relative"
          minH="248px"
          border="1px solid #E3E6EA"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            height="100%"
            src={data?.chartMetadata?.zsImage || ''}
            style={{ position: 'relative' }}
          />
          {isHovered && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius="4px"
            >
              {data?.chartMetadata?.source && (
                <Button
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  onClick={() => navigate('/settings/integrations')}
                >
                  Connect{' '}
                  {data?.chartMetadata?.source === 'ISSUE TRACKING'
                    ? 'Jira'
                    : data?.chartMetadata?.source === 'REPOSITORY'
                    ? 'Github'
                    : ''}
                </Button>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Flex
          direction="column"
          p={3}
          w="full"
          minW="230px"
          minH="370px"
          borderRadius="4px"
          border="1px solid #dde0e4"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
        >
          <Flex direction="column" mb={4}>
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {data?.chartMetadata?.chartTitle}
              </Text>
              {data?.chartDataState !== 'ERROR' &&
                data?.chartDataState !== 'NOT_CONFIGURED' && (
                  <Flex
                    as={NavLink}
                    to={`/dora?${window.location.href.split('?')[1]}`}
                    align="center"
                    justify="space-between"
                    color="text.secondary"
                    cursor="pointer"
                    position="relative"
                    fontSize="sm"
                    _hover={{ _before: hoverBeforeStyles }}
                    _before={beforeStyles}
                  >
                    <Text
                      fontSize="xs"
                      fontWeight="semibold"
                      transition="all 300ms linear"
                      mr={1}
                    >
                      View Details
                    </Text>
                    <BsArrowRight />
                  </Flex>
                )}

              {(data?.chartDataState === 'ERROR' ||
                data?.chartDataState === 'NOT_CONFIGURED') && (
                <AppTooltip
                  trigger="hover"
                  content={
                    <Flex>
                      <Text
                        color="text.primary"
                        fontFamily="heading"
                        fontSize="sm"
                        mr={1}
                      >
                        {data?.chartError?.message}
                      </Text>
                      <NavLink to={`${data?.chartError?.link}`}>
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </NavLink>
                    </Flex>
                  }
                >
                  <Box>
                    <Button
                      size={'xs'}
                      color={'error'}
                      border={'1px'}
                      borderColor={'error'}
                      backgroundColor={'white'}
                      fontFamily="heading"
                    >
                      {'why no data?'}
                    </Button>
                  </Box>
                </AppTooltip>
              )}
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {data?.chartMetadata?.info}
            </Text>
          </Flex>

          {progressData?.map((item: any, idx: number) => (
            <DoraProgressItem
              key={idx}
              type={item.value}
              title={convertToTitleCase(item.key)}
              keys={data?.keys}
            />
          ))}
        </Flex>
      )}
    </>
  );
};

export default DoraProgressCard;
