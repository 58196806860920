import { FC, useContext, useMemo } from 'react';
import { DataGrid, Paginate } from '@devd-client/devd/components';
import { InitiativeContext } from '../../../../../contexts/initiativesContext';
import styled from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { useContributorsGql } from '../../../../../graphql';

const AnchorLink = styled.a`
  text-decoration: underline;
  color: #0095e6;
`;

interface ContributorsTableProps {
  selectedProjectId: string;
}

const ContributorsTable: FC<ContributorsTableProps> = ({
  selectedProjectId,
}) => {
  const {
    state: { contributors },
    dispatch,
  } = useContext(InitiativeContext);

  const { isFetching: contributorsLoading } = useContributorsGql(
    selectedProjectId,
    dispatch
  );

  const rows: any[] = useMemo(
    () => contributors?.contributorActivities || [],
    [contributors?.contributorActivities]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('contributorAccount', {
        cell: (info) => info.getValue(),
        header: 'Account',
      }),
      columnHelper.accessor('team', {
        cell: (info) =>
          info
            .getValue()
            ?.map((team: string) => team)
            .join(', '),
        header: 'Teams',
      }),
      columnHelper.accessor('issueCount', {
        cell: (info) => info.getValue(),
        header: 'Issue count',
      }),
    ],
    [contributors?.contributorActivities]
  );

  return (
    <>
      <DataGrid
        showLoader={contributorsLoading}
        columns={columns}
        data={rows}
        maxH="26rem"
        sticky="firstCol"
      />
      {/* <Box marginLeft="-20px">
        <Paginate
          currentPage={taskList?.currentPage + 1}
          totalPageCount={taskList?.totalPages}
          pageSize={10}
          onPageChange={(page) => handleCurrentTaskListPage(page as number)}
        />
      </Box> */}
    </>
  );
};

export default ContributorsTable;
