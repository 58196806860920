export function ChatBoxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00001 14.6667V11.3333M3.00001 4.66667V1.33333M1.33334 3H4.66668M1.33334 13H4.66668M8.66668 2L7.51056 5.00591C7.32255 5.49473 7.22855 5.73914 7.08236 5.94472C6.9528 6.12693 6.79361 6.28613 6.6114 6.41569C6.40581 6.56187 6.1614 6.65587 5.67258 6.84388L2.66668 8L5.67259 9.15612C6.16141 9.34413 6.40582 9.43813 6.6114 9.58431C6.79361 9.71387 6.9528 9.87307 7.08236 10.0553C7.22855 10.2609 7.32255 10.5053 7.51056 10.9941L8.66668 14L9.8228 10.9941C10.0108 10.5053 10.1048 10.2609 10.251 10.0553C10.3806 9.87307 10.5397 9.71387 10.722 9.58431C10.9275 9.43813 11.1719 9.34413 11.6608 9.15612L14.6667 8L11.6608 6.84388C11.1719 6.65587 10.9275 6.56187 10.722 6.41569C10.5397 6.28613 10.3806 6.12693 10.251 5.94472C10.1048 5.73914 10.0108 5.49473 9.8228 5.00591L8.66668 2Z"
        stroke="#0095E6"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChatBoxIcon;
