export function convertToSingleDecimal(number: number): number {
  const parsedNumber: number = parseFloat(number.toString());

  if (isNaN(parsedNumber)) {
    return NaN;
  }

  const roundedNumber: string = parsedNumber.toFixed(1);

  return parseFloat(roundedNumber);
}
