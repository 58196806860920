import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useRenameCustomDashboard } from '../../../api';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../../api';
import {
  useToastHook,
  eliminateSpecialCharacters,
} from '@devd-client/devd/components';

interface UpdateDashboardModel {
  isOpen: boolean;
  onClose: () => void;
  selectedDashboard: string;
  setDashboardId?: (id: string) => void;
}

const initialValuesState = { dashboardName: '' };

const UpdateDashboardModel: FC<UpdateDashboardModel> = ({
  onClose,
  isOpen,
  selectedDashboard,
  setDashboardId,
}) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const [initialValues, setInitialValues] = useState(initialValuesState);

  const { mutate: updateCustomDashboard } = useRenameCustomDashboard();

  const validationSchema = Yup.object({
    dashboardName: Yup.string()
      .required('Dashboard name is required')
      .trim()
      .matches(/^[a-zA-Z0-9 ]*$/, 'No special characters allowed')
      .min(3, 'Dashboard name must be at least 3 characters long')
      .max(25, 'Dashboard name must be at under 25 characters'),
  });

  useEffect(() => {
    setInitialValues({
      dashboardName: selectedDashboard,
    });
  }, [selectedDashboard]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Dashboard Name</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              oldName: selectedDashboard,
              newName: eliminateSpecialCharacters(
                values.dashboardName.trimStart()
              ),
            };
            updateCustomDashboard(payload, {
              onSuccess() {
                if (setDashboardId) {
                  setDashboardId(payload.newName);
                }
                newToast({
                  message: 'Dashboard name updated',
                  status: 'success',
                });
                queryClient.invalidateQueries([QUERY_KEYS.customDashboards]);
                setSubmitting(false);
                setTimeout(() => {
                  onClose();
                }, 10);
              },
              onError: (err: any) => {
                newToast({
                  message: err.message ? err.message : 'Something went wrong.',
                  status: 'error',
                });
                setSubmitting(false);
                setTimeout(() => {
                  onClose();
                }, 10);
              },
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Field name="dashboardName">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.dashboardName && form.touched.dashboardName
                      }
                    >
                      <FormLabel>Dashboard Name</FormLabel>
                      <Input {...field} placeholder="Enter dashboard name" />
                      <FormErrorMessage>
                        {form.errors.dashboardName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>

              <ModalFooter>
                <Button mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default UpdateDashboardModel;
