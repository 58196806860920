import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { WarningIcon } from '../icons';
import FeatureItem from './FeatureItem';

interface TrialEndModalProps {
  isOpen: boolean;
  onClose: () => void;
  salesButtonAction: () => void;
  upgradeNowBtnAction: () => void;
  displayUpgrade: boolean;
  activeUsers: number;
}

const TrialEndModal: React.FC<TrialEndModalProps> = ({
  isOpen,
  onClose,
  salesButtonAction,
  upgradeNowBtnAction,
  displayUpgrade,
  activeUsers,
}) => {
  const bodyContent = (
    <>
      <Flex justify="center">
        <WarningIcon height={84} width={84} />
      </Flex>
      <Text
        mt={4}
        textAlign="center"
        fontSize="4xl"
        fontFamily="heading"
        fontWeight="bold"
      >
        Free plan has limited access.
      </Text>
      <Text
        textAlign="center"
        fontSize="sm"
        fontFamily="heading"
        fontWeight="normal"
        color="text.secondary"
      >
        Applicable for an account with 10 contributors or less
      </Text>

      <Text
        mt={8}
        fontFamily="heading"
        color="text.secondary"
        fontWeight="normal"
      >
        Our free plan does{' '}
        <Box as="span" color="text.secondary" fontWeight="semibold">
          not
        </Box>{' '}
        give you our most powerful features.
      </Text>

      <Stack mt={4}>
        <FeatureItem title="Unlimited repos & teams" />
        <FeatureItem
          title="Activity log:"
          subtitle="Get visibility into every repo, project and team"
        />
        <FeatureItem
          title="Working agreements:"
          subtitle="Drive adoption of engineering best practices"
        />
        <FeatureItem
          title="Engineering investment:"
          subtitle="See engineering time distribution"
        />
      </Stack>

      <Flex mt={8} align="center" justify="center">
        <Button onClick={salesButtonAction} mr={3} variant="tertiary">
          Talk to Sales
        </Button>
        <Button variant="secondary" onClick={upgradeNowBtnAction}>
          Upgrade Now
        </Button>
      </Flex>
    </>
  );

  const isClosable =
    displayUpgrade && (activeUsers === null || activeUsers < 10);

  // eslint-disable-next-line
  function dummyOnClose() {}

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={dummyOnClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        {isClosable && <ModalCloseButton onClick={onClose} />}
        <ModalBody w="full" px={8} pb={8}>
          {bodyContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrialEndModal;
