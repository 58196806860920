export function cursorClick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2.5V1M4.06066 4.06066L3 3M4.06066 12L3 13.0607M12 4.06066L13.0607 3M2.5 8H1M14.8645 15.1896L12.3727 19.817C12.0881 20.3457 11.9457 20.61 11.7745 20.6769C11.6259 20.7349 11.4585 20.7185 11.324 20.6328C11.1689 20.534 11.0806 20.2471 10.9038 19.6733L7.44519 8.44525C7.3008 7.97651 7.2286 7.74213 7.28669 7.58383C7.33729 7.44595 7.44595 7.33729 7.58383 7.2867C7.74213 7.22861 7.9765 7.3008 8.44525 7.44519L19.6732 10.9038C20.247 11.0806 20.5339 11.169 20.6327 11.324C20.7185 11.4586 20.7348 11.6259 20.6768 11.7745C20.61 11.9458 20.3456 12.0881 19.817 12.3728L15.1896 14.8645C15.111 14.9068 15.0717 14.9279 15.0374 14.9551C15.0068 14.9792 14.9792 15.0068 14.9551 15.0374C14.9279 15.0717 14.9068 15.111 14.8645 15.1896Z"
        stroke="grey"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
