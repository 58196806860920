import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import {
  Bars,
  BarWrapper,
  Dot,
  GraduationWrapper,
  Label,
  Legend,
  Legends,
  MultiColorBar,
  Scale,
  Values,
  ValueWrapper,
} from './ProgressMultiColor.styled';

interface ProgressMultiColorProps {
  readings?: ReadingType[];
  showScale?: boolean;
  showValues?: boolean;
  showLegend?: boolean;
  unset?: boolean;
  customTooltip?: string;
}

type ReadingType = {
  name: string;
  value: number;
  color: string;
};

export const ProgressMultiColor: FC<ProgressMultiColorProps> = ({
  readings,
  customTooltip,
  showScale,
  showValues,
  showLegend,
  unset,
}) => {
  const values =
    readings &&
    readings.length &&
    readings?.map((item, i) => {
      if (item.value > 0) {
        return (
          <ValueWrapper
            style={{ color: item.color, width: item.value + '%' }}
            key={i}
          >
            <span>{item.value}%</span>
          </ValueWrapper>
        );
      }
      return null;
    });

  const calibrations =
    readings &&
    readings.length &&
    readings?.map((item, i) => {
      if (item.value > 0) {
        return (
          <GraduationWrapper
            style={{ color: item.color, width: item.value + '%' }}
            key={i}
          >
            <span>|</span>
          </GraduationWrapper>
        );
      }
      return null;
    });

  const bars =
    readings &&
    readings.length &&
    readings?.map((item, i) => {
      if (item.value > 0) {
        return (
          <Fragment key={i}>
            {unset ? (
              <Tooltip
                hasArrow
                placement="top"
                label={`${item.value?.toFixed(1)}%`}
              >
                <BarWrapper
                  style={{
                    backgroundColor: item.color,
                    width: item.value + '%',
                  }}
                  key={i}
                ></BarWrapper>
              </Tooltip>
            ) : (
              <BarWrapper
                style={{ backgroundColor: item.color, width: item.value + '%' }}
                key={i}
              ></BarWrapper>
            )}
          </Fragment>
        );
      }
      return null;
    });

  const legends =
    readings &&
    readings.length &&
    readings?.map((item, i) => {
      if (item.value > 0) {
        return (
          <Legend key={i}>
            <Dot style={{ color: item.color }}>●</Dot>
            <Label>{item.name}</Label>
          </Legend>
        );
      }
      return null;
    });

  return (
    <>
      {unset ? (
        <MultiColorBar>
          {showValues && <Values>{values && values}</Values>}

          {showScale && <Scale>{calibrations && calibrations}</Scale>}

          <Bars>{bars && bars}</Bars>

          {showLegend && <Legends>{legends && legends}</Legends>}
        </MultiColorBar>
      ) : (
        <Flex direction="column" justify="center" w="full">
          <Tooltip
            hasArrow
            label={
              customTooltip
                ? customTooltip
                : `Progress: ${readings && readings[0].value}%`
            }
          >
            <MultiColorBar>
              {showValues && <Values>{values && values}</Values>}

              {showScale && <Scale>{calibrations && calibrations}</Scale>}

              <Bars>{bars && bars}</Bars>

              {showLegend && <Legends>{legends && legends}</Legends>}
            </MultiColorBar>
          </Tooltip>
          {customTooltip && (
            <Text
              as="p"
              color="text.secondary"
              textAlign="center"
              fontSize="7.6px"
              fontFamily="heading"
            >
              {customTooltip
                ? customTooltip
                : `Progress: ${readings && readings[0].value}%`}
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};

ProgressMultiColor.defaultProps = {
  showScale: false,
  showValues: false,
  showLegend: false,
};

export default ProgressMultiColor;
