import { apiClient } from '@devd-client/api';

//   Todo: Type of params
export const uploadFile = (params: any): Promise<any> =>
  apiClient(`/v1/upload`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const getTeamMemberToSelect = (team: string): Promise<any> =>
  apiClient(
    `/v1/employee/org/${localStorage.getItem('orgId')}/team?team=${team}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getNonTeamMember = (role: string, team: string): Promise<any> =>
  apiClient(
    `/v1/employee/org/${localStorage.getItem(
      'orgId'
    )}/team-not?role=${role}&teamNot=${team}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getTeamMembers = (team: string): Promise<any> =>
  apiClient(
    `/v1/team/members/org/${localStorage.getItem('orgId')}/team/${team}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const updateTeamMemberRole = ({
  team,
  role,
  email,
}: {
  team: string;
  role: string;
  email: string;
}): Promise<any> => {
  return apiClient(
    `/v1/team/org/${localStorage.getItem('orgId')}/teamName/${team}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        email,
        role,
      }),
    }
  );
};

export const addMembers = (params: any): Promise<any> =>
  apiClient(`/v1/team/members/org/${localStorage.getItem('orgId')}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const deleteMembers = (params: any): Promise<any> =>
  apiClient(
    `/v1/team/members/org/${localStorage.getItem('orgId')}?teamName=${
      params.team
    }&email=${encodeURIComponent(params.email)}`,
    {
      method: 'DELETE',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getTeams = (team: string): Promise<any> =>
  apiClient(
    `/v2/team/${localStorage.getItem('orgId')}${team && `?team=${team}`}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getParentTeams = (teamNot: string): Promise<any> =>
  apiClient(
    `/v2/team/${localStorage.getItem(
      'orgId'
    )}/lite?type=parent&teamNot=${teamNot}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getDataByTeams = (team: string): Promise<any> =>
  apiClient(`/v1/team/${localStorage.getItem('orgId')}/${team}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getAllGitRepos = (): Promise<any> =>
  apiClient(
    `/v1/integration/git/org/${localStorage.getItem('orgId')}/projects/lite`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllQualityProjectsRepos = (): Promise<any> =>
  apiClient(
    `/v1/integration/quality/org/${localStorage.getItem(
      'orgId'
    )}/projects/lite`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllJiraProjects = (): Promise<any> =>
  apiClient(
    `/v1/integration/jira/org/${localStorage.getItem('orgId')}/projects/lite`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllIssueEpic = (): Promise<any> =>
  apiClient(`/v1/issue/Epic/org/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getAllBoardNames = (): Promise<any> =>
  apiClient(
    `/v1/integration/jira/org/${localStorage.getItem('orgId')}/board/lite`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllIncidentManagement = (): Promise<any> =>
  apiClient(
    `/v1/integration/incident/org/${localStorage.getItem(
      'orgId'
    )}/projects/lite`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const addOrUpdateTeam = (params: any): Promise<any> =>
  apiClient(`/v1/team`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const updateTeam = (params: any): Promise<any> =>
  apiClient(
    `/v1/team/org/${localStorage.getItem('orgId')}/team/${params.key.name}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );

export const getTeamHomeData = (): Promise<any> =>
  apiClient(
    `/v2/account/org/${localStorage.getItem('orgId')}/page/TeamSettings`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getDoraSettings = (): Promise<any> =>
  apiClient(`/v1/dora/org/${localStorage.getItem('orgId')}/settings`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
