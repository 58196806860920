import { useCallback, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  Skeleton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { TbBellRinging2 } from 'react-icons/tb';
import { QUERY_KEYS, useMarkAsRead, useNotifications } from '../api';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

type Notification = {
  id: string;
  text: string;
  isRead: boolean | null;
  createdAt: string;
};

const NotificationDropdown = () => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data, isLoading: notificationLoading } = useNotifications();

  const { mutate: markAsRead } = useMarkAsRead();

  const notifications = data?.dto?.alarms?.content?.map((item: any) => ({
    id: item.id,
    text: item.desc,
    isRead: item.read,
    createdAt: item.createdAt,
  }));

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [isOpen]);

  const [unreadCount, setUnreadCount] = useState(
    notifications?.filter((n: Notification) => !n.isRead).length
  );

  const handleMarkAllAsRead = () => {
    //TODO: Mark all notifications as read
  };

  const handleMarkAsRead = (id: string) => {
    markAsRead(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.notifications]);
        },
      }
    );
  };

  const onClose = () => {
    const unreadNotifications = notifications?.filter(
      (n: Notification) => !n.isRead
    );

    if (unreadNotifications?.length > 0) {
      unreadNotifications.forEach((notification: Notification) => {
        if (!notification.isRead) {
          handleMarkAsRead(notification.id);
        }
      });
    }
  };

  return (
    <>
      <Menu
        closeOnSelect={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          toggle();
        }}
        onOpen={toggle}
      >
        <MenuButton
          as={IconButton}
          color="text.primary"
          _hover={{ color: 'primary', bg: 'gray.100' }}
          aria-label="Notifications"
          icon={
            <Tooltip closeOnClick hasArrow label="Notification">
              <Box position="relative">
                <TbBellRinging2 size={20} />
                {data?.dto?.anyUnReadAlarm && (
                  <Badge
                    position="absolute"
                    top="-2px"
                    right="-2px"
                    color="red"
                    fontSize="10px"
                    borderRadius="50%"
                    p="1px"
                  >
                    <Box
                      height="6px"
                      width="6px"
                      borderRadius="50%"
                      bg="red.500"
                    />
                  </Badge>
                )}
              </Box>
            </Tooltip>
          }
          variant="ghost"
        />

        <MenuList
          maxHeight="600px"
          width="560px"
          left={'492px'}
          top={'48px'}
          overflowY="auto"
          background={'#FFFFFF'}
          border={'1px solid #E3E6EA'}
          borderRadius={'4px'}
          boxShadow={'0px 6px 12px rgba(0, 0, 0, 0.1)'}
        >
          <Box
            mt={-2}
            width={'560px'}
            height={'56px'}
            left={'492px'}
            top={'48px'}
            border={'1px solid #E3E6EA'}
            borderRadius={'4px 4px 0px 0px'}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                width={'130px'}
                height={'28px'}
                ml={'24px'}
                my={'14px'}
                fontFamily="Inter"
                fontStyle={'normal'}
                fontWeight="700"
                fontSize="20px"
                lineHeight={'28px'}
                color="#1A202C"
              >
                Notifications
              </Text>

              {/* {unreadCount > 0 && (
              <Button
                color="primary"
                variant="ghost"
                size="xs"
                leftIcon={<MdOutlineDoneAll />}
                onClick={handleMarkAllAsRead}
              >
                Mark all as read
              </Button>
            )} */}
            </Flex>
          </Box>
          {notificationLoading &&
            [0, 1, 2].map((_: number, idx: number) => (
              <Skeleton
                key={idx}
                height="52px"
                width="100%"
                marginBottom="2px"
              />
            ))}

          {notifications?.length <= 0 ? (
            <Text
              px={4}
              fontSize="sm"
              fontFamily="heading"
              color="text.primary"
            >
              No Data.
            </Text>
          ) : (
            notifications?.map((n: Notification, idx: number) => (
              <Box key={idx}>
                <NotificationItem n={n} handleMarkAsRead={handleMarkAsRead} />
              </Box>
            ))
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default NotificationDropdown;

const NotificationItem = ({
  n,
  handleMarkAsRead,
}: {
  n: Notification;
  handleMarkAsRead: (id: string) => void;
}) => {
  const formattedTime = moment(n.createdAt).format('D MMM, h:mm A');

  return (
    <MenuItem p={0} key={n.id} onClick={() => handleMarkAsRead(n.id)}>
      <Flex
        flexDir="column"
        alignItems={'flex-start'}
        p={'16px 24px 16px 10px'}
        gap={'8px'}
        w={'560px'}
        height="78px"
        left="492px"
        top="104px"
        background="#FFFFFF"
        // bg={!n.isRead ? 'gray.100' : 'white'}
        border="1px solid #E3E6EA"
      >
        <Box
          fontSize={'10px'}
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="12px"
          color="#718096"
          bgColor={'#f6f7f9'}
          w="fit-content"
          ml={'12px'}
          p={1}
        >
          {formattedTime}
        </Box>
        <Flex
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
        >
          {!n.isRead && <Box w="8px" h="8px" borderRadius="50%" bg="#0095E6" />}
          <Text
            fontSize="sm"
            fontFamily="heading"
            color="text.primary"
            ml={!n.isRead ? '6px' : '15px'}
          >
            {n.text}
          </Text>
        </Flex>
      </Flex>
    </MenuItem>
  );
};
