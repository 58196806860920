import { Box, Flex, Icon, Text, useTheme } from '@chakra-ui/react';
import {
  IconLabelContainer,
  Label,
  StyledLeftNav,
} from './settings-nav.styled';

import { useEffect } from 'react';
import { IconType } from 'react-icons/lib';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface SettingsNavProps {
  appState: any;
}

interface SettingsNavItemProps {
  id: string;
  label: string;
  icon?: IconType;
  isSelected?: boolean;
  bottom?: boolean;
  onClick: (id: string) => void;
  path: string;
}

function SettingsNavItem(props: SettingsNavItemProps) {
  const { id, icon, label, isSelected, onClick, bottom = false, path } = props;
  const theme = useTheme();

  return (
    <Link to={path} onClick={() => onClick(id)}>
      <Box
        bgColor={isSelected ? theme['colors'].primaryBg : '#FFFFFF'}
        cursor={'pointer'}
        userSelect={'none'}
        mb={2}
      >
        <Flex>
          <Box
            width={1}
            height={'auto'}
            bgColor={isSelected ? 'primary' : '#FFFFFF'}
          />
          <IconLabelContainer>
            {icon && <Icon as={icon} color={isSelected ? 'primary' : ''} />}
            <Label
              color={
                isSelected
                  ? theme['colors'].primary
                  : theme['colors'].text.secondary
              }
            >
              {label}
            </Label>
          </IconLabelContainer>
        </Flex>
      </Box>
    </Link>
  );
}

const NavItems = [
  {
    id: 'Integrations',
    label: 'Integrations',
    path: 'integrations',
  },
  {
    id: 'Investment Categories',
    label: 'Investment categories',
    path: 'investment-categories',
  },
  {
    id: 'Teams',
    label: 'Teams',
    path: 'teams',
  },
  {
    id: 'Members',
    label: 'Members',
    path: 'members',
  },
  {
    id: 'Dora',
    label: 'DORA',
    path: 'dora',
  },
  {
    id: 'Subscription and Billing',
    label: 'Subscription and Billing',
    path: 'subscription-billing',
  },
  {
    id: 'Organisation',
    label: 'Organisation',
    path: 'organisation',
  },
  {
    id: 'Contributors',
    label: 'Contributors',
    path: 'contributors',
  },
  {
    id: 'feedback',
    label: 'Feedback',
    path: 'feedback',
  },
  {
    id: 'notification',
    label: 'Notification',
    path: 'notification',
  },
  {
    id: 'survey',
    label: 'Survey',
    path: 'survey',
  },
  {
    id: 'custom-metrics',
    label: 'Custom metrics',
    path: 'custom-metrics',
  },
];

export function SettingsNav(props: SettingsNavProps) {
  const location = useLocation();
  const currentNav = location.pathname.split('/')[2];

  const showFeedback = props?.appState?.user?.dto?.modules?.cycleFeedback;

  const currentNavId = currentNav
    ? NavItems.find((item) => item.path === currentNav)?.id
    : null;

  const [selectedNavItem, setSelectedNavItem] = useState<any>();

  useEffect(() => {
    setSelectedNavItem(currentNavId ? currentNavId : NavItems[0].id);
  }, [currentNavId]);

  const onNavItemSelect = (id: string): void => {
    const item = NavItems.filter((item) => item.id === id)[0];
    setSelectedNavItem(id);
  };

  const renderNavItems = () => {
    const filteredNavItems = showFeedback
      ? NavItems
      : NavItems.filter((item) => item.id !== 'feedback');

    return filteredNavItems.map((item: any) => {
      return (
        <SettingsNavItem
          key={item.id}
          isSelected={selectedNavItem === item.id}
          onClick={onNavItemSelect}
          {...item}
        />
      );
    });
  };

  return (
    <StyledLeftNav>
      <Box>
        <Text
          fontSize={'sm'}
          fontWeight={'medium'}
          fontFamily="heading"
          color="text.primary"
          userSelect={'none'}
          letterSpacing={'0.1px'}
          m={5}
        >
          Settings
        </Text>
        {renderNavItems()}
      </Box>
    </StyledLeftNav>
  );
}

export default SettingsNav;
