import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';

interface CumulativeFlowDiagramAreaChartProps {
  data: any;
  keys?: any;
  chartMetadata?: any;
}

export const AreaChartRecharts = ({
  data,
  keys,
  chartMetadata,
}: CumulativeFlowDiagramAreaChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey={chartMetadata.xAxis}
          axisLine={false}
          tickLine={false}
          padding={{ left: 5, right: 5 }}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <YAxis
          label={{
            value: chartMetadata.ylabel,
            angle: -90,
            position: 'insideLeft',
            fontSize: 11,
            fontWeight: 400,
          }}
          axisLine={false}
          tickLine={false}
          type="number"
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <defs>
          <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0095E6" stopOpacity={0.8} />
            <stop
              offset="95%"
              stopColor="rgba(0, 149, 230, 0)"
              stopOpacity={0.8}
            />
          </linearGradient>
        </defs>
        <Legend
          verticalAlign="bottom"
          iconType="circle"
          iconSize={11}
          height={36}
          align="right"
          wrapperStyle={{ left: 35, fontSize: 11 }}
        />

        {keys.map((el: any) => (
          <Area
            key={el.key}
            type="monotone"
            dataKey={el.key}
            name={el.name}
            strokeWidth={2}
            stackId="2"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={1}
            activeDot={{ r: 6 }}
            dot={{
              fill: el.color,
              stroke: el.color,
              strokeWidth: 3,
              r: 2,
            }}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
