import React, { useEffect, useState } from 'react';
import { Box, CloseButton, Flex, Text } from '@chakra-ui/react';
import { ChatBoxIcon, useAiAssistantStore } from '@devd-client/devd/components';
import { useFetchContributorInsights } from '../../api';

export const Chatbox: React.FC = () => {
  const { toggleChatbox, data, setData } = useAiAssistantStore();

  const {
    data: contributorInsightsData,
    isFetching: contributorInsightsFetching,
  } = useFetchContributorInsights(data);

  const handleClose = () => {
    toggleChatbox();
    setData(null);
  };

  const [typedContent, setTypedContent] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const content = contributorInsightsData?.content || '';

  useEffect(() => {
    if (!content || contributorInsightsFetching) return;

    const interval = setInterval(() => {
      if (currentIndex < content.length) {
        setTypedContent((prevContent) => prevContent + content[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, 2); // Adjust the typing speed here (e.g., increase or decrease the interval)

    return () => clearInterval(interval);
  }, [content, currentIndex, contributorInsightsFetching]);

  return (
    <Box
      position="fixed"
      bottom="20px"
      right="60px"
      p="4"
      color="black"
      bgColor="white"
      borderColor={'primary'}
      borderRadius="md"
      borderWidth={1}
      boxShadow="lg"
      w="420px"
      mr={6}
    >
      <Flex mb="2" alignItems={'center'} justifyContent={'space-between'}>
        <Flex alignItems={'center'}>
          <Box mr={2}>
            <ChatBoxIcon width="20px" height="20px" />
          </Box>
          <Text
            fontFamily={'inter'}
            color={'#0C111D'}
            fontSize={'16px'}
            lineHeight={'24px'}
            fontWeight={'600'}
          >
            AI Assistant
          </Text>
        </Flex>

        <CloseButton onClick={handleClose} />
      </Flex>
      <Box
        borderWidth={1}
        py="2"
        px="3"
        h={'350px'}
        borderRadius="md"
        position="relative"
        fontFamily={'inter'}
        color={'#667085'}
        fontSize={'14px'}
        lineHeight={'20px'}
        fontWeight={'400'}
        borderLeftWidth="4px"
        borderLeftColor="primary"
        borderLeftStyle="solid"
        overflowY={'auto'}
      >
        {contributorInsightsFetching ? (
          <div>Loading...</div>
        ) : (
          <Text
            minH={'20px'}
            dangerouslySetInnerHTML={{ __html: typedContent }}
          />
        )}
      </Box>
    </Box>
  );
};
