import styled from 'styled-components';

type PaginationItemType = {
  disabled?: boolean;
  selected?: boolean;
  dots?: boolean;
};

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  margin-top: 10px;
`;

export const PaginationItem = styled.li<PaginationItemType>`
  display: flex;
  justify-content: center;
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 2px;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#1A202C')};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  & span {
    border-bottom: ${({ selected }) => selected && ' 2px solid #0095E6'};
  }

  pointer-events: ${({ disabled }) => disabled && 'none'};
  user-select: ${({ disabled }) => disabled && 'none'};
`;

export const PrevLabel = styled.div`
  font-size: '12px';
  font-family: 'Inter';
  font-weight: 400;
  text-wrap: nowrap;
`;

export const NextLabel = styled.div`
  font-size: '12px';
  font-family: 'Inter';
  font-weight: 400;
`;
