import { FC, Fragment, useEffect } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Text,
  Button,
  Tooltip,
  useTheme,
  Spinner,
} from '@chakra-ui/react';
import { SlideBar } from '../../../appSlideBar';
import { Paginate } from '../../../appPaginate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  AuthorIdFilter,
  CFR_CHART_KEYS,
  COMMIT_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_CHART_KEYS,
  PR_HEALTH_INDEX_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  TeamFilter,
  WORK_BREAKDOWN_CHARTS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  ISSUE_AGE_CHART_KEYS,
  splitTime,
  REQUIREMENT_STABILITY_CHARTS,
} from '../../../shared/utils';
import IgnoreIcon from '../../../icons/IgnoreIcon';
import { useIgnorePrOrIssue } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../../../appToast';
import ProgressMultiColor from '../../../progressMultiColor';
import Badge from '../Badge';

import { useNavigate } from 'react-router-dom';
import {
  useDashboardStore,
  useDetailsPageStore,
} from '../../../hooks/useDashboardStore';
import KeyValueLine from '../KeyValueLine';
import { useInView } from 'react-intersection-observer';

interface DefaultSideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  defaultData: any;
  heading: string;
  subHeading: string;
  isFetching: boolean;
  handlePageClick: any;
  currentChartMeta: any;
  querykey?: string;
  startDate?: string;
  endDate?: string;
  filterBy: AuthorIdFilter | TeamFilter;
  payload: any;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
}

interface NavigationState {
  heading: string;
  filterBy: AuthorIdFilter | TeamFilter;
  currentChartMeta: any;
  payload: any;
}

const DefaultSideBar: FC<DefaultSideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  defaultData,
  heading,
  subHeading,
  isFetching,
  handlePageClick,
  currentChartMeta,
  querykey,
  startDate,
  endDate,
  filterBy,
  payload,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const colors = useTheme();
  const { mutate: ignorePrOrIssue, isLoading: ignorePrOrIssueLoading } =
    useIgnorePrOrIssue();
  const { setSelected, setActive } = useDetailsPageStore();

  useEffect(() => {
    if (inView) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  }, [inView]);

  const handlePrIgnoreOrIssue = (id: string) => {
    ignorePrOrIssue(
      {
        id,
        type: [
          ...PR_CHART_KEYS,
          ...GOAL_METRIC_GIT_KEYS,
          ...COMMIT_CHART_KEYS,
          ...WORK_BREAKDOWN_CHARTS,
        ].includes(currentChartMeta?.chartKey)
          ? 'ignoreGit'
          : [...ISSUE_CHART_KEYS, ...GOAL_METRIC_JIRA_KEYS].includes(
              currentChartMeta?.chartKey
            )
          ? 'ignoreIssue'
          : '',
      },
      {
        onSuccess: () => {
          newToast({
            message: 'The entry you ignored has been successfully processed.',
            status: 'success',
          });
          queryClient.invalidateQueries([querykey]);
          queryClient.invalidateQueries([currentChartMeta.chartKey]);
        },
        onError: () => {
          newToast({
            message:
              'There was an error processing your request. Please try again.',
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      currentChartMeta={currentChartMeta}
      startDate={startDate}
      endDate={endDate}
      filterBy={filterBy}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      SideDrawerBodyHeight={[
        ...PR_CHART_KEYS,
        ...PR_AGEING_CHARTS,
        ...PR_HEALTH_INDEX_CHARTS,
        ...LOC_VS_PR_CYCLE_TIME_CHARTS,
        ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
        ...ISSUE_CHART_KEYS,
        ...ISSUE_AGE_CHART_KEYS,
        ...ISSUE_LIFECYCLE_DIST_CHARTS,
        ...REQUIREMENT_STABILITY_CHARTS,
      ].includes(currentChartMeta.chartKey)}
      sideBarSubHeading={
        <>
          <Text
            fontFamily="heading"
            fontSize="sm"
            color={'text.secondary'}
            fontWeight={'medium'}
          >
            {subHeading}
          </Text>

          {defaultData &&
            defaultData.data &&
            defaultData?.data[0]?.readings && (
              <Flex>
                {defaultData?.data[0]?.readings?.map((item: any) => (
                  <Badge
                    dotColor={item.color}
                    text={
                      item.name &&
                      item.name.charAt(0).toUpperCase() + item.name.slice(1)
                    }
                  />
                ))}
              </Flex>
            )}

          {[
            ...PR_CHART_KEYS,
            ...PR_AGEING_CHARTS,
            ...PR_HEALTH_INDEX_CHARTS,
            ...LOC_VS_PR_CYCLE_TIME_CHARTS,
            ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
          ].includes(currentChartMeta.chartKey) &&
            !isFetching && (
              <Button
                onClick={() => {
                  const state: NavigationState = {
                    heading,
                    currentChartMeta: JSON.parse(
                      JSON.stringify(currentChartMeta)
                    ),
                    filterBy: JSON.parse(JSON.stringify(filterBy)),
                    payload: JSON.parse(JSON.stringify(payload)),
                  };

                  setSelected({
                    id: 6,
                    startDate: startDate as string,
                    endDate: endDate as string,
                    duration: 'Custom',
                    sprintId: '',
                  });
                  setActive('Custom');
                  navigate('/pr-details', { state });
                }}
                variant={'primary'}
                maxW={'fit-content'}
                mt={4}
              >
                More Details
              </Button>
            )}
          {[
            ...ISSUE_CHART_KEYS,
            ...ISSUE_AGE_CHART_KEYS,
            ...ISSUE_LIFECYCLE_DIST_CHARTS,
            ...REQUIREMENT_STABILITY_CHARTS,
          ].includes(currentChartMeta.chartKey) &&
            !isFetching && (
              <Button
                onClick={() => {
                  const state: NavigationState = {
                    heading,
                    currentChartMeta: JSON.parse(
                      JSON.stringify(currentChartMeta)
                    ),
                    filterBy: JSON.parse(JSON.stringify(filterBy)),
                    payload: JSON.parse(JSON.stringify(payload)),
                  };

                  startDate &&
                    endDate &&
                    setSelected({
                      id: 6,
                      startDate: startDate as string,
                      endDate: endDate as string,
                      duration: 'Custom',
                      sprintId: '',
                    });
                  setActive('Custom');
                  navigate('/issue-details', { state });
                }}
                variant={'primary'}
                maxW={'fit-content'}
                mt={4}
              >
                More Details
              </Button>
            )}
        </>
      }
      content={
        <Stack spacing={1}>
          {isFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {defaultData?.data?.map((item: any, idx: number, arr: any) => (
            <Fragment key={item?.id || idx}>
              <Box p={2}>
                <Stack spacing={1}>
                  <Flex justifyContent={'space-between'}>
                    <Box>
                      {item?.updatedAt ? (
                        <Text
                          fontFamily="heading"
                          fontSize="xs"
                          color="text.secondary"
                          fontWeight="semibold"
                        >
                          {moment(item?.updatedAt).format('lll')}
                        </Text>
                      ) : (
                        item?.date && (
                          <Text
                            fontFamily="heading"
                            fontSize="xs"
                            color="text.secondary"
                            fontWeight="semibold"
                          >
                            {moment(item?.date).format('lll')}
                          </Text>
                        )
                      )}
                      {/meeting/i.test(heading) ? (
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          color="gray.700"
                          fontWeight="semibold"
                        >
                          {item?.title}
                        </Text>
                      ) : (
                        <Link
                          to={`/timeline/${
                            [...CFR_CHART_KEYS].includes(
                              currentChartMeta?.chartKey
                            )
                              ? 'cfr'
                              : [
                                  ...PR_CHART_KEYS,
                                  ...GOAL_METRIC_GIT_KEYS,
                                  ...COMMIT_CHART_KEYS,
                                  ...PR_AGEING_CHARTS,
                                  ...PR_HEALTH_INDEX_CHARTS,
                                  ...REVIEW_RESPONSIVENESS_INDEX_CHARTS,
                                  ...WORK_BREAKDOWN_CHARTS,
                                  ...LOC_VS_PR_CYCLE_TIME_CHARTS,
                                ].includes(currentChartMeta?.chartKey)
                              ? 'pr'
                              : [
                                  ...ISSUE_CHART_KEYS,
                                  ...GOAL_METRIC_JIRA_KEYS,
                                  ...ISSUE_LIFECYCLE_DIST_CHARTS,
                                ].includes(currentChartMeta?.chartKey)
                              ? 'issue'
                              : [...DEPLOY_CHART_KEYS].includes(
                                  currentChartMeta?.chartKey
                                )
                              ? 'build'
                              : 'issue'
                          }/${item?.id || item.commitId}`}
                        >
                          <Text
                            fontSize="md"
                            fontFamily="heading"
                            textDecoration="underline"
                            color="gray.700"
                            fontWeight="medium"
                          >
                            {item?.title}
                          </Text>
                        </Link>
                      )}

                      {item?.author && (
                        <Flex align="center">
                          <Avatar
                            size="xs"
                            bg="text.secondary"
                            opacity={0.8}
                            name={item?.author?.toUpperCase()?.charAt(0)}
                          />

                          <Link to={`/contributors/${item?.author}`}>
                            <Text
                              fontSize="sm"
                              fontFamily="heading"
                              color="text.secondary"
                              fontWeight="medium"
                              ml={1}
                              decoration={'underline'}
                            >
                              {item?.author}
                            </Text>
                          </Link>
                        </Flex>
                      )}
                    </Box>

                    {[
                      ...PR_CHART_KEYS,
                      ...GOAL_METRIC_GIT_KEYS,
                      ...COMMIT_CHART_KEYS,
                      ...ISSUE_CHART_KEYS,
                      ...GOAL_METRIC_JIRA_KEYS,
                      ...WORK_BREAKDOWN_CHARTS,
                    ].includes(currentChartMeta?.chartKey) && (
                      <Tooltip
                        hasArrow
                        placement="bottom-end"
                        label={`Clicking this icon will cease tracking for this entry. Are you sure?`}
                      >
                        <IconButton
                          icon={<IgnoreIcon color={'#718096'} />}
                          size={'sm'}
                          onClick={() =>
                            handlePrIgnoreOrIssue(item.id || item.commitId)
                          }
                          variant={'solid'}
                          colorScheme={colors.secondary}
                          isDisabled={ignorePrOrIssueLoading}
                          aria-label={'Ignore PR'}
                          mr={2}
                          bgColor={'white'}
                          p={2}
                          borderRadius={'lg'}
                        />
                      </Tooltip>
                    )}
                  </Flex>

                  <Flex gap={6}>
                    {item.mergeTime && (
                      <KeyValueLine
                        mr={0}
                        pairKey={'Merge Time'}
                        value={item?.mergeTime}
                      />
                    )}
                    {item.pickupTime && (
                      <KeyValueLine
                        mr={0}
                        pairKey={'Pickup Time'}
                        value={item?.pickupTime}
                      />
                    )}
                  </Flex>
                  <Flex flexWrap="wrap" gap={2}>
                    {(item?.newwork || item?.newwork === 0) && (
                      <KeyValueLine
                        mr={0}
                        pairKey={'New Work Lines'}
                        value={item?.newwork}
                      />
                    )}

                    {(item?.refactor || item?.refactor === 0) && (
                      <KeyValueLine
                        mr={0}
                        pairKey={'Refactor Lines'}
                        value={item?.refactor}
                      />
                    )}

                    {(item?.rework || item?.rework === 0) && (
                      <KeyValueLine
                        mr={0}
                        pairKey={'Rework Lines'}
                        value={item?.rework}
                      />
                    )}

                    {(item?.helped || item.helped === 0) && (
                      <KeyValueLine
                        mr={0}
                        pairKey={'Helped Others Lines'}
                        value={item?.helped}
                      />
                    )}
                  </Flex>

                  {/* For meeting data */}
                  {/meeting/i.test(heading) && (
                    <Flex flexWrap="wrap" gap={2}>
                      {item?.startTime && (
                        <KeyValueLine
                          mr={0}
                          pairKey={'Start Time'}
                          value={moment(item?.startTime).format('lll')}
                        />
                      )}

                      {item?.endTime && (
                        <KeyValueLine
                          mr={0}
                          pairKey={'End Time'}
                          value={moment(item?.endTime).format('lll')}
                        />
                      )}

                      {item?.duration && (
                        <KeyValueLine
                          mr={0}
                          pairKey={'Duration'}
                          value={splitTime(item?.duration / 60)}
                        />
                      )}

                      {item?.organizer && (
                        <KeyValueLine
                          mr={0}
                          pairKey={'Organizer'}
                          value={item?.organizer}
                        />
                      )}

                      {item?.calendarOwner && (
                        <KeyValueLine
                          mr={0}
                          pairKey={'Calendar Owner'}
                          value={item?.calendarOwner}
                        />
                      )}

                      {item?.attendees && (
                        <KeyValueLine
                          mr={0}
                          pairKey={'Attendees'}
                          value={item?.attendees
                            .map((attendee: string) => attendee)
                            .join(', ')}
                        />
                      )}
                    </Flex>
                  )}

                  <Flex direction={'column'}>
                    {item?.id && (
                      <KeyValueLine pairKey={'ID'} value={item?.id} />
                    )}
                    {item?.pipelineName && (
                      <KeyValueLine
                        pairKey={'Pipeline Name'}
                        value={item?.pipelineName}
                      />
                    )}
                    {item?.repo && (
                      <KeyValueLine
                        pairKey={'Repositories'}
                        value={item?.repo}
                      />
                    )}
                    {item?.artifact && (
                      <KeyValueLine
                        pairKey={'Artifact'}
                        value={item?.artifact}
                      />
                    )}
                    {item?.buildTime && (
                      <KeyValueLine
                        pairKey={'Build Time'}
                        value={item?.buildTime}
                      />
                    )}
                    {item?.commit && (
                      <KeyValueLine
                        pairKey={'Commit ID'}
                        value={item?.commit}
                      />
                    )}
                    {item?.environment && (
                      <KeyValueLine
                        pairKey={'Environment'}
                        value={item?.environment}
                      />
                    )}
                    {item?.project && (
                      <KeyValueLine pairKey={'Project'} value={item?.project} />
                    )}
                    {item?.status && (
                      <KeyValueLine pairKey={'Status'} value={item?.status} />
                    )}
                    {item?.type && (
                      <KeyValueLine pairKey={'Type'} value={item?.type} />
                    )}
                  </Flex>

                  <Flex align="center" flexWrap="wrap">
                    {item?.attributes?.map((att: any, idx: number) => {
                      if (att?.key === 'Additions') {
                        return (
                          <KeyValueLine
                            key={att?.key + idx}
                            pairKey={'Code Changes'}
                            content={
                              <Flex ml={1} gap={1}>
                                <Tooltip
                                  hasArrow
                                  placement="bottom-start"
                                  label={'Additions'}
                                >
                                  <Text
                                    color="success"
                                    fontFamily="heading"
                                    fontSize="sm"
                                    fontWeight="medium"
                                  >{`+${
                                    item?.attributes.find(
                                      (attr: any) => attr.key === 'Additions'
                                    )?.value === 'null'
                                      ? '0'
                                      : item?.attributes.find(
                                          (attr: any) =>
                                            attr.key === 'Additions'
                                        )?.value
                                  }`}</Text>
                                </Tooltip>
                                <Tooltip
                                  hasArrow
                                  placement="bottom-end"
                                  label="Deletions"
                                >
                                  <Text
                                    color="error"
                                    fontFamily="heading"
                                    fontSize="sm"
                                    fontWeight="medium"
                                  >{`-${
                                    item?.attributes.find(
                                      (attr: any) => attr.key === 'Deletions'
                                    )?.value === 'null'
                                      ? '0'
                                      : item?.attributes.find(
                                          (attr: any) =>
                                            attr.key === 'Deletions'
                                        )?.value
                                  }`}</Text>
                                </Tooltip>
                              </Flex>
                            }
                          />
                        );
                      }

                      return (
                        att?.value &&
                        att?.key !== 'Deletions' && (
                          <KeyValueLine
                            key={att?.key + idx}
                            pairKey={att?.key}
                            value={
                              att?.key === 'Merged At' ||
                              att?.key === 'Created At' ||
                              att?.key === 'Updated At' ||
                              att?.key === 'Dated At'
                                ? moment(att?.value)?.format('lll')
                                : att?.key === 'Reviewers'
                                ? att?.value?.replace(/[{}]/g, '')
                                : att?.value
                            }
                          />
                        )
                      );
                    })}
                  </Flex>

                  <Flex>
                    {item.readings && item.readings?.length > 0 && (
                      <ProgressMultiColor unset readings={item.readings} />
                    )}
                  </Flex>
                </Stack>
              </Box>
              {idx !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}
          {hasNextPage || isFetchingNextPage ? (
            <Flex height="20px" justify="center" align="center">
              <Spinner />{' '}
              <Text
                ml={2}
                fontFamily="heading"
                color="text.secondary3"
                fontSize="sm"
              >
                Loading more data
              </Text>
            </Flex>
          ) : defaultData?.data?.length > 8 ? (
            <Flex height="20px" justify="center" align="center">
              <Text fontFamily="heading" color="text.secondary3" fontSize="sm">
                No more data.
              </Text>
            </Flex>
          ) : (
            <></>
          )}

          <Button ref={ref} visibility={'hidden'}>
            hidden
          </Button>
        </Stack>
      }
    />
  );
};

export default DefaultSideBar;
