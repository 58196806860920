import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { SplitSidebarType } from '..';

import LeftNav from './left-nav/left-nav';
import MetricsNav from './metrics-nav/metrics-nav';
import SettingsNav from './settings-nav/settings-nav';

interface AppSidebarProps {
  splitSidebar: SplitSidebarType;
  appState: any;
}

const AppSidebar: FC<AppSidebarProps> = ({ splitSidebar, appState }) => {
  return (
    <Flex w="fit-content" h="100%">
      <LeftNav appState={appState} isCondensed={splitSidebar === 'none'} />
      {splitSidebar === 'settings' ? (
        <SettingsNav appState={appState} />
      ) : splitSidebar === 'metrics' ? (
        <MetricsNav />
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default AppSidebar;
