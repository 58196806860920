import styled from 'styled-components';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';

import { useEffect } from 'react';
import SubsPlanModal from './SubsPlanModal';

import { useNavigate } from 'react-router';
import { AppIcon, LogoutIcon } from '../../icons';
import { AiOutlineLink } from 'react-icons/ai';
import { useToastHook } from '../../appToast';
import TeamInviteModal from './InviteMember/TeamInviteModal';
import NotificationDropdown from './NotificationDropdown';
import TrialEndModal from '../../TrialEndModal';
import DemoCalendarModal from '../../DemoCalendarModal';
import { useShownTrialEndModal } from './api';
import HelpIconButton from './HelpIconButton';
import SearchComponent from '../../SearchComponent';
import { useQueryClient } from '@tanstack/react-query';

const MenuDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #edebe9;
  margin-top: 16px;
  margin-bottom: 10px;
`;

declare global {
  interface Window {
    analytics: any;
  }
}

interface AppHeaderProps {
  appState: any;
  handleLogout: () => void;
}

export function AppHeader({ appState, handleLogout }: AppHeaderProps) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const { mutate: handleTrialEndModal } = useShownTrialEndModal();

  const {
    colors: { text },
  } = theme;
  const upgradeModal = useDisclosure();
  const teamInviteMember = useDisclosure();

  const trialEndModal = useDisclosure();
  const demoCalendarModal = useDisclosure();

  const signOut = () => {
    handleLogout();
    queryClient.clear();
  };
  const remainingDays = appState?.user?.dto?.remainingDays;
  const paidPlanActive = appState?.user?.dto?.paidPlanActive;
  const plan = appState?.user?.dto?.plan;
  const displayUpgrade = appState?.user?.dto?.displayUpgrade;
  const activeUsers = appState?.user?.dto?.activeUsers;

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    if (!appState) return;
    window.analytics.group(appState?.user?.dto?.org_id, {
      name: appState?.user?.dto?.org_name,
      plan: appState?.user?.dto?.plan,
      orgId: appState?.user?.dto?.org_id,
    });
  }, []);

  useEffect(() => {
    if (displayUpgrade) {
      trialEndModal.onOpen();
    }
  }, [displayUpgrade]);

  const url = document.location.href;

  const copyUrl = () => {
    navigator.clipboard.writeText(url).then(function () {
      newToast({
        status: 'success',
        message: `Copied ${url} to clipboard!`,
        position: 'top-center',
      });
    });
  };

  return (
    <Flex
      h="48px"
      w="full"
      align="center"
      p={4}
      zIndex={99}
      bg="white"
      borderBottom="1px solid #E3E6EA"
    >
      <Flex align="center" w="full">
        <Box cursor="pointer" onClick={() => navigate('/dashboard')}>
          <AppIcon height="28px" style={{ marginLeft: '-14px' }} />
        </Box>

        <Divider
          alignSelf={'center'}
          style={{
            height: '2vh',
            width: '1px',
            backgroundColor: theme['colors'].text.secondary,
          }}
          orientation="vertical"
        />
        <Text
          ml={4}
          fontFamily={'heading'}
          color={'text.secondary'}
          fontWeight={'medium'}
          fontSize={'sm'}
        >
          {localStorage.getItem('orgName')}
        </Text>

        {(plan === 'Free' || plan === 'Trial') && (
          <>
            {!paidPlanActive && (
              <>
                {remainingDays === null ? (
                  <Tag ml={4} alignSelf={'center'} colorScheme="gray">
                    <Box
                      as={'span'}
                      onClick={upgradeModal.onOpen}
                      textDecoration="underline"
                      cursor="pointer"
                    >
                      <Text
                        color="text.primary"
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="normal"
                      >
                        Upgrade Now
                      </Text>
                    </Box>
                  </Tag>
                ) : remainingDays > 0 ? (
                  <Tag ml={4} alignSelf={'center'} colorScheme="gray">
                    <Text
                      color="error"
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="normal"
                      userSelect={'none'}
                    >
                      Trial Expires in {remainingDays} days &nbsp;
                    </Text>

                    <Box
                      as={'span'}
                      onClick={upgradeModal.onOpen}
                      textDecoration="underline"
                      cursor="pointer"
                    >
                      <Text
                        color="text.primary"
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="normal"
                      >
                        Upgrade Now
                      </Text>
                    </Box>
                  </Tag>
                ) : remainingDays <= 0 ? (
                  <Tag ml={4} alignSelf={'center'} colorScheme="gray">
                    <Text
                      color="error"
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="normal"
                      userSelect={'none'}
                    >
                      Trial Expired &nbsp;
                    </Text>
                    <Box
                      as={'span'}
                      onClick={upgradeModal.onOpen}
                      textDecoration="underline"
                      cursor="pointer"
                    >
                      <Text
                        color="text.primary"
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="normal"
                      >
                        Upgrade Now
                      </Text>
                    </Box>
                  </Tag>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </Flex>

      <SearchComponent />

      <NotificationDropdown />

      <HelpIconButton
        salesButtonAction={() => {
          demoCalendarModal.onOpen();
        }}
      />

      {localStorage.getItem('role') !== 'EMPLOYEE' && (
        <Button
          variant="primary"
          size="sm"
          mx={4}
          px={4}
          onClick={() => {
            if (
              localStorage.getItem('userId') ===
              'a867b5e7-ee22-4a9f-becd-b8f3aa43f753'
            ) {
              newToast({
                message: "You don't have access.",
                status: 'error',
              });
            } else {
              teamInviteMember.onOpen();
            }
          }}
        >
          Invite
        </Button>
      )}

      {teamInviteMember.isOpen && (
        <TeamInviteModal
          isOpen={teamInviteMember.isOpen}
          onClose={teamInviteMember.onClose}
        />
      )}

      <Flex mr={2}>
        <Box alignSelf={'center'}>
          <Menu>
            <MenuButton
              alignSelf={'center'}
              as={IconButton}
              rounded={'full'}
              variant={'ghost'}
              _hover={{ backgroundColor: 'transparent', color: 'blue.500' }}
              _active={{ backgroundColor: 'transparent', color: 'blue.500' }}
              cursor={'pointer'}
            >
              <Avatar
                size={'sm'}
                name={appState?.user?.dto?.firstName?.charAt(0)}
                backgroundColor="#E3E6EA"
                color="text.secondary"
                fontWeight="semibold"
                fontSize="xs"
                lineHeight="16px"
                fontFamily="body"
              />
            </MenuButton>
            <MenuList boxShadow="0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)">
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'left'}
                p={4}
                pb={0}
              >
                <Avatar
                  size={'md'}
                  name={appState?.user?.dto?.firstName?.charAt(0)}
                  backgroundColor="#E3E6EA"
                  color="text.secondary"
                  fontWeight="semibold"
                  fontSize="md"
                  lineHeight="19px"
                  fontFamily="heading"
                />
                <Box>
                  <Text
                    fontSize="xs"
                    fontFamily="heading"
                    lineHeight="16px"
                    fontWeight="normal"
                    color="text.secondary"
                    mt={1}
                  >
                    {appState?.user?.email}
                  </Text>

                  <Text
                    fontWeight="semibold"
                    fontSize="lg"
                    fontFamily="heading"
                    lineHeight="20px"
                    color="text.primary"
                    mt={1}
                  >
                    {appState?.user?.dto?.name}
                  </Text>
                </Box>
                <MenuDivider />
              </Box>

              {/* <MenuItem>View Profile</MenuItem>
              <MenuItem>Help</MenuItem> */}
              <MenuItem onClick={signOut}>
                <Text
                  as="p"
                  pl={2}
                  color="text.secondary"
                  fontSize="md"
                  fontWeight="normal"
                  fontFamily="heading"
                  display="flex"
                  alignItems="center"
                >
                  <LogoutIcon
                    color={text.secondary}
                    height="14px"
                    width="14px"
                    style={{ marginRight: '4px' }}
                  />
                  {`Logout`}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>

      {upgradeModal.isOpen && (
        <SubsPlanModal
          isOpen={upgradeModal.isOpen}
          onClose={upgradeModal.onClose}
        />
      )}

      {trialEndModal.isOpen && (
        <TrialEndModal
          isOpen={trialEndModal.isOpen}
          onClose={() => {
            handleTrialEndModal({
              id: appState?.user?.dto?.id,
              displayed: false,
            });
            trialEndModal.onClose();
          }}
          salesButtonAction={() => {
            trialEndModal.onClose();
            demoCalendarModal.onOpen();
          }}
          upgradeNowBtnAction={() => {
            trialEndModal.onClose();
            navigate('/settings/subscription-billing');
            handleTrialEndModal({
              id: appState?.user?.dto?.id,
              displayed: false,
            });
          }}
          displayUpgrade={displayUpgrade}
          activeUsers={activeUsers}
        />
      )}
      {demoCalendarModal.isOpen && (
        <DemoCalendarModal
          isOpen={demoCalendarModal.isOpen}
          showBackBtn
          onClose={() => {
            handleTrialEndModal({
              id: appState?.user?.dto?.id,
              displayed: false,
            });
            demoCalendarModal.onClose();
          }}
          onBackAction={() => {
            demoCalendarModal.onClose();
            trialEndModal.onOpen();
          }}
        />
      )}
    </Flex>
  );
}

export default AppHeader;
