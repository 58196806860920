import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  nonTeamMembers: 'nonTeamMembers',
  teamMembers: 'teamMembers',
  teams: 'teams',
  parentTeams: 'parentTeams',
  teamDetails: 'teamDetails',
  gitProjects: 'gitProjects',
  jiraProjects: 'jiraProjects',
  issueEpics: 'issueEpics',
  boardNames: 'boardNames',
  incidentManagement: 'incidentManagement',
  updateTeam: 'updateTeam',
  teamHomeData: 'teamHomeData',
  doraSettings: 'doraSettings',
  qualityProjects: 'qualityProjects',
};

export const useTeamMembersToSelect = (team: string) => {
  return useQuery(
    [QUERY_KEYS.nonTeamMembers, team],
    () => ENDPOINTS.getTeamMemberToSelect(team),
    {
      enabled: !!team,
    }
  );
};

export const useNonTeamMembers = (role: string, team: string) => {
  return useQuery(
    [QUERY_KEYS.nonTeamMembers, role, team],
    () => ENDPOINTS.getNonTeamMember(role, team),
    {
      enabled: !!role && !!team,
    }
  );
};

export const useTeamMembers = (team: string) => {
  return useQuery(
    [QUERY_KEYS.teamMembers],
    () => ENDPOINTS.getTeamMembers(team),
    {
      enabled: !!team,
    }
  );
};

export const useUpdateTeamMemberRole = () =>
  useCustomMutation([QUERY_KEYS.teamMembers], ENDPOINTS.updateTeamMemberRole);

export const useTeamHomeDetails = () => {
  return useQuery([QUERY_KEYS.teamHomeData], ENDPOINTS.getTeamHomeData);
};

export const useAddMembers = () => {
  return useCustomMutation([QUERY_KEYS.teamMembers], ENDPOINTS.addMembers);
};

export const useDeleteMembers = () => {
  return useCustomMutation([QUERY_KEYS.teamMembers], ENDPOINTS.deleteMembers);
};

export const useFetchTeams = (team: string) =>
  useQuery([QUERY_KEYS.teams, team], () => ENDPOINTS.getTeams(team));

export const useFetchParentTeams = (teamNot: string) =>
  useQuery([QUERY_KEYS.parentTeams, teamNot], () =>
    ENDPOINTS.getParentTeams(teamNot)
  );

export const useFetchTeamData = (team: string) =>
  useQuery(
    [QUERY_KEYS.teamDetails, team],
    () => ENDPOINTS.getDataByTeams(team),
    {
      enabled: !!team,
    }
  );

export const useFetchAllGitProjects = () =>
  useQuery([QUERY_KEYS.gitProjects], ENDPOINTS.getAllGitRepos);

export const useFetchAllJiraProjects = () =>
  useQuery([QUERY_KEYS.jiraProjects], ENDPOINTS.getAllJiraProjects);

export const useFetchAllIssueEpic = () =>
  useQuery([QUERY_KEYS.issueEpics], ENDPOINTS.getAllIssueEpic);

export const useFetchAllBoardNames = () =>
  useQuery([QUERY_KEYS.boardNames], ENDPOINTS.getAllBoardNames);

export const useFetchAllIncidentManagement = () =>
  useQuery([QUERY_KEYS.incidentManagement], ENDPOINTS.getAllIncidentManagement);

export const usePostTeam = () =>
  useCustomMutation([QUERY_KEYS.updateTeam], ENDPOINTS.addOrUpdateTeam);

export const useUpdateTeam = () =>
  useCustomMutation([QUERY_KEYS.updateTeam], ENDPOINTS.updateTeam);

export const useFetchDoraSettings = () => {
  return useQuery([QUERY_KEYS.doraSettings], ENDPOINTS.getDoraSettings);
};

export const useFetchQualityProjects = () => {
  return useQuery(
    [QUERY_KEYS.qualityProjects],
    ENDPOINTS.getAllQualityProjectsRepos
  );
};
