import { Box, Flex, Icon, Text, useTheme } from '@chakra-ui/react';
import { IconLabelContainer, Label, StyledLeftNav } from './metrics-nav.styled';
import { IconType } from 'react-icons/lib';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable-next-line */
export interface MetricsNavProps {}

interface MetricsNavItemProps {
  id: string;
  label: string;
  icon?: IconType;
  isSelected?: boolean;
  bottom?: boolean;
  onClick: (id: string) => void;
  path: string;
}

function MetricsNavItem(props: MetricsNavItemProps) {
  const { id, icon, label, isSelected, onClick, bottom = false, path } = props;
  const theme = useTheme();

  return (
    <Link to={path} onClick={() => onClick(id)}>
      <Box
        bgColor={isSelected ? theme['colors'].primaryBg : '#FFFFFF'}
        cursor={'pointer'}
        userSelect={'none'}
        mb={2}
      >
        <Flex>
          <Box
            width={1}
            height={'auto'}
            bgColor={isSelected ? 'primary' : '#FFFFFF'}
          />
          <IconLabelContainer>
            {icon && <Icon as={icon} color={isSelected ? 'primary' : ''} />}
            <Label
              color={
                isSelected
                  ? theme['colors'].primary
                  : theme['colors'].text.secondary
              }
            >
              {label}
            </Label>
          </IconLabelContainer>
        </Flex>
      </Box>
    </Link>
  );
}

const NavItems = [
  {
    id: 'Favourite',
    label: 'Favourites',
    path: 'favourite',
  },
  {
    id: 'Productivity',
    label: 'Productivity',
    path: 'productivity',
  },
  {
    id: 'Velocity',
    label: 'Velocity',
    path: 'velocity',
  },
  {
    id: 'Quality',
    label: 'Quality',
    path: 'quality',
  },
  {
    id: 'Dora',
    label: 'DORA',
    path: 'dora',
  },
  {
    id: 'Throughput',
    label: 'Throughput',
    path: 'throughput',
  },
  {
    id: 'Cycle Time',
    label: 'Cycle Time',
    path: 'cycle-time',
  },
  {
    id: 'Flow',
    label: 'Flow',
    path: 'flow',
  },
  {
    id: 'Wellbeing',
    label: 'Well-being',
    path: 'wellbeing',
  },
];

export function MetricsNav(props: MetricsNavProps) {
  const { pathname } = useLocation();
  const currentNav = pathname.split('/').pop();

  const currentNavId = currentNav
    ? NavItems.find((item) => item.path === currentNav)?.id
    : NavItems[0].id;

  const [selectedNavItem, setSelectedNavItem] = useState<any>();

  useEffect(() => {
    setSelectedNavItem(currentNavId ? currentNavId : NavItems[0].id);
  }, [currentNavId]);

  const onNavItemSelect = (id: string): void => {
    const item = NavItems.filter((item) => item.id === id)[0];

    setSelectedNavItem(id);
  };

  const renderNavItems = () => {
    const userRole = localStorage.getItem('role');
    return NavItems.filter((item) => {
      if (userRole === 'EMPLOYEE' && item.id === 'Wellbeing') {
        return false;
      }
      return true;
    }).map((item) => (
      <MetricsNavItem
        key={item.id}
        isSelected={selectedNavItem === item.id}
        onClick={onNavItemSelect}
        {...item}
      />
    ));
  };

  return (
    <StyledLeftNav>
      <Box>
        <Text
          fontSize={'sm'}
          fontWeight={'medium'}
          fontFamily="heading"
          color="text.primary"
          userSelect={'none'}
          letterSpacing={'0.1px'}
          m={5}
        >
          Metrics
        </Text>
        {renderNavItems()}
      </Box>
    </StyledLeftNav>
  );
}

export default MetricsNav;
