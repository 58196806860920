import styled from 'styled-components';

export type LabelProps = {
  color: string;
};

export const Label = styled.div<LabelProps>`
  color: ${(props) => props.color};
  font-size: 0.75rem;
  font-weight: 500;
  font-family: 'Inter';
  align-self: center;
`;

export const IconLabelContainer = styled.div`
  display: flex;
  padding: 9px 16px;
  align-items: center;
`;

export const StyledLeftNav = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;
