import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import { useNavigate } from 'react-router';
import './chart.css';
import { useCallback, useState } from 'react';
import {
  ACTIVE_DAYS_CHARTS,
  ISSUE_CHART_KEYS,
  REQUIREMENT_STABILITY_CHARTS,
  splitTime,
} from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';

interface BarChartRechartsProps {
  data: any;
  keys?: any;
  chartMetadata?: any;
  slant?: boolean;
  granularSlant?: boolean;
  showLabel?: boolean;
  selectedTeam?: any;
  repos?: string[];
  selected: any;
  type?: string;
  hideBtn?: boolean;
  showYLabel?: boolean;
}

export const BarChartRecharts = ({
  data,
  keys,
  chartMetadata,
  slant,
  granularSlant,
  showLabel,
  selectedTeam,
  repos,
  selected,
  type,
  hideBtn,
  showYLabel,
}: BarChartRechartsProps) => {
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={'#0095E6'}
          fontSize={7.6}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  let name = '';
  if (clickedData?.name === 'Total Issues') name = 'totalIssues';
  else if (clickedData?.name === 'Reopened Issues') name = 'reopenedIssues';
  else if (clickedData?.name === 'Backward Issues') name = 'backwardIssues';

  const foundData = data.find(
    (d: any) => d?.startDate === clickedData?.payload?.startDate
  );
  const count = foundData?.[name];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 35,
            left: showLabel || showYLabel ? 0 : slant ? 108 : -5,
            bottom: slant ? 52 : 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={chartMetadata.xAxis}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: 8,
              fontWeight: 500,
              cursor: 'pointer',
            }}
            angle={granularSlant || slant ? -60 : undefined}
            interval={granularSlant || slant ? 0 : undefined}
            textAnchor={granularSlant || slant ? 'end' : 'middle'}
            onClick={handleClick}
            tick={slant ? renderCustomTick : undefined}
          />
          <YAxis
            tickFormatter={(e) =>
              chartMetadata.ylabel === 'Hour'
                ? splitTime(e)
                : chartMetadata.dataType === 'TIME'
                ? splitTime(e / 60)
                : e
            }
            label={
              !showYLabel
                ? {}
                : {
                    value: chartMetadata.ylabel,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 11,
                    fontWeight: 400,
                    dy: 45,
                  }
            }
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 400, marginTop: 10 }}
          />

          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.ylabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                hideBtn={hideBtn}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
          />
          {showLabel && (
            <Legend
              verticalAlign="middle"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: '20%',
                transform: 'translateY(-50%)',
              }}
            />
          )}

          {keys?.map((el: any) => (
            <Bar
              key={el.key}
              dataKey={el.key}
              name={el.name}
              barSize={20}
              fill={el.color}
              style={{ cursor: 'pointer' }}
              onClick={(event: any, payload: any) => {
                handleModalOpen();

                setCurrentChartMeta(chartMetadata);
                setClickedData({
                  payload: {
                    startDate: event.startDate,
                    endDate: event.endDate,
                  },
                  name: el.name,
                });
              }}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {[...ACTIVE_DAYS_CHARTS, ...ISSUE_CHART_KEYS].includes(
        currentChartMeta?.chartKey
      ) &&
        isOpen && (
          <MetricSidebar
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={
              currentChartMeta?.chartKey === REQUIREMENT_STABILITY_CHARTS[0]
                ? ``
                : `${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.startDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.startDate).format('DD MMM')
                  } - ${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.endDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.endDate).format('DD MMM')
                  }${
                    ISSUE_CHART_KEYS?.includes(currentChartMeta?.chartKey) &&
                    count
                      ? `, ${
                          clickedData?.name !== undefined
                            ? clickedData?.name
                            : ''
                        } ${count && `(${count})`}`
                      : ''
                  } `
            }
            payload={clickedData}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              projectsFilter: repos,
              sprintId: selected.sprintId,
              type: type,
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
