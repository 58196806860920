import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Switch,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import {
  AiStarIcon,
  CircleCheck,
  DateFilter,
  DownloadIcon,
  durationData,
  ErasorIcon,
  IconButtonContainer,
  InfoIcon,
  PageHeader,
  RefreshIcon,
  removeEmptyQueryParams,
  useDashboardStore,
  useMetricsListData,
  useQueryState,
  useTeamComparisonStore,
} from '@devd-client/devd/components';
import TeamCompHeatMap from '../components/TeamCompHeatMap';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { defaultMetrics } from '../helpers/team-details.utils';
import { useFetchMetricList } from '../api';
import { useNavigate } from 'react-router';
import ColorScheme from '../assets/colorScale.jpg';
import { useTeamDetailsStore } from '../store/teamDetail';

export const TeamComparison = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [appState, dispatch] = useAuth();
  const { selected, setSelected } = useDashboardStore();
  const { triggerApiCall } = useTeamDetailsStore();
  const { data: metricsList, isLoading } = useFetchMetricList();

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const { isChecked, toggleSwitch, setSelectedTeams, setSelectedMetrics } =
    useTeamComparisonStore();

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }
  }, [selected]);

  useEffect(() => {
    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  const handleUndo = () => {
    const allTeam = { value: 'all', label: 'All Teams' };
    setSelectedMetrics(
      metricsList?.dto?.filter((el: any) => defaultMetrics.includes(el.name))
    );
    setSelectedTeams([allTeam]);

    if (isChecked === false) {
      toggleSwitch();
    }
  };

  return (
    <Flex w="full" direction="column" gap={4}>
      <Flex direction="column">
        <PageHeader
          headingText="Team Comparison"
          // component={
          //   <Flex gap={2}>
          //     <IconButtonContainer withGradient>
          //       <AiStarIcon />
          //     </IconButtonContainer>
          //     <IconButtonContainer>
          //       <DownloadIcon />
          //     </IconButtonContainer>
          //   </Flex>
          // }
        />
        <Box>
          <Button variant="link" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
      </Flex>

      <Flex mt={4} align="flex-end" justify={'space-between'} w="full">
        <Flex gap={2} height="40px" align="center">
          <DateFilter
            getUser={getUser}
            appState={appState}
            dispatch={dispatch}
            AuthActionTypes={AuthActionTypes}
          />

          <Tooltip hasArrow label="Set to Default">
            <IconButton
              bg="transparent"
              border="1px solid #E0E0E0"
              borderRadius="4px"
              aria-label="circle"
              onClick={handleUndo}
              icon={<CircleCheck />}
            />
          </Tooltip>
          <Tooltip hasArrow label="Refresh">
            <IconButton
              bg="transparent"
              border="1px solid #E0E0E0"
              borderRadius="4px"
              aria-label="circle"
              onClick={triggerApiCall}
              icon={<RefreshIcon />}
            />
          </Tooltip>
        </Flex>

        <Flex height="60px" width="280px">
          <Image src={ColorScheme} alt="color scheme" />
        </Flex>

        <Flex align="center">
          <Text
            fontFamily={'heading'}
            fontSize="sm"
            fontWeight={'medium'}
            color="text.secondary2"
            mr={1}
          >
            Compare to Org
          </Text>
          <Box mr={2}>
            <Tooltip
              label="This allows you to either compare your selected Teams with each other or Compare Teams with organisation averages"
              hasArrow
              placement="bottom-end"
            >
              <Box>
                <InfoIcon />
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <Switch isChecked={isChecked} onChange={toggleSwitch} />
          </Box>
        </Flex>
      </Flex>

      <Box>
        <TeamCompHeatMap
          isChecked={isChecked}
          metricsList={metricsList}
          isLoading={isLoading}
        />
      </Box>
    </Flex>
  );
};

export default TeamComparison;
