import { useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

type StatusType =
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'loading'
  | undefined;

interface IToast {
  message: string;
  status: StatusType;
  position?: string;
  title?: string;
}

export const useToastHook = (): [(toastData: IToast) => void] => {
  const [state, setState] = useState<any>(undefined);
  const toast = useToast();

  useEffect(() => {
    if (state) {
      toast({
        title: state?.title,
        description: state?.message,
        status: state?.status,
        duration: 2000,
        position: state?.position ?? 'top-right',
        isClosable: false,
        variant: 'subtle',
      });
    }
  }, [state, toast]);

  return [setState];
};
