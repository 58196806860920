import React from 'react';
import styled from 'styled-components';
import IntegrationCards from './IntegrationCards';

const IntegrationOptionsContainer = styled.div`
  margin-top: 16px;
`;

const HeadingText = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const SubText = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 20px;
  margin-top: 4px;
`;

const IntegrationOptions = () => {
  return (
    <IntegrationOptionsContainer>
      <HeadingText>Lets get started!</HeadingText>
      {/* <SubText>Lin under</SubText> */}

      <IntegrationCards />
    </IntegrationOptionsContainer>
  );
};

export default IntegrationOptions;
