import React, { FC } from 'react';
import { Box, Flex, IconButton, useTheme } from '@chakra-ui/react';
import {
  ComponentWrapper,
  Heading,
  LeftSection,
  PageHeaderContainer,
  RightSection,
  SubHeading,
  HeadingWrapper,
} from './PageHeader.styled';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { isHTML } from '../shared/utils';
import ReactHtmlParser from 'html-react-parser';
import { BiHelpCircle } from 'react-icons/bi';

interface PageHeaderProps {
  component?: React.ReactNode;
  headingText: string;
  subHeadingText?: string;
  helpDocLink?: string;
  mt?: string;
  mb?: string;
  showBackBtn?: boolean;
  backBtnAction?: () => void;
}

export const PageHeader: FC<PageHeaderProps> = ({
  component,
  subHeadingText,
  headingText,
  helpDocLink,
  mb,
  mt,
  showBackBtn,
  backBtnAction,
}) => {
  const {
    colors: { text },
  } = useTheme();

  const handleHelpIconClick = () => {
    window.open(
      helpDocLink,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=270,left=800,width=700,height=600'
    );
  };

  return (
    <PageHeaderContainer mt={mt} mb={mb}>
      <LeftSection>
        {showBackBtn && (
          <IconButton
            bg="transparent"
            aria-label="back"
            size="xs"
            isRound
            mr={2}
            icon={<MdOutlineKeyboardBackspace size={20} color="#777" />}
            onClick={backBtnAction}
          />
        )}

        <HeadingWrapper>
          <Flex alignItems={'center'}>
            {headingText && (
              <Heading color={text.primary}>{headingText}</Heading>
            )}
            {helpDocLink && (
              <Box
                as={BiHelpCircle}
                onClick={handleHelpIconClick}
                _hover={{ cursor: 'pointer', color: 'primary' }}
                transition="color 0.3s"
                lineHeight={'28px'}
                fontSize={'xl'}
                ml={'2px'}
              />
            )}
          </Flex>
          {subHeadingText && isHTML(subHeadingText) ? (
            <Box w="50vw">{ReactHtmlParser(subHeadingText)}</Box>
          ) : (
            <SubHeading>{subHeadingText}</SubHeading>
          )}
        </HeadingWrapper>
      </LeftSection>
      <RightSection>
        {component && <ComponentWrapper>{component}</ComponentWrapper>}
      </RightSection>
    </PageHeaderContainer>
  );
};
