import { usePagination, DOTS } from './usePagination';
import {
  NextLabel,
  PaginationContainer,
  PaginationItem,
  PrevLabel,
} from './Paginate.styled';
import { Fragment } from 'react';

interface PaginateProps {
  onPageChange: (page: number | string) => void;
  totalPageCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  nextLabel?: string;
  previousLabel?: string;
}

export const Paginate = (props: PaginateProps) => {
  const {
    onPageChange,
    totalPageCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    nextLabel,
    previousLabel,
  } = props;

  const paginationRange = usePagination(
    totalPageCount,
    pageSize,
    siblingCount,
    currentPage
  );

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationContainer>
      <PaginationItem disabled={currentPage === 1} onClick={onPrevious}>
        <PrevLabel>{previousLabel || '〈 Previous'}</PrevLabel>
      </PaginationItem>
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationItem dots key={idx}>
              &#8230;
            </PaginationItem>
          );
        }

        return (
          <PaginationItem
            key={idx}
            selected={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            <span>{pageNumber}</span>
          </PaginationItem>
        );
      })}
      <PaginationItem disabled={currentPage === lastPage} onClick={onNext}>
        <NextLabel>{nextLabel || 'Next 〉'}</NextLabel>
      </PaginationItem>
    </PaginationContainer>
  );
};
