import { FC } from 'react';
import styled from 'styled-components';
import TasksTable from './TasksTable';
import TasksToolbar from './TasksToolbar';

interface TasksProps {
  selectedProjectId: string;
}

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tasks: FC<TasksProps> = ({ selectedProjectId }) => {
  return (
    <TasksContainer>
      <TasksToolbar />
      <TasksTable selectedProjectId={selectedProjectId} />
    </TasksContainer>
  );
};

export default Tasks;
