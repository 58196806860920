import { Box } from '@chakra-ui/react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { DataGrid, Paginate } from '@devd-client/devd/components';
import { InitiativeContext } from '../../../../contexts/initiativesContext';
import styled from 'styled-components';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { useTaskListGql } from '../../../../graphql';

const AnchorLink = styled.a`
  text-decoration: underline;
  color: #0095e6;
`;

interface TasksTable {
  selectedProjectId: string;
}

const TasksTable: FC<TasksTable> = ({ selectedProjectId }) => {
  const {
    state: { taskList },
    dispatch,
  } = useContext(InitiativeContext);
  const [currentTaskListPage, setCurrentLastListPage] = useState<number>(0);

  const { isFetching: taskLoading } = useTaskListGql(
    selectedProjectId,
    currentTaskListPage,
    20,
    dispatch
  );

  const rows: any[] = useMemo(() => taskList?.data || [], [taskList?.data]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('key', {
        cell: (info) => (
          <AnchorLink
            href={info.row.original.ticketUrl}
            target="_blank"
            rel="noreferrer"
          >
            {info.getValue()}
          </AnchorLink>
        ),
        header: 'Name',
      }),
      columnHelper.accessor('summary', {
        cell: (info) => info.getValue(),
        header: 'Summary',
        size: 300,
      }),
      columnHelper.accessor('assignee', {
        cell: (info) => info.getValue(),
        header: 'Assignee',
      }),
      columnHelper.accessor('status', {
        cell: (info) => info.getValue(),

        header: 'Status',
        size: 80,
      }),

      columnHelper.accessor('ticketType', {
        cell: (info) => info.getValue(),
        header: 'Type',
        size: 80,
      }),

      columnHelper.accessor('updatedDate', {
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('YYYY-MM-DD'),
        header: 'Updated Date',
        size: 100,
      }),
      columnHelper.accessor('investment', {
        cell: (info) => info.getValue(),
        header: 'Investment',
      }),
    ],
    [taskList?.data]
  );

  const handleCurrentTaskListPage = useCallback(
    (page: number) => {
      setCurrentLastListPage(page - 1);
    },
    [currentTaskListPage]
  );

  return (
    <>
      <DataGrid
        showLoader={taskLoading}
        columns={columns}
        data={rows}
        maxH="26rem"
        sticky="firstCol"
      />
      <Box marginLeft="-20px">
        <Paginate
          currentPage={taskList?.currentPage + 1}
          totalPageCount={taskList?.totalPages}
          pageSize={10}
          onPageChange={(page) => handleCurrentTaskListPage(page as number)}
        />
      </Box>
    </>
  );
};

export default TasksTable;
