export function AlertTriangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="alert-triangle">
        <path
          id="Vector"
          d="M5.14501 1.93L0.91001 9C0.822694 9.15121 0.776494 9.32265 0.776005 9.49726C0.775516 9.67187 0.820756 9.84356 0.907224 9.99526C0.993692 10.147 1.11837 10.2734 1.26886 10.3619C1.41936 10.4505 1.59041 10.4981 1.76501 10.5H10.235C10.4096 10.4981 10.5807 10.4505 10.7312 10.3619C10.8816 10.2734 11.0063 10.147 11.0928 9.99526C11.1793 9.84356 11.2245 9.67187 11.224 9.49726C11.2235 9.32265 11.1773 9.15121 11.09 9L6.85501 1.93C6.76588 1.78305 6.64037 1.66156 6.49061 1.57724C6.34085 1.49292 6.17188 1.44862 6.00001 1.44862C5.82814 1.44862 5.65918 1.49292 5.50941 1.57724C5.35965 1.66156 5.23415 1.78305 5.14501 1.93Z"
          stroke={props.color || '#FF5230'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M6 4.5V6.5"
          stroke={props.color || '#FF5230'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M6 8.5H6.005"
          stroke={props.color || '#FF5230'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default AlertTriangle;
