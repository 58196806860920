import {
  Box,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  AppTooltip,
  CFR_CHART_KEYS,
  COMMIT_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  CUSTOM_CHART_KEYS,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  DataGrid,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  MEETING_CHARTS,
  MEMBER_CHART_KEYS,
  MetricSidebar,
  PR_CHART_KEYS,
  Paginate,
  PeriodType,
  QUALITY_CHART_KEYS,
  splitTime,
  useDebounce,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTeamDetailsStore } from '../../store/teamDetail';
import { QUERY_KEYS, useFetchTeamsGql } from '../../graphql';
import CycleTimeItem from './CycleTimeItem';
import { convertToTimeFormat } from '../../helpers/team-details.utils';
import moment from 'moment';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #0095e6;
`;

type ListViewProps = {
  searchTerm: string;
  selectedTeam: any;
  setTeamLength: any;
  selected: PeriodType;
};

const ListView: React.FC<ListViewProps> = ({
  searchTerm,
  selectedTeam,
  setTeamLength,
  selected,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [searchBarParams, setSearchBarParams] = useSearchParams();
  const searchParam = searchBarParams.get('search');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [teamId, setTeamId] = useState<string>('');
  const [metricsName, setMetricsName] = useState<string>('');
  const [heading, setHeading] = useState<string>('');
  const [count, setCount] = useState<string | number>(0);

  const { isFetching, data, isLoading, fetchNextPage, hasNextPage } =
    useFetchTeamsGql(
      '',
      selected.startDate,
      selected.endDate,
      selected?.sprintId,
      selectedTeam.value,
      5,
      debouncedSearchTerm
    );

  const item: any = data;

  const itemMeta = item?.itemMeta;

  useEffect(() => {
    searchParam && setSearch(searchParam);
  }, []);

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const rows: any[] = useMemo(() => data?.pages || [], [data?.pages]);

  useEffect(() => {
    setTeamLength(rows.length);
  }, [rows, setTeamLength]);

  useEffect(() => {
    setCurrentPage(0);
  }, [
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    selectedTeam.value,
    searchTerm,
  ]);

  const columnHelper = createColumnHelper<any>();

  const isMetricType = itemMeta?.map((item: any) => item.metric);

  const columns: any = useMemo(
    () =>
      data?.pages && data?.pages[0]
        ? Object.keys(data?.pages[0])
            .filter(
              (el: any) =>
                el !== 'leadTimeForChange' &&
                el !== 'prCycleTimeBreakUp' &&
                el !== 'codeImpact'
            )
            .map((key: any, idx: any) => {
              return key === 'name'
                ? columnHelper.accessor('name', {
                    header: () => <Text>Team</Text>,

                    cell: (info) => {
                      return (
                        <StyledLink to={`/teams/${info.getValue()}`}>
                          {info.getValue()}
                        </StyledLink>
                      );
                    },
                  })
                : [
                    'gitActivity',
                    'issueActivity',
                    'meetingActivity',
                    'qualityActivity',
                    'doraActivity',
                  ].includes(key)
                ? columnHelper.accessor(key, {
                    header: () => (
                      <Box>
                        <Text>
                          {key === 'gitActivity'
                            ? 'Git Activity'
                            : key === 'issueActivity'
                            ? 'Issue Activity'
                            : key === 'qualityActivity'
                            ? 'Quality'
                            : key === 'doraActivity'
                            ? 'DORA'
                            : key === 'meetingActivity'
                            ? 'Meeting Activity'
                            : ''}
                        </Text>
                        {key === 'meetingActivity' ? (
                          <></>
                        ) : (
                          <Text fontSize="xxs" fontWeight="normal">
                            Count
                          </Text>
                        )}
                      </Box>
                    ),
                    enableSorting: false,

                    cell: (info) => (
                      <Stack spacing={1}>
                        {info.row.original?.[key]?.map(
                          (row: any, index: number) => (
                            <Flex key={index} align="center">
                              <Flex
                                align="center"
                                width={
                                  key === 'doraActivity' ||
                                  key === 'meetingActivity'
                                    ? '88px'
                                    : '48px'
                                }
                              >
                                <Box
                                  height="16px"
                                  width="2px"
                                  borderRadius="2px"
                                  bg={row.fillColor}
                                />
                                <Tooltip label="View Details">
                                  <Text
                                    ml={1.5}
                                    fontSize="xs"
                                    color="text.primary"
                                    textDecoration={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'underline'
                                        : 'none'
                                    }
                                    cursor={
                                      isMetricType.includes(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      )
                                        ? 'pointer'
                                        : 'default'
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setTeamId(info.row.original.name);
                                      setMetricsName(
                                        itemMeta.filter(
                                          (item: any) =>
                                            item.label === row.label
                                        )[0]?.metric
                                      );
                                      setHeading(row.label);

                                      if (
                                        row.label !== 'Lead Time For Change' ||
                                        row.label !== 'MTTR' ||
                                        row.label !== 'Total Meeting time'
                                      ) {
                                        setCount(row.count);
                                      }

                                      onOpen();
                                    }}
                                  >
                                    {row.label === 'Lead Time For Change' ||
                                    row.label === 'MTTR' ||
                                    row.label === 'Total Meeting time'
                                      ? splitTime(Number(row.count) / 60)
                                      : row.count}
                                  </Text>
                                </Tooltip>
                              </Flex>
                              <Text
                                minW="85px"
                                fontSize="xs"
                                fontFamily="heading"
                                fontWeight={'normal'}
                                color="text.secondary"
                                whiteSpace={'nowrap'}
                              >
                                {row?.label}
                              </Text>
                            </Flex>
                          )
                        )}
                      </Stack>
                    ),
                  })
                : isMetricType.includes(
                    itemMeta.filter((item: any) => item.key === key)[0]?.metric
                  )
                ? columnHelper.accessor(key, {
                    header: () => (
                      <Box display="flex" flexDirection="column">
                        <Text textAlign={'start'} w={'140%'}>
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.label
                          }
                        </Text>
                        <Text
                          fontSize="xxs"
                          fontWeight="normal"
                          textAlign={'start'}
                        >
                          {
                            itemMeta.filter((item: any) => item.key === key)[0]
                              ?.measurement
                          }
                        </Text>
                      </Box>
                    ),

                    cell: (info) =>
                      key === 'prCycleTime' ? (
                        <AppTooltip
                          content={
                            <Box textAlign={'start'}>
                              <Text
                                fontFamily="heading"
                                fontSize="medium"
                                color="text.primary"
                              >
                                PR cycle time
                              </Text>

                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                color="text.secondary"
                              >
                                {splitTime(Number(info.getValue()) / 60)}
                              </Text>
                              {/* <HStack mt={3} justify={'center'} spacing={4}>
                                <CycleTimeItem
                                  title="PICKUP"
                                  time={convertToTimeFormat(
                                    info.row.original?.prCycleTimeBreakUp
                                      ?.pickupCycleTime / 60
                                  )}
                                />
                                <CycleTimeItem
                                  title="MERGE"
                                  time={convertToTimeFormat(
                                    info.row.original?.prCycleTimeBreakUp
                                      ?.mergeCycleTime / 60
                                  )}
                                />
                              </HStack> */}
                            </Box>
                          }
                          trigger="hover"
                        >
                          <Text
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setTeamId(info.row.original?.name);
                              setMetricsName(
                                itemMeta.filter(
                                  (item: any) => item.key === key
                                )[0]?.metric
                              );
                              setHeading(
                                itemMeta.filter(
                                  (item: any) => item.key === key
                                )[0]?.label
                              );
                              onOpen();
                            }}
                            fontSize="sm"
                            textDecoration="none"
                            fontWeight="medium"
                            transition="text-decoration 0.2s ease-in-out"
                            _hover={{
                              textDecoration: 'underline',
                            }}
                            _focus={{
                              outline: 'none',
                              boxShadow: 'outline',
                            }}
                          >
                            {splitTime(Number(info.getValue()) / 60)}
                          </Text>
                        </AppTooltip>
                      ) : (
                        <Tooltip label="View details" aria-label="View details">
                          <Text
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setTeamId(info.row.original.name);
                              setMetricsName(
                                itemMeta.filter(
                                  (item: any) => item.key === key
                                )[0]?.metric
                              );
                              setHeading(
                                itemMeta.filter(
                                  (item: any) => item.key === key
                                )[0]?.label
                              );
                              setCount(info.getValue());
                              onOpen();
                            }}
                            fontSize="sm"
                            textDecoration="none"
                            transition="text-decoration 0.2s ease-in-out"
                            _hover={{
                              textDecoration: 'underline',
                            }}
                            _focus={{
                              outline: 'none',
                              boxShadow: 'outline',
                            }}
                            textAlign={'center'}
                          >
                            {info.getValue()}
                          </Text>
                        </Tooltip>
                      ),
                    size: 80,
                    meta: {
                      isNumeric: true,
                    },
                  })
                : columnHelper.accessor(key, {
                    header: () => <></>,

                    cell: () => null,
                  });
            })
        : [],
    [data?.pages]
  );

  return (
    <>
      <>
        <DataGrid
          showLoader={(isFetching && !data) || (isLoading && !data)}
          isExtraFetching={isFetching && data !== undefined}
          columns={columns}
          data={rows}
          maxH="30rem"
          sticky="firstCol"
          infiniteScroll
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          useMaxH
          useTdHeight
        />
        {/* <Box marginLeft="-20px">
          <Paginate
            currentPage={data?.currentPage + 1}
            totalPageCount={data?.totalPages}
            pageSize={10}
            onPageChange={(page) => handlePageClick(page as number)}
          />
        </Box> */}
      </>
      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...GOAL_METRIC_GIT_KEYS,
        ...GOAL_METRIC_JIRA_KEYS,
        ...COMMIT_CHART_KEYS,
        ...MEMBER_CHART_KEYS,
        ...CONTRIBUTOR_CHART_KEYS,
        ...CUSTOM_CHART_KEYS,
        ...DEPENDENCY_VULNERABILITIES_CHART_KEYS,
        ...QUALITY_CHART_KEYS,
        ...MEETING_CHARTS,
      ].includes(metricsName) &&
        isOpen && (
          <MetricSidebar
            querykey={QUERY_KEYS.teamList}
            openDrawer={isOpen}
            handleDrawerClose={onClose}
            heading={heading}
            subHeading={`${moment(selected.startDate).format(
              'DD MMM'
            )} - ${moment(selected.endDate).format('DD MMM')} ${
              heading !== 'Lead Time For Change' &&
              heading !== 'MTTR' &&
              heading !== 'Total Meeting time' &&
              heading !== 'PR Cycle Time'
                ? `(${count})`
                : ''
            }
           `}
            payload={{
              payload: {
                startDate: selected.startDate,
                endDate: selected.endDate,
              },
            }}
            currentChartMeta={{
              chartKey: metricsName === 'CUSTOM' ? heading : metricsName,
            }}
            filterBy={{
              teamId,
              sprintId: selected.sprintId,
            }}
            selectedTeam={teamId}
          />
        )}
    </>
  );
};
export default ListView;
