import styled from 'styled-components';

export const TooltipContainer = styled.div`
  padding: 10px;
  width: fit-content;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
  pointer-events: all;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
`;

export const StyledTooltipHeading = styled.p`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
`;

export const StyledToopTipText = styled.p`
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 14px;
  /* margin-bottom: 2px; */
`;

export const Button = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryBg};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 9px;
  padding: 4px 6px;
  font-weight: 500;
  color: #2c5282;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

export const Unit = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 11px;
`;
