import { BiBookmarkMinus } from 'react-icons/bi';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Skeleton,
  Spinner,
  Text,
  Image,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import {
  AiFillStar,
  AiOutlineEdit,
  AiOutlineInfoCircle,
  AiOutlineStar,
} from 'react-icons/ai';
import { useChartsGql, useSizeBreakdownGql } from './graphql';
import Chart from '../charts/Chart';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ChartBox,
  ChartNameWrapper,
  Count,
  DetailBox,
  IconWrapper,
  Label,
  MetricCardContainer,
  MetricLabelWrapper,
  Name,
  TagLabel,
  TagLabels,
  Value,
} from './metricsCard.styled';
import {
  useAddFavouriteMutation,
  useDeleteFavTeamMutation,
  useDeleteFavouriteMutation,
  useTeamFavMetricsMutation,
} from './apis';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../appToast';
import {
  convertHexToRGBA,
  eliminateHtmlTags,
  ISSUE_CHART_KEYS,
  splitTime,
} from '../shared/utils';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { convertToSingleDecimal } from './helpers/metricCard.utils';
import { MdArrowForwardIos } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import AppTooltip from '../appTooltip';
import { Link, useSearchParams } from 'react-router-dom';
import { AlertTriangle, EliteIcon, StarIcon, Thumsup } from '../icons';
import ViewSwitcher from '../viewSwitcher';
import { filterOptions, viewSwitchObj } from './metricsCard.utils';
import CustomSelect, { ISelect } from '../customSelect';
import { ChakraStylesConfig } from 'chakra-react-select';
import { useUpdateCustomDashboard } from '../addMetrics/api';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { RxReload } from 'react-icons/rx';
import { useCallback, useRef } from 'react';
import { toPng } from 'html-to-image';
import Logo from '../shared/assets/DD-Logo.svg';
import ImageDown from '../shared/assets/image-down.svg';
import { Loader } from '../appLoader';
import AILogo from '../shared/assets/ai-icon-circle.svg';
import AITooltipLogo from '../shared/assets/ai-tooltip-logo.svg';
import { SlideInfoDrawer } from '../infoSlidebar/SlideInfoDrawer';

interface MetricsCardProps {
  metricType: string;
  selected?: any;
  selectedTeam: string;
  status?: string;
  queryKey?: string;
  teamMetrics?: boolean;
  ticketType?: string;
  currentDashboardMetrics?: any;
  queryKeys?: any;
  isCustomDashboard?: boolean;
}

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

export const MetricsCard = ({
  metricType,
  selected,
  selectedTeam,
  status,
  queryKey,
  teamMetrics,
  ticketType,
  currentDashboardMetrics,
  queryKeys,
  isCustomDashboard,
}: MetricsCardProps) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const [viewToggle, setViewToggle] = useState('Week');
  const [breakdown, setBreakdown] = useState<ISelect>({
    value: '',
    label: '-',
  });
  const { mutate: updateDashboard } = useUpdateCustomDashboard();
  const [searchParam] = useSearchParams();
  const { mutate: handleRemoveMetric } = useDeleteFavTeamMutation();

  const {
    data,
    isLoading: initialLoading,
    refetch,
  } = useChartsGql(
    selected.startDate,
    selected.endDate,
    metricType,
    breakdown.value,
    selectedTeam,
    selected.sprintId,
    ticketType || '',
    viewToggle.toUpperCase()
  );

  const [isLoading, setIsLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(true);
  }, [openDrawer]);

  const handleDrawerClose = useCallback(
    () => setOpenDrawer(false),
    [openDrawer]
  );

  useEffect(() => {
    if (initialLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [initialLoading]);

  const handelReloadClick = async () => {
    setIsLoading(true);
    await refetch();
    setIsLoading(false);
  };

  const removeMetricFromDashboard = (metricTitle: any, metricKey: any) => {
    const prevMetrics = currentDashboardMetrics?.layout?.metrics || [];

    const updatedMetrics = prevMetrics.filter(
      (item: any) => item.metric !== metricTitle && item.metric !== metricKey
    );

    updateDashboard(
      {
        name: currentDashboardMetrics?.key?.name,
        orgId: localStorage.getItem('orgId'),
        scope: 'org',
        layout: {
          ...currentDashboardMetrics.layout,
          metrics: updatedMetrics,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys?.customDashboardsDetails]);
          newToast({
            status: 'success',
            message: `${metricTitle} removed from ${searchParam.get('id')}`,
          });
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message:
              err?.message ??
              `Some error in removing ${metricTitle} from ${searchParam.get(
                'id'
              )}`,
          });
        },
      }
    );
  };

  const removeMetricFromTeams = (metricTitle: any, metricKey: any) => {
    handleRemoveMetric(
      {
        team: selectedTeam,
        metric:
          metricKey === 'CUSTOM' ? encodeURIComponent(metricTitle) : metricKey,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['teamFavMetrics']);
          newToast({
            status: 'success',
            message: `Removed metric ${metricTitle}`,
          });
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message: 'Something went wrong. Please try again.',
          });
        },
      }
    );
  };

  const { data: sizeBreakdown, isLoading: sizeBreakdownLoading } =
    useSizeBreakdownGql(
      selected.startDate,
      selected.endDate,
      metricType,
      selectedTeam,
      selected.sprintId
    );

  useEffect(() => {
    if (selected.duration === 'Sprint') {
      setViewToggle('Day');
    } else {
      setViewToggle('Week');
    }
  }, [selected.duration]);

  const modifiedData = [
    'MEAN_TIME_TO_RECOVERY',
    'LEAD_TIME_FOR_CHANGE',
    'PR_CYCLE_TIME',
    'CODING_CYCLE_TIME',
    'PICKUP_CYCLE_TIME',
    'MERGE_CYCLE_TIME',
    'TASK_LEAD_TIME',
    'BUG_LEAD_TIME',
    'ISSUE_CYCLE_TIME',
    'MEETING_TIME',
    'CUSTOM',
  ].includes(data?.chartMetadata?.chartKey)
    ? {
        ...data,
        average:
          data?.average && data?.chartMetadata?.dataType == 'INTEGER'
            ? data?.average
            : data?.average && data?.chartMetadata?.dataType == 'TIME'
            ? splitTime(parseInt(data?.average) / 60)
            : data?.average && data?.chartMetadata?.dataType == 'PERCENTAGE'
            ? data?.average
            : data?.average && splitTime(parseInt(data?.average) / 60),
        chartMetadata: {
          ...data?.chartMetadata,
          ylabel:
            data?.chartMetadata?.chartKey === 'CUSTOM'
              ? data?.chartMetadata.ylabel
              : 'Hour',
        },
        data: data?.data?.map((item: any) => ({
          ...item,
          y:
            data?.chartMetadata?.chartKey === 'CUSTOM'
              ? Number(item.y)
              : Number(item.y) / 60,
        })),
        keys: data?.keys?.map((key: any) =>
          key.name === 'Minutes' ? { ...key, name: 'Hours' } : key
        ),
      }
    : data;

  const { mutate: addFavouriteMutation, isLoading: deleteMutationLoading } =
    useAddFavouriteMutation();
  const { mutate: deleteFavouriteMutation, isLoading: addMutationLoading } =
    useDeleteFavouriteMutation();
  const { mutate: deleteFavTeamMutation, isLoading: deleteFavTeam } =
    useDeleteFavTeamMutation();

  const addFavorite = () => {
    addFavouriteMutation(
      { data: { name: [metricType] } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([metricType]);
          queryClient.invalidateQueries([queryKey]);
          newToast({
            status: 'success',
            message: 'Added to Favourites',
          });
        },
        onError: () => {
          newToast({
            status: 'error',
            message: 'Something went wrong. Please try again.',
          });
        },
      }
    );
  };
  const deleteFavorite = () => {
    deleteFavouriteMutation(
      { data: { name: [metricType] } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([metricType]);
          queryClient.invalidateQueries([queryKey]);
          newToast({
            status: 'success',
            message: 'Removed from Favourites',
          });
        },
        onError: () => {
          newToast({
            status: 'error',
            message: 'Something went wrong. Please try again.',
          });
        },
      }
    );
  };

  const handleHelpIconClick = () => {
    window.open(
      data?.chartMetadata?.helpDoc,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=270,left=800,width=700,height=600'
    );
  };

  const [show, setShow] = useState(true);

  const ref = useRef<HTMLDivElement>(null);
  const [mergedImage, setMergedImage] = useState<string | null>(null);
  const [imageDownloadLoading, setImageDownloadLoading] = useState(false);

  const downloadChart = useCallback(
    (chartName: string) => {
      if (ref.current === null) {
        return;
      }
      setImageDownloadLoading(true);

      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const img = document.createElement('img') as HTMLImageElement;
          img.src = dataUrl;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) return;
            canvas.width = img.width;
            canvas.height = img.height;

            context.drawImage(img, 0, 0);
            const logoImg = document.createElement('img') as HTMLImageElement;
            logoImg.src = Logo;
            logoImg.onload = () => {
              const logoWidth = logoImg.width / 4;
              const logoHeight = logoImg.height / 4;
              const logoX = canvas.width - logoWidth - 60;
              const logoY = 140;

              context.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);

              const mergedDataUrl = canvas.toDataURL('image/png');
              setMergedImage(mergedDataUrl);

              const link = document.createElement('a');
              link.download = `${chartName}.png`;
              link.href = mergedDataUrl;
              link.click();
              setImageDownloadLoading(false);
              setShow(true);
            };
          };
        })
        .catch((err) => {
          setShow(true);
          setImageDownloadLoading(false);
          newToast({
            message: 'Something went wrong while downloading.',
            status: 'error',
          });
        });
    },
    [ref]
  );

  if (modifiedData?.chartDataState === 'ERROR') return null;

  return (
    <Box>
      {isLoading ||
      addMutationLoading ||
      deleteMutationLoading ||
      sizeBreakdownLoading ||
      deleteFavTeam ? (
        <Box position={'relative'}>
          <Skeleton minWidth={'350px'} minHeight={'400px'} />
          <Spinner position={'absolute'} top={'45%'} left={'47%'} />
        </Box>
      ) : (
        <MetricCardContainer ref={ref}>
          <DetailBox>
            <Box>
              <Flex justify="space-between" align="center">
                <Flex>
                  {modifiedData?.performance && (
                    <Tooltip hasArrow label={modifiedData?.reason}>
                      <Box mr={2}>
                        {modifiedData?.performance === 'Elite' && (
                          <TagLabel>
                            <Box height="full" mt={-1}>
                              <EliteIcon
                                color="Green"
                                height="18px"
                                width="18px"
                              />
                            </Box>

                            <Text fontFamily={'heading'} ml={1}>
                              {modifiedData?.performance}
                            </Text>
                          </TagLabel>
                        )}
                        {modifiedData?.performance === 'High' && (
                          <TagLabel
                            color={convertHexToRGBA('#0095E6' || '', 0.1)}
                          >
                            <Box height="full" mt={-1}>
                              <StarIcon
                                width="14px"
                                height="14px"
                                color="#0095E6"
                              />
                            </Box>

                            <Text color="#0095E6" fontFamily={'heading'} ml={1}>
                              {modifiedData?.performance}
                            </Text>
                          </TagLabel>
                        )}
                        {modifiedData?.performance === 'Medium' && (
                          <TagLabel
                            color={convertHexToRGBA('#FF8D70' || '', 0.1)}
                          >
                            <Box height="full" mt={-1}>
                              <Thumsup
                                height="14px"
                                width="14px"
                                color={'#FF8D70'}
                              />
                            </Box>

                            <Text
                              color={'#FF8D70'}
                              fontFamily={'heading'}
                              ml={1}
                            >
                              {modifiedData?.performance}
                            </Text>
                          </TagLabel>
                        )}
                        {modifiedData?.performance === 'Low' && (
                          <TagLabel
                            color={convertHexToRGBA('FF0000' || '', 0.1)}
                          >
                            <Box height="full" mt={-1}>
                              <AlertTriangle
                                height="14px"
                                width="14px"
                                color="#FF0000"
                              />
                            </Box>

                            <Text color={'red'} fontFamily={'heading'} ml={1}>
                              {modifiedData?.performance}
                            </Text>
                          </TagLabel>
                        )}
                      </Box>
                    </Tooltip>
                  )}
                  {modifiedData?.tags && modifiedData?.tags?.length >= 1 && (
                    <TagLabels>
                      {modifiedData.tags.map((item: string, idx: number) => (
                        <TagLabel key={idx}>{item}</TagLabel>
                      ))}
                    </TagLabels>
                  )}
                </Flex>

                <Flex align="flex-end">
                  {modifiedData?.chartDataState === 'NOT_CONFIGURED' && (
                    <AppTooltip
                      trigger="hover"
                      content={
                        <Flex>
                          <Text
                            fontFamily="heading"
                            fontSize="sm"
                            color="text.primary"
                            mr={2}
                          >
                            {modifiedData?.chartError?.message}
                          </Text>
                          <Link to={`${modifiedData?.chartError?.link}`}>
                            <Text
                              color="primary"
                              fontFamily="heading"
                              fontSize="sm"
                              textDecoration={'underline'}
                            >
                              Configure
                            </Text>
                          </Link>
                        </Flex>
                      }
                    >
                      <Box>
                        <Button
                          size={'sm'}
                          color={'error'}
                          border="1px"
                          borderColor={'error'}
                          backgroundColor={'white'}
                          fontFamily="heading"
                        >
                          {'why no data?'}
                        </Button>
                      </Box>
                    </AppTooltip>
                  )}

                  <Flex align="flex-end">
                    {data?.chartMetadata?.showBreakdown &&
                      !data?.chartError && (
                        <>
                          <Box ml={2}>
                            <Text
                              fontFamily={'heading'}
                              fontWeight={'semibold'}
                              color={'grey'}
                              w={'150px'}
                            >
                              Breakdown by
                            </Text>
                            <CustomSelect
                              // label="Breakdown by"
                              size="xs"
                              width="100px"
                              chakraStyles={chakraStyles}
                              defaultItem={breakdown}
                              onChange={(selected: string) =>
                                setBreakdown({
                                  value: eliminateHtmlTags(selected),
                                  label:
                                    eliminateHtmlTags(selected) === ''
                                      ? '-'
                                      : eliminateHtmlTags(selected),
                                })
                              }
                              options={
                                ISSUE_CHART_KEYS?.includes(metricType)
                                  ? filterOptions
                                  : filterOptions.filter(
                                      (item) => item.value !== 'PRIORITY'
                                    )
                              }
                            />
                          </Box>
                          <Box
                            height="25px"
                            width="1.5px"
                            bg="gray.300"
                            ml={2}
                          />
                        </>
                      )}

                    {!data?.chartError && (
                      <Box mx={2}>
                        <ViewSwitcher
                          tabList={viewSwitchObj}
                          currentTab={viewToggle}
                          handleSwitch={setViewToggle}
                          height="25px"
                        />
                      </Box>
                    )}

                    {(modifiedData?.data?.length > 0 || teamMetrics) && (
                      <>
                        {teamMetrics && (
                          <Box>
                            <Tooltip
                              hasArrow
                              aria-label="filled-star"
                              label="Remove"
                            >
                              <IconButton
                                isRound
                                size="sm"
                                alignContent={'end'}
                                bg="none"
                                aria-label="circle info"
                                icon={
                                  <BiBookmarkMinus
                                    size={'18px'}
                                    color={colors.text.secondary}
                                  />
                                }
                                onClick={() =>
                                  removeMetricFromTeams(
                                    data?.chartMetadata?.chartTitle,
                                    data?.chartMetadata?.chartKey
                                  )
                                }
                              />
                            </Tooltip>
                          </Box>
                        )}
                      </>
                    )}
                  </Flex>
                  {modifiedData?.data?.length > 0 && (
                    <Tooltip
                      hasArrow
                      aria-label="view-details"
                      label="View Detail"
                    >
                      <IconButton
                        isRound
                        size="sm"
                        alignContent={'end'}
                        bg="none"
                        aria-label="circle info"
                        icon={
                          <BsArrowRight
                            size={'18px'}
                            color={colors.text.secondary}
                          />
                        }
                        onClick={() =>
                          navigate({
                            search: new URLSearchParams({
                              ...Object.fromEntries(
                                new URLSearchParams(window.location.search)
                              ),
                              type: metricType,
                              view: viewToggle,
                            }).toString(),
                          })
                        }
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>

              {status && (
                <Text
                  fontSize="sm"
                  fontFamily="heading"
                  fontWeight="normal"
                  color="text.primary"
                >
                  {status}
                </Text>
              )}
            </Box>

            <Box>
              {modifiedData?.chartMetadata?.chartTitle && (
                <ChartNameWrapper
                  mt={modifiedData?.relatedMetrics ? '16px' : '16px'}
                >
                  <Name>{modifiedData?.chartMetadata?.chartTitle}</Name>
                  {modifiedData?.chartMetadata?.helpDoc ? (
                    <Box
                      as={AiOutlineInfoCircle}
                      onClick={handleHelpIconClick}
                      _hover={{ cursor: 'pointer', color: 'primary' }}
                      transition="color 0.3s"
                      color={colors.text.primary}
                      fontSize={14}
                    />
                  ) : (
                    modifiedData?.chartMetadata?.chartKey === 'CUSTOM' && (
                      <Tooltip
                        hasArrow
                        label={modifiedData?.chartMetadata?.info}
                        aria-label="A tooltip"
                      >
                        <IconWrapper>
                          <IconButton
                            isRound
                            size="xs"
                            alignContent={'end'}
                            bg="none"
                            cursor="default "
                            aria-label="circle info"
                            icon={
                              <AiOutlineInfoCircle
                                color={colors.text.primary}
                                fontSize={16}
                              />
                            }
                          />
                        </IconWrapper>
                      </Tooltip>
                    )
                  )}

                  {modifiedData?.chartMetadata?.chartKey !== 'CUSTOM' && (
                    <AppTooltip
                      content={
                        <Text
                          fontSize="sm"
                          color="text.secondary"
                          fontWeight="normal"
                          fontFamily="heading"
                        >
                          {modifiedData?.chartMetadata?.info}{' '}
                          <Box
                            as="span"
                            display="inline-flex"
                            gap={1}
                            alignItems="center"
                          >
                            <Text
                              as="span"
                              color="blue"
                              bg="none"
                              fontSize="14"
                              display="inline"
                              borderBottom="1px solid blue"
                              fontWeight={400}
                              onClick={() => handleDrawerOpen()}
                              cursor={'pointer'}
                            >
                              Learn More by
                            </Text>
                            <Image src={AILogo} h={5} w={5} />
                          </Box>
                        </Text>
                      }
                      placement="bottom"
                      trigger="hover"
                    >
                      <IconButton
                        aria-label="ticket/open-ai-assistant"
                        icon={<Image src={AITooltipLogo} w={10} />}
                        _hover={{
                          transform: 'scale(1.1)',
                          transition: 'transform 0.2s ease-in-out',
                        }}
                        variant="unstyled"
                        size={'xs'}
                        ml={1}
                      />
                    </AppTooltip>
                  )}

                  <Flex ml={'auto'} alignItems={'center'}>
                    {modifiedData?.chartMetadata?.isFavMetric === true ? (
                      <Box ml={'auto'}>
                        <Tooltip
                          hasArrow
                          aria-label="filled-star"
                          label="Remove from Favourites"
                        >
                          <IconButton
                            isRound
                            size="xs"
                            alignContent={'end'}
                            bg="none"
                            aria-label="circle info"
                            icon={
                              <AiFillStar
                                size={'18px'}
                                color={colors.text.secondary}
                              />
                            }
                            onClick={deleteFavorite}
                          />
                        </Tooltip>
                      </Box>
                    ) : modifiedData?.chartMetadata?.isFavMetric === false ? (
                      <Box ml={'auto'}>
                        <Tooltip
                          hasArrow
                          aria-label="empty-star"
                          label="Add to Favourites"
                        >
                          <IconButton
                            isRound
                            size="xs"
                            alignContent={'end'}
                            bg="none"
                            aria-label="circle info"
                            icon={
                              <AiOutlineStar
                                fontSize="18px"
                                color={colors.text.secondary}
                              />
                            }
                            onClick={addFavorite}
                          />
                        </Tooltip>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Tooltip
                      hasArrow
                      aria-label="reload-metric"
                      label="Reload Metric Data"
                    >
                      <IconButton
                        isRound
                        size="sm"
                        alignContent={'end'}
                        bg="none"
                        aria-label="circle info"
                        icon={<RxReload size={'16px'} />}
                        onClick={() => handelReloadClick()}
                      />
                    </Tooltip>
                    {isCustomDashboard && (
                      <>
                        {modifiedData?.chartMetadata?.chartKey === 'CUSTOM' && (
                          <Tooltip
                            hasArrow
                            aria-label="edit-metric"
                            label="Edit metric"
                          >
                            <IconButton
                              isRound
                              size="sm"
                              alignContent={'end'}
                              bg="none"
                              aria-label="circle info"
                              marginTop={
                                data?.chartMetadata?.showBreakdown === true
                                  ? '20px'
                                  : '0'
                              }
                              icon={<AiOutlineEdit size={'18px'} />}
                              onClick={() =>
                                navigate(
                                  `edit-metric?metric=${modifiedData?.chartMetadata?.chartTitle}`
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        <Tooltip
                          hasArrow
                          aria-label="remove-metric"
                          label="Remove metric"
                        >
                          <IconButton
                            isRound
                            size="sm"
                            alignContent={'end'}
                            bg="none"
                            aria-label="circle info"
                            icon={<MdOutlineDeleteOutline size={'18px'} />}
                            onClick={() =>
                              removeMetricFromDashboard(
                                data?.chartMetadata?.chartTitle,
                                data?.chartMetadata?.chartKey
                              )
                            }
                          />
                        </Tooltip>
                      </>
                    )}
                  </Flex>
                </ChartNameWrapper>
              )}

              {typeof modifiedData?.average !== 'undefined' &&
                modifiedData?.average !== null && (
                  <MetricLabelWrapper>
                    {typeof modifiedData?.average !== 'undefined' &&
                      modifiedData?.average !== null && (
                        <Count>
                          {String(modifiedData?.average).replace(' 0hr', '')}{' '}
                          {modifiedData?.chartMetadata?.dataType ===
                            'PERCENTAGE' && (
                            <Text fontSize={'18px'} display={'inline'}>
                              %
                            </Text>
                          )}
                        </Count>
                      )}
                    <Label>
                      {modifiedData?.chartMetadata?.dataType !== 'PERCENTAGE' &&
                        modifiedData?.averageLabel}
                    </Label>
                  </MetricLabelWrapper>
                )}

              {modifiedData?.threshold && (
                <MetricLabelWrapper>
                  <Count color={modifiedData?.threshold?.color}>
                    {String(
                      parseFloat(modifiedData?.threshold?.value).toFixed(1)
                    )}
                  </Count>

                  <Tooltip hasArrow label={modifiedData?.threshold?.desc}>
                    <Label>{modifiedData?.threshold?.title}</Label>
                  </Tooltip>
                </MetricLabelWrapper>
              )}

              {modifiedData?.data?.length <= 0 && (
                <p
                  style={{
                    color: '#B10707',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                >
                  No Data
                </p>
              )}

              {typeof modifiedData?.previousPeriodStat?.percentChange !==
                'undefined' &&
              modifiedData?.previousPeriodStat?.percentChange !== null ? (
                <Tooltip
                  label={
                    modifiedData?.previousPeriodStat?.reason
                      ? `Reason: ${modifiedData?.previousPeriodStat?.reason}`
                      : 'Current average compared to average of last period'
                  }
                  placement="bottom"
                  hasArrow
                  background={'gray.700'}
                  color={'white'}
                >
                  <Flex width="fit-content" align="center" minW={'max-content'}>
                    {modifiedData?.previousPeriodStat?.percentChange !== 0 ? (
                      <>
                        {modifiedData?.previousPeriodStat?.percentChange > 0 ? (
                          <IoMdArrowDropup
                            color={
                              modifiedData?.previousPeriodStat?.improved
                                ? colors.success
                                : colors.error
                            }
                            fontSize={'24px'}
                          />
                        ) : (
                          <IoMdArrowDropdown
                            color={
                              modifiedData?.previousPeriodStat?.improved
                                ? colors.success
                                : colors.error
                            }
                            fontSize={'24px'}
                          />
                        )}
                        <Value
                          color={
                            modifiedData?.previousPeriodStat?.improved
                              ? colors.success
                              : colors.error
                          }
                        >
                          {/* {modifiedData?.previousPeriodStat?.percentChange > 0
                            ? '+'
                            : ''} */}
                          {Math.abs(
                            modifiedData?.previousPeriodStat?.percentChange
                          )}
                          %
                        </Value>
                      </>
                    ) : (
                      <Value color={colors.text.secondary}>{``}</Value>
                    )}
                  </Flex>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          </DetailBox>

          <ChartBox>
            {!isLoading && show && (
              <Tooltip label="Download Chart" hasArrow>
                <Button
                  variant={'unstyled'}
                  size={'xs'}
                  mx={2}
                  position={'absolute'}
                  right={5}
                  top={-10}
                  zIndex={99}
                  onClick={() => {
                    setShow(false);
                    downloadChart(
                      modifiedData?.chartMetadata?.chartTitle ?? 'Chart'
                    );
                  }}
                >
                  <Image w={5} h={5} src={ImageDown} />
                </Button>
              </Tooltip>
            )}
            <Chart
              data={modifiedData?.data}
              keys={modifiedData?.keys}
              chartMetadata={modifiedData?.chartMetadata}
              showLabel={
                ['BACKWARD_MOMENTUM', 'REQUIREMENT_STABILITY'].includes(
                  modifiedData?.chartMetadata?.chartKey
                )
                  ? true
                  : false
              }
              showYLabel={true}
              selectedTeam={selectedTeam}
              ticketType={ticketType}
              selected={selected}
              queryKey={queryKey}
            />
          </ChartBox>

          {sizeBreakdown?.title &&
            sizeBreakdown?.breakdowns &&
            sizeBreakdown?.breakdowns?.length >= 1 && (
              <Flex direction={'column'} w="full">
                <Box w="full" height="0.5px" bg="rgba(0,0,0,0.1)" my={4} />
                <Flex align="center" flexWrap="wrap" mb={3}>
                  <Text
                    fontFamily={'heading'}
                    fontSize="sm"
                    color="text.primary"
                    fontWeight="medium"
                    mr={1}
                  >
                    {sizeBreakdown?.title}
                  </Text>
                  <Tooltip
                    hasArrow
                    label={sizeBreakdown?.subTitle}
                    aria-label="A tooltip"
                  >
                    <Box>
                      <AiOutlineInfoCircle
                        color={colors.text.primary}
                        fontSize={14}
                      />
                    </Box>
                  </Tooltip>
                </Flex>

                <Flex
                  w="full"
                  align="center"
                  gap={{ base: 1, md: 3 }}
                  justify="space-between"
                >
                  {sizeBreakdown?.breakdowns?.map(
                    (item: any, idx: number, arr: any) => (
                      <Fragment key={idx}>
                        <Box w="fit-content">
                          <Flex align="center" direction="column" gap={1}>
                            <Tooltip
                              placement={
                                arr?.length - 1 !== idx ? 'top' : 'top-end'
                              }
                              hasArrow
                              aria-label="subtitle"
                              label={item?.subTitle}
                            >
                              <Text
                                fontFamily="heading"
                                fontWeight="normal"
                                color="gray.700"
                                fontSize="11.5px"
                                whiteSpace={'nowrap'}
                                padding="4px 12px"
                                border="1px solid"
                                borderColor="gray.300"
                                borderRadius="999px"
                              >
                                {item?.title}
                              </Text>
                            </Tooltip>
                          </Flex>

                          <Flex justify="space-evenly">
                            <Text
                              fontFamily="heading"
                              fontWeight="medium"
                              color="text.primary"
                              fontSize="sm"
                              whiteSpace={'nowrap'}
                              textAlign={'center'}
                            >
                              {sizeBreakdown?.unit === 'minutes'
                                ? splitTime(item?.value / 60)
                                : `${convertToSingleDecimal(item?.value)}%`}
                            </Text>
                          </Flex>
                        </Box>
                        {arr?.length - 1 !== idx && (
                          <Box
                            height="1px"
                            position={'relative'}
                            w="100%"
                            mt={'-20px'}
                            background="gray.200"
                          >
                            {sizeBreakdown?.unit === 'minutes' && (
                              <Box
                                position="absolute"
                                top="-8px"
                                left="100%"
                                transform="translateX(-50%)"
                              >
                                <MdArrowForwardIos color={'#CBD5E0'} />
                              </Box>
                            )}
                          </Box>
                        )}
                      </Fragment>
                    )
                  )}
                </Flex>
              </Flex>
            )}
        </MetricCardContainer>
      )}
      {imageDownloadLoading && <Loader />}
      {openDrawer && (
        <SlideInfoDrawer
          handelDrawerClose={handleDrawerClose}
          openDrawer={openDrawer}
          title={data?.chartMetadata?.chartTitle}
          chartKey={data?.chartMetadata?.chartKey}
          item={data}
        />
      )}
    </Box>
  );
};
